
.customer-select {
    width: 300px;
    margin: 20px auto;

    > input {
        padding: 20px;
        display: block;
        width: 100%;
        border: none;

        opacity: 0;
        @include prefix(animation, fadein 300ms linear forwards);
        animation-delay: 100ms;
    }

    > ul {
        margin: 0;
        padding: 0;
        list-style: none;

        > li {
            > a {
                display: block;
                color: $color_black;
                text-decoration: none;
                padding: 20px;
                background: $color_white;
                margin: 10px 0;
                @include prefix(border-radius, 6px);
                opacity: .6;
                outline: none;
                @include prefix(transition, all 100ms ease);

                &:hover {
                    opacity: 1;

                    transform: scale(1.06);
                }

                &:active {
                    background: rgba(0, 0, 0, 0.1);
                }

                @include prefix(box-shadow, 0 4px 4px 0 #dbe0e3);

                > * {
                    display: inline-block;
                    vertical-align: middle;
                }

                > i {
                    font-size: 18px;
                    margin: -4px 0;
                    margin-right: 10px;
                }

                > span {
                    max-width: 220px;
                }
            }

            &.translate {
                > a {
                    color: $color_palette_red;
                }
            }

            &.admin {
                > a {
                    color: $color_palette_blue;
                }
            }

            opacity: 0;
            @include prefix(animation, slidedown 400ms ease forwards);

            &:nth-child(1) { animation-delay: 200ms; }
            &:nth-child(2) { animation-delay: 250ms; }
            &:nth-child(3) { animation-delay: 300ms; }
            &:nth-child(4) { animation-delay: 350ms; }
            animation-delay: 400ms;
        }
    }
}

body.customer-home, body.customer-home > main {
    > .container {
        > h1 {
            font-size: 24px;
            font-weight: 600;
            text-align: center;
            margin: 40px 0 0 0;
            opacity: 0;
            @include prefix(animation, slidedown 400ms ease forwards);
            animation-delay: 100ms;
        }

        > .options {
            text-align: center;
            margin-top: 40px;
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            > .option-card {
                text-decoration: none;
                color:black;
                display: grid;
                width: 300px;
                background: $color_white;
                @include prefix(border-radius, 12px);
                @include prefix(box-shadow, 0 8px 10px 0 #dbe0e3);
                transition: box-shadow 200ms ease;
                &:hover {
                    @include prefix(box-shadow, 0 2px 4px 0 #dbe0e3);
                }
                margin: 0 15px 30px 15px;
                padding: 30px 0;
                opacity: 0;
                @include prefix(animation, slidedown 400ms ease forwards);
                &:nth-child(1) { animation-delay: 200ms; }
                &:nth-child(2) { animation-delay: 300ms; }
                &:nth-child(3) { animation-delay: 400ms; }
                &:nth-child(4) { animation-delay: 500ms; }
                &:nth-child(5) { animation-delay: 600ms; }
                &:nth-child(6) { animation-delay: 700ms; }
                

                > .image {
                    width: 50px;
                    height: 50px;
                    margin: 0 auto 20px;

                    background-position: 50% 50%;
                    background-repeat: no-repeat;
                    background-size: contain;

                    &.image-upload {
                        background-image: url('../svg/upload-1x.svg');
                    }

                    &.image-create {
                        background-image: url('../svg/create-1x.svg');
                    }

                    > i {
                        color: $color_black;
                        font-size: 50px;
                        opacity: 0.8;
                    }
                }

                > .title {
                    font-size: 18px;
                    font-weight: 600;
                    padding: 0 30px;
                }

                > .text {
                    font-size: 14px;
                    font-weight: 500;
                    padding: 0 30px;
                    margin: 10px 0 20px 0;
                }

                > .actions {
                    margin-top: auto;
                }
            }
        }

        > .other {
            text-align: center;
            margin-bottom: 60px;

            opacity: 0;
            @include prefix(animation, fadein 500ms ease forwards);
            animation-delay: 500ms;

            > h3 {
                font-size: 14px;
                font-weight: 600;
                text-transform: uppercase;
            }

            > ul {
                list-style: none;
                margin: 0;
                padding: 0;

                > li {
                    display: block;
                    margin: 20px 0;

                    > a {
                        font-size: 14px;
                        font-weight: 600;
                        color: $color_palette_red;
                        text-decoration: none;
                        padding: 10px;
                    }
                }
            }

            > h3,
            > ul > li > a {
                margin: 20px 0;
            }
        }
    }
}
