
@keyframes spinner {
  to {transform: rotate(360deg);}
}
.partial.loading {
    height: 20px;
    position: relative;
}
.partial.loading:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid transparent;
    border-top-color: $color_primary;
    border-bottom-color: $color_primary;
    animation: spinner .8s ease infinite;
}

body.translation, body.translation > main {
    .partial.loading:before {
        border-top-color: $color_palette_red;
        border-bottom-color: $color_palette_red;
        z-index: 10;
    }
}

body.embed, body.embed >  main {
    &.wizard {
        padding-top: 60px;

        .partial.loading:before {
            top: 100px;
        }
    }

    &.manage, &.manage > main {
        .actions:not(.modal-actions) {
            top: 0;
        }
    }
}

body.embed, body.embed > main {
    &.template-builder {
        .heading {
            top : 0;
        }
    }

    > .product-detail {
        > .heading {
            top: 0;
        }

        > .inner {
            top: 75px;
        }

        > #preview {
            top: 75px;
        }
    }

    > .vocabulary-detail {

        > .inner {
            top: 75px;
        }
    }

    &.inriver {

        >.product-detail {

            > .heading {
                > .actions {
                    .pagination {
                        display: none;
                    }
                    .add-button {
                        display: none;
                    }
                }
            }

            #form {
                .external-id-field {
                    display: none;
                }
            }
        }
    }

    .embed-show {
        display: unset;
    }

    .embed-hide {
        display: none;
    }
}

.embed-show {
    display: none;
}
