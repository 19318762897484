
// GENERAL STYLINGS
.TagTreeEditor {
    // Gives the tagtree all space of the node
    flex-grow: 1;

    // If the tagtree is rendered inside a h*/p element this will make the font size not increase or decrease
    font-size: initial;

    // Makes the dropdown menu show
    .ReactVirtualized__Grid.ReactVirtualized__List.rst__virtualScrollOverride {
        overflow: visible !important;
    }
    .rstcustom__node {
        overflow: inherit;
    }

    // This do we not use. It's the default wrapper around the value but we use VocabularyLookup for that instead
    .rstcustom__rowLabel {
        display: none;
    }
    // VocabularyLookup and other buttons lives inside this selector
    .rstcustom__rowToolbar {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 40px;
        right: 0;
        gap: 5px;
        > :has(> .VocabularyLookup) {
            flex-grow: 1; // Give VocabularyLookup as much space as possible
        }

        #add_setting {
            padding: 5px 20px 20px 16px;
        }
        i {
            opacity: 0.6;
            &:hover {
                opacity: 1;
            }
        }

        > :not(:first-child) {
            align-self: flex-end; // Align all the other elements at the end
            margin: auto 0px; // Align center vertically 
        }
    }

    .rstcustom__rowContents {
        display: block;
        position: relative;
    }

    // This is the AsyncSelect (.VocabularyLookup) input and we do not want to specify the height (It's specified by default)
    input {
        height: unset !important;
    }

    // Remove the default background when hovering a node
    .rstcustom__highlight {
        background: none;
    }

    // This padding is the default padding. We do not want it to change when hovering the element 
    .rstcustom__rowWrapper:hover {
        padding: 2px 2px 2px 0;
        opacity: 1;
    }
    .rstcustom__rowContents:hover {
        box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
    }

    // z-index by default is set to 1000, That is way to big of a number and will result in it overlaps other menus
    .rstcustom__collapseButton, .rstcustom__expandButton {
        z-index: 1 !important; 
    }

    // Fix stacking context
    .ReactVirtualized__Grid.ReactVirtualized__List.rst__virtualScrollOverride {
        will-change: auto !important;
    }

    // The first menu item should be custom buttons
    .vocabulary-lookup__menu-list > :first-child:has(> .buttons) {
        background: none;
    }
}


// ENRICH TAB
div[data-react-component=ProductDetailEnrichTab] {
    .rstcustom__collapseButton, .rstcustom__expandButton {
        padding-left: 100px;
    }
    .rstcustom__toolbarButton:has(> .VocabularyLookup) {
        display: flex;
        flex-grow: 1;
    }
}