
.VariantsField {
    li {
        margin: 10px 0;

        > * {
            display: inline-block;
        }

        > textarea {
            padding: 10px 10px;
            vertical-align: top;
        }
    }

    button {
        border: none;
        background: transparent;
        cursor: pointer;
        padding: 3px 6px;

        > * {
            display: inline-block;
            vertical-align: middle;
        }

        > span {
            font-size: 9pt;
            font-weight: 500;
            margin-left: 4px;
        }

        color: $color_palette_blue;

        &:hover {
            color: $color_palette_red;
        }

        &:active {
            color: $color_black;
        }
    }
}
