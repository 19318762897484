@mixin template-styling {

  > * {
    padding-top: 5px;
    border-bottom: solid 1px $color_palette_smoke;

    &.no-padding {
      padding-top: 0;

      & ~ .template-item {
        padding-top: 5px;
        padding-bottom: 5px;

        &:hover {
          background: initial;
        }
      }
    }
  }

  form:not(.no-padding) {
    padding: 0 10px;
  }

  .vocabulary-item {
    display: flex;
    padding-left: 5px;
  }

  .template-item {
    display: flex;
    width: 100%;
    padding-left: 5px;

    &.template-linebreak {
      height: 35px;
      padding-top: 0px;
      padding-bottom: 0px;

      .fill-data-button, .details-button {
        display: none;
      }

      [data-sortable-handle] {
        color: $color_darksmoke;
        padding-top: 5px;
      }
    }

    &:hover {
      background-color: $color_lightsmoke;

      &.no-hover {
        background-color: initial;
      }

      .actions {
        opacity: 1;
      }

      .template-hover {
        opacity: 1;
      }
    }

    [data-sortable-handle], .sortable-handle-child {
      color: $color_darksmoke;
      padding-top: 10px;
      cursor: move;
    }

    .sortable-handle-child {
      padding-top: 5px;
    }

    .actions {
      padding: 10px;
      padding-top: 0;

      button, a {
        font-weight: 400;

        &:hover {
          color: $color_palette_red;
        }

        text-decoration: none;
        color: $color_black;
        font-size: 11px;
        margin: 5px 0;
        padding: 0;
        border: none;
        cursor: pointer;
        background: transparent;
        text-align: left;
      }
    }

    .template-hover {
      opacity: 0;
      display: flex;
      position: absolute;
      right: 20px;
      z-index: 9999;
      padding: 5px;
      margin-top: -40px;
      border: solid 2px $color_lightsmoke;
      background-color: #FFFFFF;
      border-radius: 4px;

      .template-name {
        font-size: 9pt;
        color: $color_gray;
        text-transform: uppercase;
        margin: 3px 0 0 0;
        padding: 2px;
        max-width: 300px;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
      }

      form, .delete-template-form {
        display: inline-flex;
        padding: 0 5px;

        a, button {
          margin-bottom: 2px;
        }
      }
    }

    .partial {
      flex-grow: 1;
      vertical-align: middle;

      &.no-line-height {
        form {
          .container {
            line-height: normal;
          }
        }
      }

      .container {
        display: flex;
        flex-wrap: wrap;
        line-height: 3;
        vertical-align: top;
        align-items: center;
        margin: 0 0 0 20px;

        textarea {
          max-height: unset;
          max-width: unset;
          min-height: unset;
          min-width: unset;
        }

        * {
          line-height: 1;
        }

        > * {
          margin: 0;
          padding: 5px 0 5px 5px;
        }

        ul {
          width: 100%;
        }

        .template-field {
          display: flex;
          flex-direction: column;
          position: relative;
          height: 100%;
          margin: auto 0 auto 0;

          &.no-hover {
            padding-left: 0 !important;

            input[type=text] {
              padding-left: 17px;
            }
          }
          &.template-tags {
            width: 100%;
          }

          &.template-text {
            margin: 3px;
          }

          &.field-type-freetext {
            width: 100%;
          }

          &.field-type-langstring {
            flex-grow: 1;
          }

          &.template-fixed_phrase {
            display: inline-block;
            width: 100%;
            .vocabulary-lookup-wrapper {
              width: 100%;
              min-width: 600px;
            }

            .single-input {
              width: 95%;
            }
          }


          .vocabulary-lookup-wrapper {
            width: 200px;
            display: inline-block;
            margin: auto 5px;

            .selected-value {
              position: static;
            }
          }

          input[type="number"] {
            width: 4em;
          }

          input, select {
            padding-left: 5px;
            width: 200px;
            height: 37px;
            margin: auto 0;
          }

          &:hover {
            .recommend-box {
              display: block;
            }
          }

          .recommend-box {
            display: none;
            position: absolute;
            bottom: -80px;
            left: -50px;
            padding: 50px;
            padding-top: 5px;
            width: 400px;
            z-index: 10;

            .recommend {
              display: inline-block;
              padding: 4px 5px;
              text-decoration: none;
              color: black;
              margin: 2px;
              border: 1px solid black;
              border-radius: 6px;
              white-space: nowrap;
              transition: 0.3s;
              font-size: 10px;
              box-shadow: 0 3px 5px 0 #dbe0e3;
              background: $color_white;

              &:hover {
                background: #e7e7e7;

                box-shadow: none;
              }
            }
          }
        }
      }
    }
  }
}
.recommend-box {
  .recommend {
    display: inline-block;
    padding: 4px 5px;
    text-decoration: none;
    color: black;
    margin: 2px;
    border: 1px solid black;
    border-radius: 6px;
    transition: 0.3s;
    font-size: 10px;
    box-shadow: 0 3px 5px 0 #dbe0e3;
    background: $color_white;
    cursor: pointer;

    &:hover {
      background: #e7e7e7;

      box-shadow: none;
    }
  }
}

.ui.checkbox.slider {
  font-size: 14px;
  input:checked ~ label {
    color: $color_palette_red !important;
    &::before {
      background-color: $color_palette_red !important;
    }
  }
}

.product-detail {

  #frequency-data-label {
    opacity: 0;
  }

  .name-field > input:hover {
    border-color: $color_palette_gray;
  }

  .image-upload {
    margin: 20px 0;
    padding: 20px;
    margin-bottom: 20px;
    background: $color_white;
    border: solid 2px $color_smoke;
    @include prefix(border-radius, 10px);

    > .title {
      text-align: center;
      font-size: 15pt;
      color: $color_black;
      margin-bottom: 12px;

      > * {
        display: inline-block;
        vertical-align: middle;
      }
    }

    > form {
      padding: 10px;

      > .top {
        display: flex;
        flex-direction: row;

        > * {
          text-align: center;

          > .label {
            font-size: 10pt;
            margin: 0;
          }

          > .field {
            margin: 8px 0;

            > input {
              padding: 8px;
              width: auto;
              margin: 0 auto;
              max-width: 300px;
            }
          }
        }

        > .url {
          flex: 1;
        }

        > .or {
          position: relative;

          > .line {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 1px;
            background: rgba(0, 0, 0, 0.2);
          }

          > .label {
            position: absolute;
            top: 50%;
            margin-top: -12px;
            width: 50px;
            left: 0;
            margin-left: -25px;
            text-transform: uppercase;
            font-size: 11pt;
            background: $color_white;
            padding: 3px;
            color: $color_gray;
          }
        }

        > .file {
          flex: 1;
        }
      }

      > .actions {
        text-align: center;
        margin: 20px;
        margin-bottom: 0;
      }
    }
  }



  .vision-annotations {
    display: flex;
    flex-direction: row;

    > .image {
      > .inner {
        display: inline-block;
        position: relative;

        > img {
          max-width: 400px;
          max-height: 400px;
        }

        > div {
          position: absolute;
          display: flex;
          flex-direction: row;

          margin-top: -25px;
          margin-left: -12px;

          > .dot {
            width: 20px;
            height: 20px;
            border: solid 3px $color_white;
            background: $color_palette_blue;
            @include prefix(border-radius, 100%);
          }

          > .label {
            background: rgba(255, 255, 255, 0.8);
            border: solid 1px $color_black;
            @include prefix(border-radius, 3px);
            flex: 1;

            padding: 5px 10px;
            margin: 0 7px;
            font-size: 9pt;
            display: block;
            text-decoration: none;

            > .key {
              color: $color_gray;
            }

            > .value {
              color: $color_black;
            }

            &.active > * {
              color: $color_palette_red;
            }

            &.active {
              border-color: $color_palette_red;
            }

            &.mapped {
              font-weight: bold;
            }
          }
        }
      }
    }

    > .other {
      flex: 1;

      > ul {
        list-style: none;
        margin: 0;
        padding: 20px;

        > li {
          display: inline-block;
          margin: 6px;

          > .label {
            background: rgba(255, 255, 255, 0.8);
            border: solid 1px $color_black;
            @include prefix(border-radius, 3px);

            padding: 5px 10px;
            font-size: 9pt;
            display: block;
            text-decoration: none;

            > .key {
              color: $color_gray;
            }

            > .value {
              color: $color_black;
            }

            &.active > * {
              color: $color_palette_red;
              border-color: $color_palette_red;
            }

            &.active {
              border-color: $color_palette_red;
            }

            &.mapped {
              font-weight: bold;
            }
          }
        }
      }

      > form {
        padding: 30px;

        > .actions {
          > * {
            margin-right: 10px;
          }
        }
      }
    }
  }

  > .heading {
    padding: 15px 20px;
    border-bottom: solid 2px #f4f5f9;
    background: $color_white;
    @include prefix(box-shadow, 0 2px 9px 0 rgba(0, 0, 0, 0.1));
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 50px;
    left: 0;
    right: 0;
    z-index: 9;
    > div.image-and-product-id {
      display: flex;
      gap: 20px;
      @include prefix(animation, slidedown 200ms ease forwards);
        img {
          max-height: 400px;
        }

        img.thumb {
          height: 36px;
        }

        div {
          > h2, > h3 {
            margin: 0;
            line-height: initial;
          }

          > h2 {
            font-size: 11pt;
            font-weight: 500;
          }

          > h3 {
            font-size: 10pt;
            color: $color_gray;
            font-weight: 500;
          }
      }
  }
  > .pagination {
    @include prefix(animation, slidedown 200ms ease forwards);
    
    .rotate-icon::before {
      transform: rotate(90deg);
    }
    
    > .pages {
      > * {
        display: inline-block;
        vertical-align: middle;
      }
      
      .input-custom-width {
          width: 130px;
      }

      > form {
        margin: 0 4px;

        > * {
          display: inline-block;
          vertical-align: middle;
        }
      }
    }
  }
    > .actions {
      padding: 0;
      display: flex;
      align-items: center;
      gap: 15px;
    }

    .logo {
      width: 160px;
      height: 45px;
      padding: 0;
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      border: none;
      margin: 0 auto;
      filter: invert(1);
    }
  }

  > .details-body {
      position: relative;

    > .marked-as-ready-message {
      z-index: 8;
      position: absolute;
      top: 350px;
      left: 15%;
      margin: 0 auto;
      font-size: 30px;

      .actions {
        display: flex;
        justify-content: center;
        margin-top: 20px;

        button {
          width: 200px;
        }
      }
    }

    display: flex;
    flex-direction: row;

    > .inner {
      flex: 1;
      padding: 20px 20px 160px;
      width: 80%;

      > .alert-box-container {
        position: fixed;
        z-index: 100;
        top: 140px;
      }

      > #form {
        z-index: 6;
        position: relative;

        .row {
          white-space: nowrap;
          margin: 0 0 20px;
          display: flex;
          flex-direction: column;

          background: $color_white;
          @include prefix(border-radius, 4px);
          padding: 0 20px 10px;

          justify-content: space-between;
        }
        .column {
          > form.product-top-form {
           display: grid;
           grid-template-columns: repeat(2, minmax(300px, 1fr));
          }
          .accordion {
            margin: 5px 0;
            .content {
              padding: 0px;
              .tabs {
                padding-top: 10px;
              }
              .tab-container {
                padding-bottom: 10px;
              }
            }
          }
          .additional-information-product-details {
            padding-top: 15px;
            padding-bottom: 0px;
            display: flex;
            align-items: center;
            font-size: 9pt;
            p {
              margin-left: 2px;
              margin-right: 6px;
            }
            .divider {
              flex-grow: 1;
            }
          }
          [id=original-text] {

            > form.original-text-form {
              flex-grow: 1;
              display: grid;
              grid-template-columns: repeat(2, 1fr);

              > .original-text-textarea {
                > textarea {
                  height: calc(100% - 30px);
                }
              }

              textarea[id=id_notes] {
                min-height: 70px;
                height: 70px;
              }
            }
          }

          form {
            width: 100%;
            margin-top: 10px;
            .field {
              margin: 0 10px;

              &.highlight > input, &.highlight > div {
                box-shadow: 0 0 0 1px lighten($color_palette_red, 20%);
                border-radius: 4px;
              }

              > .label {
                display: block;
                margin: 6px 10px;

                label {
                  font-size: 9pt;
                  color: $color_gray;
                  text-transform: uppercase;
                }
              }

              > input,
              > textarea {
                padding: 11px 10px;
                font-size: 12pt;
              }

              > textarea {
                width: 0;
                height: 128px;
                min-height: 128px;
                max-width: 0;
              }

              .error-messages {
                list-style: none;
                padding: 0;
                margin: 5px 0 0;

                li {
                  color: $color_error;
                  font-size: small;
                  margin: 2px 0;

                  a {
                    font-weight: bold;
                    color: $color_error;
                    text-decoration: underline;
                  }
                }
              }

            }
          }
        }

        /* Clear floats after the columns */
        .row:after {
          content: "";
          display: table;
          clear: both;
        }
      }

      > #edit {
        padding: 2px 0;

        z-index: 4;
        position: relative;

        .edit {
          .metadata-form {
            margin: 5px;

            fieldset {
              max-width: 700px;
              display: flex;
              flex-direction: column;

              > div {
                display: flex;
                flex-direction: row;
                padding: 10px;

                > label {
                  width: 20%;
                }

                > * {
                  width: 80%;
                }
              }
            }
          }

          &.edit-mappings {
            > .section {
              .mappings {
                > .extract-mappings {

                  .field {
                    > .label {
                      font-size: 11pt;
                      padding: 10px 0;
                      color: $color_gray;
                    }

                    select {
                      width: 300px;
                    }
                  }

                  button {
                    width: 200px;
                  }
                }

                > h3 {
                  margin-top: 40px;
                  padding-top: 20px;
                  border-top: solid 1px $color_palette_smoke;

                  &:first-of-type {
                    margin-top: 0;
                    border-top: 0;
                  }
                }

                > div {
                  margin-bottom: 20px;

                  > .title {
                    font-size: 9pt;
                    text-transform: uppercase;
                    color: $color_gray;
                    padding: 6px 12px;
                  }

                  > ul {
                    list-style: none;
                    margin: 0;
                    padding: 0;

                    > li {
                      display: inline-block;
                      margin: 5px;

                      .highlight {
                        opacity: 1 !important;
                      }

                      a.pair {
                        display: inline-block;
                        text-decoration: none;
                        background: $color_white;
                        @include prefix(border-radius, 4px);
                        @include prefix(box-shadow, 0 2px 9px 0 #dbe0e3);
                        overflow: hidden;

                        * {
                          font-size: 12px;
                          padding: 5px 10px;
                          display: inline-block;
                        }

                        > .key {
                          background: $color_palette_red;
                          color: $color_white;
                        }

                        > .value {
                          color: #1a1919;
                        }

                        &.mapped {
                          > .key {
                            background: $color_palette_blue;
                          }
                        }

                        &:active {
                          opacity: 0.7;
                        }

                        &.main-kind {
                          border: solid 1px black;
                          background-color: lightgray;
                        }
                      }
                    }
                  }
                }

                &.fade {
                  > div > ul > li {
                    div {
                      opacity: 0.5;
                    }
                  }
                }
              }
            }
          }

          .extract-form.annotations {
            position: relative;

            .annotation-lock {
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;

              z-index: 1001;
              background: rgba(255, 255, 255, 0.9);

              > .confirmation-message {
                width: 300px;
                position: absolute;
                top: 100px;
                left: 50%;
                margin-left: -150px;
                text-align: center;

                > p {
                  font-size: 10pt;
                }
              }
            }
          }

          .original-text-form,
          .extract-form {
            > .field {
              margin: 25px 0;

              > .label {
                font-size: 9pt;
                color: $color_gray;
                text-transform: uppercase;
                margin: 6px 10px;
              }

              > input,
              > textarea {
                padding: 11px 10px;
                font-size: 12pt;
              }

              > textarea {
                height: calc(100% - 30px);
                min-height: calc(100% - 30px);
                max-height: calc(100% - 30px);
              }
            }
          }

          .extract-columns {
            display: flex;

            > * {
              flex-basis: 50%;
              min-width: 400px;
              flex-grow: 1;
              flex-wrap: wrap;
            }

            > textarea {
              display: none;
            }
          }

          > ul {
            list-style: none;
            padding: 0 20px;
            margin: 0;

            > li {
              display: inline-block;
              margin: 0 4px;

              > a {
                display: block;
                padding: 10px;
                border-bottom: solid 3px transparent;
                font-size: 9pt;
                color: $color_gray;
                text-decoration: none;
                text-transform: uppercase;

                &.active {
                  border-bottom-color: $color_palette_red;
                  color: $color_palette_red;
                }
              }

              &.separator {
                height: 10px;
                border-right: solid $color_gray 1px;
              }
            }
          }

          > .section {
            background: $color_white;
            @include prefix(border-radius, 4px);
            min-height: 400px;
            padding: 20px;

            .data-tag-tree {
              font-family: monospace;
              font-size: 16px;
              white-space: pre;
              overflow-y: scroll;
            }

            .edit-fields {
              display: flex;
              margin: -20px;
              @include prefix(border-radius, 4px);
              background: rgba(0, 0, 0, 0.1);

              .recommend-box {
                margin: 5px;
              }

              .recommend {
                padding: 4px 5px;
                text-decoration: none;
                color: black;
                margin: 2px;
                border: 1px solid black;
                border-radius: 6px;
                transition: 0.3s;
                font-size: 10px;
                box-shadow: 0 3px 5px 0 #dbe0e3;
                display: inline-block;
              }

              .recommend:hover {
                background: #e7e7e7;

                box-shadow: none;
              }

              .seo-recommendations {
                margin-top: 10px;
              }

              .seo-recommend {
                background: #cdeaf9;
              }

              .seo-recommend:hover {
                background: #a4ceea;
              }

              [seo-competition="Low"] {
                background: #01FF70;
              }

              [seo-competition="Medium"] {
                background: #FFDC00;
              }

              [seo-competition="High"] {
                background: #FF851B;
              }

              .left {

                flex: 0 1 200px;
                margin: 30px 0;
                font-size: 10pt;

                .recommend:hover {
                  background: rgba(0, 0, 0, 0.1);
                }

                .kind {

                  &.highlight {
                    > a {
                      border-color: $color_palette_red;
                    }
                  }

                  > * {
                    padding: 10px 16px;
                    display: inline-block;
                  }

                  > a {
                    text-decoration: none;
                    color: $color_black;
                    border-left: solid 3px transparent;
                    padding-right: 4px;

                    &.active {
                      border-color: $color_palette_red;
                      color: $color_palette_red;
                    }
                  }

                  > span {
                    padding-left: 0;
                    color: $color_gray;
                  }

                  &.active {
                    background: $color_white;
                  }
                }

                .separator {
                  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
                  margin: 6px 14px;
                }

                a.add {
                  padding: 10px 16px;
                  padding-left: 40px;
                  display: block;
                  margin-top: 10px;

                  > span {
                    text-transform: uppercase;
                    color: $color_gray;
                  }
                }

                > ul.subparts {
                  list-style: none;
                  padding: 0;
                  margin: 0;

                  ul {
                    // Subpart of subpart and beyond
                    width: 100%;
                    list-style: none;
                    padding: 0;
                    margin: 0;
                  }

                  // Text hover
                  > li.highlight {
                    > .subpart, > .add, > ul {
                      border-color: $color_palette_red;
                      cursor: pointer;
                    }
                  }

                  li > .add {
                    // subpart of subpart add button
                    margin: 0;
                    padding-left: 60px;
                  }

                  li.depth-3 {
                    padding-left: 20px;
                  }

                  li.depth-4 {
                    padding-left: 20px;
                  }

                  > li.active {

                    > .subpart {
                      background: $color_white;
                      border-color: $color_palette_red;
                    }

                    > .add {
                      background: $color_white;
                      border-left: solid 3px $color_palette_red;
                    }

                    > ul {
                      background: $color_white;
                      border-left: solid 3px $color_palette_red;
                    }

                  }

                  li > .subpart {
                    display: flex;
                    justify-content: space-between;
                    // flex-wrap: wrap;
                    border-left: solid 3px transparent;

                    > .link {
                      flex: 1 0 100px;
                      padding: 10px 16px;
                      padding-left: 40px;

                    }

                    > .delete {
                      flex: 0 0 50px;
                      padding: 8px 0;

                      .delete-button {
                        margin: -4px;
                        padding: 4px;
                        border: none;
                        opacity: 0;
                        font-size: 11pt;
                        cursor: pointer;
                        background: transparent;
                      }
                    }

                    &:hover > .delete > .delete-button {
                      opacity: 1;
                    }
                  }
                }

                a {
                  text-decoration: none;
                  color: $color_black;

                  &.active {
                    color: $color_palette_red;
                  }
                }
              }

              .right {
                flex: 1;
                padding: 20px;
                margin: 10px;
                margin-left: 0;
                background: $color_white;
                @include prefix(border-radius, 4px);

                hr {
                  border: 1px solid $color_smoke;
                }

                > form .label {
                  display: inline-block;
                }

                > form label {
                  font-size: 9pt;
                  color: $color_gray;
                  text-transform: uppercase;
                  margin: 0 10px 6px;
                  display: block;
                }

                .fluent-input-row {
                  .label {
                    display: inline-block;
                    margin: 6px 10px;
                    margin-top: 0;

                    > label {
                      font-size: 9pt;
                      color: $color_gray;
                      text-transform: uppercase;
                    }
                  }

                  > form {
                    display: inline-block;

                    > div {
                      display: inline-block;

                      > input {
                        min-width: 350px;
                      }
                    }
                  }
                }

                .product-type-row {
                  display: flex;
                  flex-wrap: wrap;

                  .product-type {
                    flex: 1;
                  }

                  &.highlight {
                    .VocabularyLookup > div:first-child, > input {
                      box-shadow: 0 0 0 1px lighten($color_palette_red, 20%);
                      border-radius: 3px;
                    }

                    .vocabulary-lookup {
                      box-shadow: 0 0 0 1px lighten($color_palette_red, 20%);
                      border-radius: 3px;
                    }
                  }

                  .product-type-number {
                    width: 15%;
                    margin-left: 5px;
                    display: flex;
                    flex-direction: column;
                    flex: 1;

                    label {
                      margin-top: 3px;
                    }

                    select, input {
                      width: 100%;
                      padding: 0 6px;
                      font-size: 11pt;
                      flex: 1;
                      min-height: 38px;
                    }
                  }

                  .product-type-checkbox {
                    display: flex;
                    align-items: center;
                    margin-top: 25px;

                    input[type=checkbox] {
                      margin: 0 0 6px 10px;
                      transform: scale(1.3);
                    }
                  }
                }

                form {
                  display: flex;
                  flex-wrap: wrap;

                  > .row {
                    flex: 1 1 300px;
                    padding: 0 10px;
                    margin: 10px 0;

                    &.row-subpart_name {
                      flex: 1 1 100%;
                    }

                    &.highlight {
                      .VocabularyLookup > div:first-child, > input {
                        box-shadow: 0 0 0 1px lighten($color_palette_red, 20%);
                        border-radius: 3px;
                      }

                      .vocabulary-lookup {
                        box-shadow: 0 0 0 1px lighten($color_palette_red, 20%);
                        border-radius: 3px;
                      }
                    }

                    > input[type=text] {
                      width: 100%;
                      padding: 8px;
                    }

                    #react-async-select .css-12jo7m5 {
                      white-space: normal;
                    }

                  }

                  > hr {
                    width: 100%;
                  }

                  > .product-type-row {
                    width: 100%;
                    margin-bottom: 10px;
                  }

                  > .global-search {
                    width: 100%;
                    flex: 1 1 300px;
                    padding: 0 10px;
                    margin: 10px 0;

                    &.highlight {
                      .VocabularyLookup > div:first-child, > input {
                        box-shadow: 0 0 0 1px lighten($color_palette_red, 20%);
                        border-radius: 3px;
                      }

                      .vocabulary-lookup {
                        box-shadow: 0 0 0 1px lighten($color_palette_red, 20%);
                        border-radius: 3px;
                      }
                    }

                    > input[type=text] {
                      width: 100%;
                      padding: 8px;
                    }

                    #react-async-select .css-12jo7m5 {
                      white-space: normal;
                    }

                    .global-search-message {
                      color: #3f7700;
                      font-size: 10px;
                    }

                  }
                }
              }
            }
          }

          &.edit-enrich-tab {
            .template-list {
              @include template-styling
            }
          }

          &.edit-templates, &.edit-sections, &.edit-category-page-form {
            > .section {
              > .animate {
                display: flex;
                margin: -20px;
                @include prefix(border-radius, 4px);
                background: rgba(0, 0, 0, 0.1);
                min-height: 400px;

                .left {
                  flex: 0 0 0;
                  padding-top: 10px;

                  .mask {
                    position: absolute;
                    visibility: hidden;
                    z-index: 100;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    background-color: rgba(33, 40, 76, 0.5);
                    -webkit-border-radius: 10px;
                    -moz-border-radius: 10px;
                    -o-border-radius: 10px;
                    border-radius: 10px;
                  }

                  h3 {
                    padding-left: 10px;
                    text-transform: uppercase;
                    font-size: 12px;
                    color: $color_gray;
                  }

                  ul {
                    margin: 5px 0;
                    list-style: none;
                    padding: 0;

                    .temp-adj-vocabulary {
                      margin-left: 20px;
                      margin-right: 20px;
                      width: 100%;

                      > form {
                        > div {
                          .template-selector {

                            .modal {
                              top: 0;
                              margin-top: 0;

                            }
                          }

                        }

                      }
                    }

                    li {
                      margin: 0;
                      padding: 0;
                      display: flex;
                      justify-content: space-between;
                      white-space: nowrap;

                      &.active {
                        background: $color_white;
                      }

                      > * {
                        padding: 10px 16px;
                        display: inline-block;
                      }

                      a {
                        padding: 10px 16px;
                        text-decoration: none;
                        font-size: 10pt;
                        color: $color_black;
                        border-left: solid 3px transparent;

                        &.add {
                          padding: 10px 16px;
                          padding-left: 40px;
                          display: block;

                          > span {
                            margin-top: 10px;
                            text-transform: uppercase;
                            color: $color_gray;
                          }
                        }

                        > span {
                          margin-right: 14px;
                        }

                      }

                      &.active > a {
                        border-color: $color_palette_red;
                        color: $color_palette_red;

                        > span {
                          color: $color_palette_red;
                        }
                      }

                      form {
                        opacity: 0;

                        button {
                          padding: 1px;
                          margin: 0;
                          cursor: pointer;
                          background: transparent;
                          border: 0;

                          > i {
                            font-size: 15px;
                          }
                        }
                      }

                      &:hover {
                        form {
                          opacity: 1;
                        }
                      }
                    }
                  }
                }

                .right {
                  flex: 1;
                  padding: 10px 0;
                  margin: 10px;

                  &.has-sidebar {
                    margin-left: 0;
                  }

                  background: $color_white;
                  @include prefix(border-radius, 4px);

                  > h3 {
                    margin: 60px 0;
                    text-align: center;
                  }

                  .section {
                    h3 {
                      padding-left: 30px;
                      text-transform: uppercase;
                    }
                  }

                  .template-selector {
                    .mask {
                      position: absolute;
                    }

                    .modal {
                      top: 50px;
                    }
                  }

                  .template-list, .section .template-list {
                    @include template-styling;
                  }

                  .template-list.big-border-bottom {
                    > * {
                      border-bottom: none;

                      &.no-padding {
                        position: relative;

                        &.more-margin {
                          margin: 20px 0;
                        }

                        .divider {
                          position: absolute;
                          top: calc(50%);
                          bottom: calc(50% + 5px);
                          left: 32px;
                          width: calc(100% - 45px);
                          border: 1px solid #f1f1f1;
                        }
                      }
                    }
                  }

                  form {
                    h1, h2, h3, p {
                      margin: 0;
                      padding: 14px 20px;
                      padding-bottom: 5px;
                    }

                    .actions {
                      padding: 20px;

                      > * {
                        margin-right: 14px;
                      }
                    }

                    .field {
                      margin: 0;
                      padding: 14px 20px;

                      > * {
                        display: block;
                      }

                      .label {
                        display: inline-block;
                        margin: 6px 10px;
                        margin-top: 0;

                        label {
                          font-size: 9pt;
                          color: $color_gray;
                          text-transform: uppercase;
                        }
                      }

                      select,
                      input,
                      textarea {
                        padding: 8px 10px;
                        font-size: 12pt;
                      }

                      border-bottom: solid 1px $color_palette_smoke;

                      &:last-of-type {
                        border-bottom: 0;
                      }

                      &.single-line {
                        display: flex;

                        > * + * {
                          margin-left: 10px;
                        }
                      }
                    }
                  }

                }
              }
            }
          }

          &.edit-children > .section > .animate {
            > form {
              display: inline-flex;
              align-items: center;
              width: 100%;

              label {
                margin-right: 8px;
              }

              input {
                padding: 4px;
                flex: 1;
              }

              button {
                text-transform: uppercase;
                font-size: 12px;
                font-weight: 400;
                border: none;
                background: $color_white;
              }
            }

            > ul {
              list-style-type: none;
              padding: 0;

              > a {
                width: 100%;
                color: $color_black;
                text-decoration: none;

                &:nth-child(even) > li {
                  background: $color_lightsmoke;
                }

                > li {
                  margin: 0;
                  padding: 10px;
                }
              }

            }
          }

          &.edit-child-colors > .section > .animate {
            h2 {
              margin-top: 0;
            }

            > form {
              display: inline-block;
              float: right;

              button {
                text-transform: uppercase;
                font-size: 12px;
                font-weight: 400;
                border: none;
                background: $color_white;
              }
            }

            > ul {
              list-style-type: none;
              padding: 0;

              > li {
                margin: 0;
                padding: 10px;
              }

            }
          }

          &.edit-fluent {
            .fluent-row {
              border-bottom: 1px solid rgba(0, 0, 0, 0.1);
              padding: 40px 20px;
              margin: -20px -20px 20px -20px;
              display: flex;

              &:hover > .actions {
                opacity: 1;
              }

              > .preview {
                flex: 1;
              }

              > .tags {
                flex: 0 1 300px;
              }

              > .actions {
                flex: 0 0 70px;
                display: flex;
                opacity: 0;
                flex-direction: column;
                text-align: right;

                .link-style {
                  padding-top: 0;
                }

                button {
                  text-align: right;
                  width: 100%;
                  margin: 0;
                }
              }
            }

            .fluent-row-form {
              border-bottom: 1px solid rgba(0, 0, 0, 0.1);
              padding: 40px 20px;
              margin: -20px -20px 20px -20px;

              &.new-row {
                border-bottom: none;
                padding: 30px 20px 20px 20px;
                margin-bottom: 0;
              }

              > .write-form {
                display: flex;
                flex-wrap: wrap;

                > * + * {
                  margin-left: 10px;
                }

                .field-text {
                  flex: 1;
                  min-width: 300px;
                }

              }

              > .verify-form {
                display: flex;
                flex-wrap: wrap;

                > * + * {
                  margin-left: 10px;
                }

                .field-extracted-tags {
                  flex: 1;

                  > div > .annotation {
                    max-width: none;
                  }
                }
              }


              &.write {
                > .verify-form {
                  display: none;
                }
              }

              &.verify {
                > .write-form {
                  opacity: 0.4;
                }
              }
            }
          }
        }
      }
    }

    > #preview {
      // z-index: 8;
      padding: 20px 0 0 0;
      width: 20%;

      .reload-icon {
        text-decoration: none;
        >.out-of-sync-icon {
          color: black;
          font-size: 17px;
        }
      }
      > div {
        position: sticky;
        padding-right: 20px;
        top: 155px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        > form {
          select {
            padding: 8px 10px;
            font-size: 12pt;
            font-weight: 700;
            display: block;
            width: 100%;
            color: #5D5D5D;
          }
        }

        > .texts-grid {
          @include prefix(border-radius, 4px);
          overflow-y: auto;
          overflow-x: hidden;
          flex-grow: 1;

          > .text {
            border-top: none;
            background: $color_white;
            min-height: 300px;

            > .bottom {
              > .reload {
                display: inline-block;
                position: absolute;
                top: 6px;
                right: 4px;
                > a {
                  color: $color_darksmoke;
                  text-decoration: none;
                }
                &:hover {
                  > a {
                    color: black;
                  }
                }
              }

              padding: 0 10px 20px;
              margin-top: 0;
              display: flex;
              flex-direction: column;
            }
          }

          .text-item {
            margin: 0;
            >.channel > .name {
              display: flex;
              align-items: center;
              gap: 4px;
            }
            .word-count {
              text-align: right;
            }
          }
        }

        > .texts-grid.fade {

          > .text {

            .highlight {
              color: $color_black;
              border-bottom: solid 2px;
            }
          }
        }
      }
    }

    #actions.loading {
      opacity: 0.5;
      pointer-events: none;
    }

    .partial.loading, .partial.loading-no-spinner {
      opacity: 0.5;
      pointer-events: none;
    }

    .animate {
      opacity: 0;
      position: relative;
      @include prefix(animation, fadein 300ms ease forwards);
    }
  }

  .template-label-group {

    > ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
    }

    .template-label {
      text-decoration: none;
      font-size: 9pt;
      padding: 0 2px;
      margin: 1px;
      color: #666666;
      border: 1px solid rgba(0, 0, 0, 0.2);
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      -o-border-radius: 2px;
      border-radius: 2px;
    }
  }

  .inline-template-adjust {
    display: flex;
    float: right;

  }

  .template-info {
    display: flex;
    flex-direction: column;
    margin-top: -25px;

    .template-label {
      display: flex;
      flex: unset !important;
      justify-content: flex-end;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      padding-top: 5px !important;

      ul > li {
        text-align: end;
      }
    }

    .template-name {
      text-align: end;
      margin: 0 10px 10px 0;
      font-weight: 200;
      font-size: .8rem;
      color: $color_palette_gray;
    }

    .template-toolbar {
      align-self: flex-end;
      display: flex;
      margin-right: 10px;
      flex-direction: column;

      .template-name, .toolbar-actions-container {
        align-self: flex-end;
      }

      .template-name {
        margin-top: auto;
        margin-bottom: auto;
      }
      .toolbar-actions-container {
        display: flex;
        opacity: 0.8;
        position: relative;

        .delete-template-form, .actions {
          display: flex;
          padding: 0 0 0 3px !important;
        }

        a, button {
          font-size: .9em !important;

          &:hover {
            opacity: 1;
          }
        }
        .remove-button {
          margin-left: 10px !important;
        }
      }

    }
  }

  .buttons-actions-top {
    margin-bottom: 10px;
    flex-grow: 1;
    display: flex !important;
    gap: 20px;

    a, button {
      font-size: 14px;
      width: unset !important;
      border: 1px solid $color_palette_red;
      font-weight: 500;

      &:hover {
        opacity: 0.8;
        background: transparent;
        color: $color_palette_red;
      }
    }
  }
  p.template-settings-help-text {
    width: max-content; 
    margin-left: auto; 
    margin-right: 10px;
    > i {
      margin: unset;
    }
  }
  .materials-div {
    display: flex;
    flex-wrap: wrap;
    line-height: 2;
    vertical-align: top;
  }

  .materials-fields {
    flex: 1 1 auto;
    flex-direction: column;
    position: relative;
    line-height: 3;
    width: 400px;
    height: 100px;
    margin: 5px;

    .label {
      font-size: 9pt;
      color: #666666;
      text-transform: uppercase;
    }

  }

  #sku-ean-modal .modal {
    max-width: 400px;
  }

  input#sku-ean {
    width: 100%;
    padding: 11px 10px;
    font-size: 12pt;
  }

  .product-label-recommendation {
    margin-top: 10px;
    white-space: normal;

    button {
      padding: 4px 5px;
      text-decoration: none;
      color: black;
      margin: 2px;
      border: 1px solid black;
      border-radius: 6px;
      transition: 0.3s;
      font-size: 10px;
      box-shadow: 0 3px 5px 0 #dbe0e3;
      display: inline-block;
      background: $color_white;
      cursor: pointer;

      &:hover {
        background: #e7e7e7;
        box-shadow: none;
      }
    }
  }

  .menu {
    position: relative;
    z-index: 5;

    > .title {
      * {
        cursor: default;
      }

      > .hover-text {
        display: none;
      }

      color: $color_palette_red;
      border: solid 1px transparent;
      text-align: center;
      font-size: 14px;
      display: inline-block;
      width: 155px;
    }

    &.action, &.product-status {
        > .options > a, button {
          display: grid;
          grid-template-columns: 23px auto;
          grid-template-rows: auto;
      }
  }
    > .options {
      display: none;
      width: max-content;
    }

    a, button:not(.ui):not(.txu-btn) {
      margin: 5px 0;
      display: block;
      text-align: left;
      font-size: 9pt;
      text-transform: uppercase;

      &.loading {
        padding: 10px;
      }
    }

    &:hover {

      > .title > span {
        display: none;
      }

      > .title > span.hover-text {
        display: inline-block;
      }

      > .options {
        display: block;
        position: absolute;
        top: -20px;
        left: -50px;
        padding: 50px;

        .pbutton-inverse {
          background-color: $color_white;
        }
      }
    }
  }

  .template-separator {
    display: flex;
    align-items: center;
    padding-left: 1em;
    padding-right: 1em;

    &:before, &:after {
      content: '';
      border-bottom: 1px dashed lightgray;
    }

    &:after {
      margin-left: 1em;
      flex: 1;
    }

    &:before {
      margin-right: 1em;
      flex: 0 0 1em;
    }
  }

  .template-selector > a {
    font-size: 9pt;
    color: #606060;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .tagtree-recommendations {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    .title-wrapper {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid lightgray;
      margin-bottom: 10px;
      padding-bottom: 10px;

      h2.title {
        font-size: 12pt;
        font-weight: 500;
      }

      .remove-button {
        display: grid;
        place-items: center;
        color: $color_gray;
      }

    }

    h3 {
      font-size: 10pt;
    }

      h2, h3 {
        color: $color_gray;
        text-transform: uppercase;
    }

    .recommendations-wrapper{
      display: grid;
        grid-template-columns: 150px auto;
        align-items: baseline;

      .name {
        display: grid;

        p {
          margin: 0;
          padding: 0;
        }
      }

      .seo {

        h3 {
        position: relative;
        }

        i.question.circle.icon {
          position: absolute;
          left: -5px;
          top: 50%;
          transform: translateY(-15%);
        }
      }

      .recommend-box-horizontal{
        display: flex;
        flex-wrap: wrap;

        .recommend {
          padding: 4px 5px;
          text-decoration: none;
          color: black;
          margin: 2px;
          border: 1px solid black;
          border-radius: 6px;
          white-space: nowrap;
          transition: 0.3s;
          font-size: 10px;
          box-shadow: 0 3px 5px 0 #dbe0e3;
          background: $color_white;

          &:hover {
            background: #e7e7e7;
            box-shadow: none;
          }
        }
      }
    }
  }
}

ul.tabs {
  list-style: none;
  padding: 0 20px;
  margin: 0;

  > li {
    display: inline-block;
    margin: 0 4px;

    > a {
      cursor: pointer;
      display: block;
      padding: 10px;
      border-bottom: solid 3px transparent;
      font-size: 9pt;
      color: $color_gray;
      text-decoration: none;
      text-transform: uppercase;

      &.active {
        border-bottom-color: $color_palette_red;
        color: $color_palette_red;
      }
    }

    &.separator {
      height: 10px;
      border-right: solid $color_gray 1px;
    }
  }
}

.tab-container:not(:first-of-type) {
  display: none;
}

.languages {
  padding-bottom: 10px;
}


body.embed .product-detail {
  #preview {
    > div {
      top: 100px;
    }
  }
}
