.vocabulary-detail {

    form label[for="id_lexicon_data"] {
        display: none;
    }

    form.edit-related-tree {
        display: block;
        > textarea {
            display: none;
        }
    }

    > .heading {
        padding: 15px 20px;
        border-bottom: solid 2px #f4f5f9;
        background: $color_white;
        @include prefix(box-shadow, 0 2px 9px 0 rgba(0, 0, 0, 0.1));

        position: fixed;
        left: 0;
        right: 0;
        z-index: 9;

        &.invisible {
            position: relative;
            z-index: 0;
            top: 0;
            background: $color_palette_smoke;
            box-shadow: none;
            > h2, h3 {
                color: $color_palette_smoke;
            }
        }

        > h2, > h3 {
            margin: 2px 0;
        }

        > h2 {
            font-size: 14pt;
        }

        > h3 {
            font-size: 10pt;
            color: $color_gray;
            font-weight: 500;
        }

        > .actions {
            float: right;
            padding: 0;

            > * {
                display: inline-block;
                margin-left: 10px;
                vertical-align: middle;
            }

            .pbutton {
                margin: 2px 0;
            }
        }
    }

    > .inner {
        padding: 30px 30px 160px;

        > form {

            > .lexicons-to-override {
                margin: 20px;
            }

        }

        > .alert-box-container {
            .alert-box {
                margin-bottom: 30px;
            }
        }

        .column {
            display: flex;

            >form {
                width: 100%;
                margin-top: 20px;
            }

            .missing-languages {
                border-top: solid 1px rgba(0, 0, 0, 0.1);
                padding-top: 10px;
                margin-top: 30px;

                > h3 {
                    font-size: 9pt;
                    font-weight: 500;
                    color: $color_gray;

                    > * {
                        display: inline-block;
                        vertical-align: middle;
                    }

                    > i {
                        font-size: 20px;
                    }
                }

                > ul {
                    margin: 0;
                    padding: 0;
                    list-style: none;

                    > li {
                        margin: 2px 0;

                        > a {
                            text-decoration: none;
                            color: $color_black;

                            &:hover {
                                color: $color_palette_red;
                            }

                            &:active {
                                color: $color_black;
                            }

                            > * {
                                display: inline-block;
                                vertical-align: middle;
                            }

                            > span {
                                &.flag {
                                    font-size: 12px;
                                    margin: 0 4px;
                                }

                                &.label {
                                    font-size: 9pt;
                                }
                            }
                        }
                    }
                }
            }

            .texts-grid {
                border-top: solid 1px rgba(0, 0, 0, 0.1);
                margin-top: 20px;
                padding-top: 10px;

                .field {
                    display: inline-block;
                    margin-top: 20px;
                    min-width: 300px;
                }
            }

            .field {
                flex: 1;
                width: 300px;
                margin: 0 50px 20px 0;

                span {
                    display: block;

                    label {
                        color: $color_gray;
                        font-weight: 600;

                        > .flag {
                            font-weight: normal;
                            display: inline-block;
                            margin-right: 4px;
                        }

                        > .owner {
                            font-size: 8pt;
                            font-weight: 500;
                            display: inline-block;
                        }
                    }
                }

                .label {
                    .material-icons {
                        font-size: inherit;
                        vertical-align: middle;
                    }
                    & textarea[name="comment"] {
                        padding: 8px;
                    }
                }

                > div {
                    display: inline-flex;
                    align-items: center;
                    margin-top: 10px;
                    font-weight: 600;
                    font-size: 20px;

                    > div {
                        color: $color_black;
                        margin-left: 5px;
                    }

                    > a {
                        text-decoration: none;
                        color: $color_palette_blue;
                        padding: 0 6px;

                        > i {
                            font-size: 20px;
                        }

                        &:hover {
                            color: $color_palette_red;
                        }

                        &:active {
                            color: $color_black;
                        }

                        visibility: hidden;
                    }

                    &:hover {
                        > a {
                            visibility: visible;
                        }
                    }
                }

                >textarea {
                    height: 70px;
                    margin-top: 10px;
                    padding: 10px;
                }
            }


        }
    }
    .related-tree-list {
        border-top: solid 1px rgba(0, 0, 0, 0.1);
        margin-top: 20px;
                > ul {
                    margin: 0;
                    padding: 0;
                    list-style: none;

                    > li {
                        .column-actions { visibility: hidden; }
                        &:hover { .column-actions { visibility: visible; } }

                        > div {
                            font-size: 0;

                            > div {

                                > .table-column {
                                    display: inline-block;
                                    font-size: 9pt;
                                    padding: 10px 14px;
                                    vertical-align: middle;
                                    overflow: hidden;
                                    text-overflow: ellipsis;

                                    &.column-text {
                                        width: 30%;
                                    }

                                    &.column-volume {
                                        width: 15%;
                                    }

                                    &.column-competition {
                                        width: 15%;
                                    }

                                    &.column-preview {
                                        width: 30%;
                                    }

                                    &.column-actions {
                                        width: 10%;
                                        text-align: left;
                                        color: $color_palette_blue;
                                        font-weight: 600;
                                        text-transform: uppercase;
                                        padding: 8px;

                                        > * {
                                            display: inline-block;
                                            vertical-align: middle;
                                        }

                                        > a {
                                            text-decoration: none;
                                            color: inherit;

                                            &:hover {
                                                color: $color_palette_red;
                                            }

                                            &:active {
                                                color: $color_black;
                                            }
                                            > i {
                                                font-size: 16px;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        &:nth-child(odd) {
                            background: $color_white;
                        }

                        &:nth-child(even) {
                            background: $color_palette_smoke;
                        }

                        &:nth-child(1) {
                            text-transform: uppercase;

                            .table-column {
                                color: $color_gray;
                                font-weight: 600;
                                font-size: 10pt !important;

                                > a {
                                    color: $color_black;
                                    text-decoration: none;
                                    padding: 8px;
                                    margin: -8px;

                                    > * {
                                        display: inline-block;
                                        vertical-align: middle;
                                    }

                                    > i {
                                        font-size: 16px;
                                    }

                                    &:active {
                                        color: $color_palette_red;
                                    }
                                }
                            }
                        }
                    }
                }
            }
    .products-count {
        border-top: solid 1px rgba(0, 0, 0, 0.1);
        margin-top: 20px;

                > h2 a {
                    text-decoration: none;
                    color: #233d84;
                    font-weight: bold;

                    &:hover {
                        color: $color_palette_red;
                    }

                    &:active {
                        color: $color_black;
                    }
                }
            }
    div#vocabulary-description {
        display: inline-block;
        font-weight: normal;
        font-size: 1em;
        width: 100%;
        overflow-wrap: anywhere;
    }
    form#vocabulary-description-form {
        margin-top: 16px;
        display: none;
    }
}
