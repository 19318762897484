// This is used to build semantic like classes for Text component

.ui.text {
  // FLOATED
  $floated-left: ("left-floated", left);
  $floated-right: ("right-floated", right);

  $floated: $floated-left, $floated-right;
  @each $class, $f in $floated {
    &.#{$class} {
      float: #{$f};
    }
  }

  // TEXT ALIGNMENT
  $text-align-left: ("left-text-align", left);
  $text-align-right: ("right-text-align", right);
  $text-align-center: ("center-text-align", center);
  $text-align-justified: ("justified-text-align", justify);

  $text-align: $text-align-left, $text-align-right, $text-align-center,
    $text-align-justified;
  @each $class, $ta in $text-align {
    &.#{$class} {
      text-align: #{$ta};
    }
  }

  // SIZES
  $sizes: ("mini", xx-small), ("tiny", x-small), ("small", small),
    ("medium", medium), ("large", large), ("big", x-large), ("huge", xx-large);
  @each $class, $size in $sizes {
    &.#{$class} {
      font-size: #{$size};
    }
  }
  // DECORATION
  $decorations: "overline", "line-through", "underline";
  @each $decoration in $decorations {
    &.#{$decoration} {
      text-decoration: #{$decoration};
    }
  }
  &.overline-underline {
    text-decoration: overline underline;
  }

  // COMPACT
  &.compact {
    margin: 0;
  }

  &.less-margin {
    margin-block: 4px;
  }

  // WIDTH
  &.max {
    width: max-content;
  }
  &.min {
    width: min-content;
  }

  // INLINE
  &.inline {
    display: inline-block;
  }

  // DISABLED
  &.disabled {
    text-decoration: line-through;
    opacity: 0.5;
  }

  // COLORS

  // these colors are from semantic class ui.icon.{color}
  $semantic-blue: ("blue", #2185d0);
  $semantic-green: ("green", #21ba45);
  $semantic-orange: ("orange", #f2711c);
  $semantic-pink: ("pink", #e03997);
  $semantic-purple: ("purple", #a333c8);
  $semantic-red: ("red", #db2828);
  $semantic-teal: ("teal", #00b5ad);
  $semantic-yellow: ("yellow", #fbbd08);
  $semantic-black: ("black", #1b1c1d);
  $semantic-grey: ("grey", #767676);

  $white: ("white", white);
  $primary: ("primary", $color_primary);
  $secondary: ("secondary", $color_secondary);

  // to use give the element class="ui text {color}"
  $text-colors: $semantic-blue, $semantic-green, $semantic-orange,
    $semantic-pink, $semantic-purple, $semantic-red, $semantic-teal,
    $semantic-yellow, $semantic-black, $semantic-grey, $white,
    $primary, $secondary;
  @each $name, $tc in $text-colors {
    &.#{$name} {
      color: #{$tc};
    }
  }
}
