body.settings-page, body.settings-page > main {
    > .container {
        > h1 {
            font-weight: 600;
            text-align: center;
            margin-top: 40px;
            opacity: 0;
            @include prefix(animation, slidedown 400ms ease forwards);
            animation-delay: 100ms;
        }
        > .settings {
            display: flex;
            flex-direction: column;
            padding-bottom: 40px;
            > a.more-settings {
                margin-top: 20px;
                margin-left: auto;
            }
            > .setting-card {
                display: inline-grid;
                grid-template-columns: 260px 1fr;
                padding: 30px 20px;
                border-bottom: 1px solid $color_palette_gray;

                a.left {
                    text-decoration: none;
                    color:black;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 20px;
                    padding: 20px 0;
                    width: 250px;
                    background: $color_white;
                    @include prefix(border-radius, 12px);
                    @include prefix(box-shadow, 0 8px 10px 0 #dbe0e3);
                    text-align: center;
                    transition: box-shadow 200ms ease;
                    &:hover {
                        @include prefix(box-shadow, 0 2px 4px 0 #dbe0e3);
                    }
                    
                    > div {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        gap: 10px;
                        > i {
                            color: $color_black;
                            font-size: 50px;
                            opacity: 0.8;
                        }
                    }
                    h4 {
                        font-size: 18px;
                        font-weight: 600;
                        padding: 0 30px;
                    }
                    a {
                        margin-top: auto;
                    }
                }

                section.right {
                    padding-top: 25px;
                    padding-left: 20px;
                    display: flex;
                    flex-direction: column;
                    gap: 25px;

                    
                }
                opacity: 0;
                @include prefix(animation, slidedown 400ms ease forwards);
                &:nth-child(1) { animation-delay: 200ms; }
                &:nth-child(2) { animation-delay: 300ms; }
                &:nth-child(3) { animation-delay: 400ms; }
                &:nth-child(4) { animation-delay: 500ms; }
                &:nth-child(5) { animation-delay: 600ms; }
                &:nth-child(6) { animation-delay: 700ms; }
            }
        }
    }

  
}
