
@-webkit-keyframes slidein {
  from { opacity: 0; left: 200px; }
  to { opacity: 1; left: 100px; }
}

@-moz-keyframes slidein {
  from { opacity: 0; left: 200px; }
  to { opacity: 1; left: 100px; }
}

@-o-keyframes slidein {
  from { opacity: 0; left: 200px; }
  to { opacity: 1; left: 100px; }
}

@keyframes slidein {
  from { opacity: 0; left: 200px; }
  to { opacity: 1; left: 100px; }
}

@-webkit-keyframes slideout {
  to { opacity: 0; left: 200px; }
  from { opacity: 1; left: 100px; }
}

@-moz-keyframes slideout {
  to { opacity: 0; left: 200px; }
  from { opacity: 1; left: 100px; }
}

@-o-keyframes slideout {
  to { opacity: 0; left: 200px; }
  from { opacity: 1; left: 100px; }
}

@keyframes slideout {
  to { opacity: 0; left: 200px; }
  from { opacity: 1; left: 100px; }
}

body > iframe.subpage-iframe {
    position: fixed;
    top: 0;
    right: 0;
    left: 100px;
    bottom: 0;
    width: calc(100% - 100px);
    @include prefix(box-shadow, 0 0 30px rgba(0, 0, 0, 0.3));
    background: $color_white;
    @include prefix(animation, slidein 300ms ease forwards);
    z-index: 1002;

    &.out {
        @include prefix(animation, slideout 300ms ease forwards);
    }
}

div.subpage-back {
    position: fixed;
    top: 3px;
    left: 40px;
    padding: 10px;
    z-index: 1001;

    opacity: 0;
    @include prefix(animation, fadein 200ms ease forwards);

    &, * {
        cursor: pointer;
    }

    > button {
        display: block;
        @include prefix(border-radius, 100%);
        background: transparent;
        color: $color_white;

        width: 36px;
        height: 36px;
        padding: 0;
        text-align: center;
        border: none;
        background: rgba(255, 255, 255, 0.2);

        > i {
            line-height: 36px;
            font-size: 24px;
        }

        &:hover {
            background: rgba(255, 255, 255, 0.4);
        }

        &:active {
            background: rgba(255, 255, 255, 0.6);
        }
    }
}

body.embed div.subpage-back, body.embed > main div.subpage-back {
    > button {
        background: rgba(255, 255, 255, 0.9);
        color: $color_palette_blue;

        &:active {
            background: rgba(255, 255, 255, 0.8);
        }
    }
}

body > div.subpage-mask {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(33, 40, 76, 0.5);
    z-index: 1000;
}
