
.product-context {
    > span {
        display: inline-block;
        font-size: 9pt;

        > * {
            margin: 0 1px;
        }

        > .name {
            color: $color_gray;
        }

        > .value {
            text-transform: uppercase;
            color: $color_palette_blue;
            @include prefix(border-radius, 3px);
            font-weight: 600;
            text-decoration: none;
        }
    }
}

.navigation {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;

    > * {
        text-align: center;
        height: 60px;
    }

    > .top {
        background: $color_palette_blue;
        position: relative;

        > .logo {
            display: inline-block;
            
            width: 160px;
            height: 60px;
            padding: 0;
            margin-top: 13px;
            margin-bottom: 10px;

            background-size: 100%;
            background-repeat: no-repeat;
            background-position: 50% 50%;

            border: none;
        }

        > .dropdown {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 20;

            > a {
                color: $color_white;
                display: inline-block;
                padding: 18px 21px;
                text-decoration: none;
                display: flex;
                gap: 3px;
                align-items: center;
                padding-left: 100px;

                > i {
                    font-size: 24px;
                }
            }

            > div {
                display: none;
                position: absolute;
                right: 10px;
                top: 50px;
                width: 200px;

                > ul {
                    list-style: none;
                    padding: 6px 0;
                    margin: 0;
                    text-align: left;
                    font-size: 10pt;
                    background: $color_white;
                    @include prefix(border-radius, 3px);
                    @include prefix(box-shadow, 0 4px 10px rgba(0, 0, 0, 0.1));
                    overflow: hidden;

                    > li {
                        &.separator {
                            border-top: solid 1px $color_palette_smoke;
                            margin: 6px 0;
                        }

                        > a {
                            display: block;
                            padding: 10px 20px;
                            color: $color_palette_gray;
                            text-decoration: none;
                            border-left: solid 4px transparent;

                            &:hover {
                                opacity: 0.6;
                                background: $color_palette_smoke;
                                color: $color_palette_red;
                            }

                            &:active {
                                opacity: 1;
                                color: $color_black;
                            }

                            &.active {
                                color: $color_palette_red;
                                border-color: $color_palette_red;
                            }
                        }
                    }
                }
            }

            &:hover {
                > div {
                    display: block;

                    opacity: 0;
                    @include prefix(animation, slidedown 200ms ease forwards);
                }
            }
        }
    }

    > .bottom {
        background: $color_white;
        position: relative;

        > .product-context {
            position: absolute;
            top: 0;
            right: 0;
            padding: 18px;
        }

        > ul {
            margin: 0;
            padding: 0;
            list-style: none;

            > li {
                display: inline-block;
                vertical-align: bottom;
                margin: 0 20px;

                > a {
                    display: block;
                    text-transform: uppercase;
                    font-size: 14px;
                    font-weight: 600;
                    text-decoration: none;
                    padding: 21px 0 18px;
                    border-bottom: solid 3px transparent;
                    color: $color_palette_gray;

                    &.active {
                        color: $color_palette_red;
                        border-color: $color_palette_red;
                    }

                    &.done {
                        color: $color_palette_blue;
                    }
                }
            }
        }
    }
}
