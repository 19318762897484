.adword-mock {

  padding: 3px;
  overflow: hidden;

  * {
    font-family: sans-serif !important;
  }

  a {
    text-decoration: none;
    color: #1a0dab;
  }
  a:hover {
    text-decoration: underline;
  }

  .title {
    font-size: 18px;
  }

  .tag {
    background-color: #fff;
    border-radius: 2px;
    color: #006621;
    display: inline-block;
    font-size: 11px;
    border: 1px solid #006621;
    padding: 0 2px 0 2px;
    line-height: 14px;
    vertical-align: baseline;
  }

  .path {
    line-height: 18px;
    font-size: 14px;
    color: #006621;

    .link {
      color: #006621;
      margin-right: 3px;
      &:hover {
        text-decoration: none;
      }
    }

    .fa {
      position: absolute;
      margin-top: 7px;
      margin-left: 6px;
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 5px solid #006621;
    }

  }

  .divisor {
    border-bottom: 1px solid #ebebeb;
    margin: 9px -16px 10px;
  }

  .card {
    background: #fff;
    border-radius: 3px;
    overflow: hidden;
    padding: 10px;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.14), 0 2px 2px 0 rgba(0, 0, 0, 0.098), 0 1px 5px 0 rgba(0, 0, 0, 0.084);
  }


  .description {
    line-height: 18px;
    font-size: 13px;
    color: #545454;
  }

}
