
@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}

@-moz-keyframes rotation {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(359deg);
  }
}

@-o-keyframes rotation {
  from {
    -o-transform: rotate(0deg);
  }
  to {
    -o-transform: rotate(359deg);
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}


@-webkit-keyframes fadein {
  from { opacity: 0; }
  to { opacity: 1; }
}

@-moz-keyframes fadein {
  from { opacity: 0; }
  to { opacity: 1; }
}

@-o-keyframes fadein {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadein {
  from { opacity: 0; }
  to { opacity: 1; }
}


@-webkit-keyframes marginslideleft {
  from {
    margin-left: 10px;
    opacity: 0;
  }
  to {
    margin-left: 0;
    opacity: 1;
  }
}

@-moz-keyframes marginslideleft {
  from {
    margin-left: 10px;
    opacity: 0;
  }
  to {
    margin-left: 0;
    opacity: 1;
  }
}

@-o-keyframes marginslideleft {
  from {
    margin-left: 10px;
    opacity: 0;
  }
  to {
    margin-left: 0;
    opacity: 1;
  }
}

@keyframes marginslideleft {
  from {
    margin-left: 10px;
    opacity: 0;
  }
  to {
    margin-left: 0;
    opacity: 1;
  }
}


@-webkit-keyframes marginslideright {
  from {
    margin-right: 10px;
    opacity: 0;
  }
  to {
    margin-right: 0;
    opacity: 1;
  }
}

@-moz-keyframes marginslideright {
  from {
    margin-right: 10px;
    opacity: 0;
  }
  to {
    margin-right: 0;
    opacity: 1;
  }
}

@-o-keyframes marginslideright {
  from {
    margin-right: 10px;
    opacity: 0;
  }
  to {
    margin-right: 0;
    opacity: 1;
  }
}

@keyframes marginslideright {
  from {
    margin-right: 10px;
    opacity: 0;
  }
  to {
    margin-right: 0;
    opacity: 1;
  }
}


@-webkit-keyframes slideleft {
  from {
    right: 0;
    opacity: 0;
  }
  to {
    right: 10px;
    opacity: 1;
  }
}

@-moz-keyframes slideleft {
  from {
    right: 0;
    opacity: 0;
  }
  to {
    right: 10px;
    opacity: 1;
  }
}

@-o-keyframes slideleft {
  from {
    right: 0;
    opacity: 0;
  }
  to {
    right: 10px;
    opacity: 1;
  }
}

@keyframes slideleft {
  from {
    right: 0;
    opacity: 0;
  }
  to {
    right: 10px;
    opacity: 1;
  }
}


@-webkit-keyframes slideright {
  from {
    left: 0;
    opacity: 0;
  }
  to {
    left: 10px;
    opacity: 1;
  }
}

@-moz-keyframes slideright {
  from {
    left: 0;
    opacity: 0;
  }
  to {
    left: 10px;
    opacity: 1;
  }
}

@-o-keyframes slideright {
  from {
    left: 0;
    opacity: 0;
  }
  to {
    left: 10px;
    opacity: 1;
  }
}

@keyframes slideright {
  from {
    left: 0;
    opacity: 0;
  }
  to {
    left: 10px;
    opacity: 1;
  }
}


@-webkit-keyframes marginslideup {
  from {
    opacity: 0;
    margin-bottom: -10px;
  }
  to {
    opacity: 1;
    margin-bottom: 10px;
  }
}

@-moz-keyframes marginslideup {
  from {
    opacity: 0;
    margin-bottom: -10px;
  }
  to {
    opacity: 1;
    margin-bottom: 10px;
  }
}

@-o-keyframes marginslideup {
  from {
    opacity: 0;
    margin-bottom: -10px;
  }
  to {
    opacity: 1;
    margin-bottom: 10px;
  }
}

@keyframes marginslideup {
  from {
    opacity: 0;
    margin-bottom: -10px;
  }
  to {
    opacity: 1;
    margin-bottom: 10px;
  }
}


@-webkit-keyframes slidedown {
  from { opacity: 0; transform: translateY(-20px); }
  to { opacity: 1; transform: translateY(0); }
}

@-moz-keyframes slidedown {
  from { opacity: 0; transform: translateY(-20px); }
  to { opacity: 1; transform: translateY(0); }
}

@-o-keyframes slidedown {
  from { opacity: 0; transform: translateY(-20px); }
  to { opacity: 1; transform: translateY(0); }
}

@keyframes slidedown {
  from { opacity: 0; transform: translateY(-20px); }
  to { opacity: 1; transform: translateY(0); }
}


@-webkit-keyframes translateslideleft {
  from { opacity: 0; transform: translateX(20px); }
  to { opacity: 1; transform: translateX(0); }
}

@-moz-keyframes translateslideleft {
  from { opacity: 0; transform: translateX(20px); }
  to { opacity: 1; transform: translateX(0); }
}

@-o-keyframes translateslideleft {
  from { opacity: 0; transform: translateX(20px); }
  to { opacity: 1; transform: translateX(0); }
}

@keyframes translateslideleft {
  from { opacity: 0; transform: translateX(20px); }
  to { opacity: 1; transform: translateX(0); }
}


@-webkit-keyframes translateslideright {
  from { opacity: 0; transform: translateX(-20px); }
  to { opacity: 1; transform: translateX(0); }
}

@-moz-keyframes translateslideright {
  from { opacity: 0; transform: translateX(-20px); }
  to { opacity: 1; transform: translateX(0); }
}

@-o-keyframes translateslideright {
  from { opacity: 0; transform: translateX(-20px); }
  to { opacity: 1; transform: translateX(0); }
}

@keyframes translateslideright {
  from { opacity: 0; transform: translateX(-20px); }
  to { opacity: 1; transform: translateX(0); }
}
