body {
    #edit-text-modal .modal {
        max-width: 1200px;
    }

    #editor {
        font-family: $font_monospace;
        min-width: 400px !important;
        max-width: 50% !important;
        height: 65vh !important;
        padding: 10px;
    }

    #preview {
        padding: 10px;
        height: 65vh !important;
        overflow: auto;
        &.partial {
            height: auto !important;
        }
    }

}