body {
    #sku-ean-modal .modal {
        max-width: 400px;
    }

    input#sku-ean {
        width: 100%;
        padding: 11px 10px;
        font-size: 12pt;
    }
}