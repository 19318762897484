
body.manage, body.manage > main {
    &.full-height {
        min-height: calc(100vh - 50px);
        .manage-content-wrapper {
            display: flex;
            min-height: calc(100vh - 50px);
            .manage-content {
                flex-grow: 1;
                .manage-content-grid {
                    height: 100%;
                    display: grid;
                    grid-template-rows: auto 1fr;
                }
            }
        }
    }

    .manage-header {
        text-align: center;
        width: 50%;
        margin: auto;
        padding: 20px 20px 0 20px;
    }

    .actions:not(.modal-actions) {
        padding: 20px;
        background: $color_white;
        left: 0;
        right: 0;
        &.position-static {
            position: static;
        }
        &.z-index-1 {
            z-index: 1;
        }

        > .container {
            text-align: center;
            display: block;
            > h1 {
                margin: 0;
            }

            > a {
                float: left;
            }

            > button {
                float: right;

                > a {
                    text-decoration: none;
                    color: $color_white;
                }
            }

            > a.add {
                float: right;

                &.blank {
                    width: 170px;
                    padding: 10px;
                }
            }
            &.two-items {
                position: relative;
                > a {
                    position: absolute;
                    left: 0;
                }
            }
        }
    }
    .container {
        &.margin-top {
            margin-top: 20px;
        }
        &.no-max-width {
            max-width: unset;
        }
        &.align-center {
            align-items: center;
        }
        &.width-max-content {
            width: max-content;
        }

        white-space: nowrap;
        &.unset-wrap{
            white-space: unset;
        }
        display: flex;
        flex-direction: column;
        justify-content: center;

        > h2 {
            text-align: center;
        }

        .view-actions {
            padding: 20px;
            border: solid 1px rgba(0, 0, 0, 0.1);
            border-radius: 10px;
            margin-top: 20px;

            > h3 {
                text-align: center;
                margin-top: 0;
            }
            form {
                display: flex;
                flex-direction: column;

                > div {
                    margin: auto;
                }

                input, textarea, select {
                    padding: 10px;
                    margin-bottom: 5px;
                }
            }
        }
        .view-search {
            margin-top: 20px;
            display: flex;
            align-items: center;
            justify-content: center;

            form {
                flex-direction: column;

                input {
                    padding: 10px;
                    margin-bottom: 5px;
                }
            }
        }
        .metrics-big-table {
            margin: 20px auto;
            overflow-x: scroll;
            width: 90vw;
        }
        .manage-table {
            width: 600px;
            margin: 20px auto;
            border-spacing: 0;
            margin-bottom: 100px;
            &.no-margin{
                margin: 20px 0;
            }
            &.full-width {
                width: 100%;
            }

            th {
                font-size: 10pt;
                text-transform: uppercase;

                .info {
                    font-weight: normal;
                    text-transform: none;
                    white-space: normal;
                }
            }

            th, td {
                text-align: center;
                padding: 10px 12px;

                &:first-of-type {
                    text-align: left;
                    padding-left: 40px;
                }
                &.right-align {
                    text-align: right;
                }
                > span {
                    display: inline-block;
                    vertical-align: middle;

                    &.flag {
                        font-size: 20px;
                        margin-right: 6px;
                    }
                }
                .two-button-action {
                    display:flex; 
                    align-items:center; 
                    gap:10px;
                }
            }

            tbody tr {
                &:hover {
                    background: $color_white;
                    color: $color_palette_red;
                }

                td {
                    border-top: solid 1px rgba(0, 0, 0, 0.1);
                }
            }

            &.manage-label-table {
                form {
                    display: inline-block;
                }
                a {
                    cursor: pointer;
                    color: blue;
                    text-decoration: underline;
                }
            }

            .pview-actions > * {
                display: inline-block;
            }

            .assign-user {
                td {
                    text-align: center;
                    a {
                        width: 100%;
                    }
                }
            }
        }

        > .groups {
            display: flex;
            flex-direction: row;
            justify-content: center;

            > .group {
                display: inline-block;
                vertical-align: top;
                width: 25%;
                padding: 10px 10px;
                padding-bottom: 100px;

                > h2 {
                    margin: 14px 16px;
                    color: $color_black;
                }

                > ul {
                    margin: 0;
                    padding: 0;
                    list-style: none;

                    background: $color_white;

                    > li {
                        > label,
                        > a {
                            display: block;
                            padding: 12px 16px;
                            font-size: 11pt;
                            text-decoration: none;
                            color: $color_gray;
                            min-height: 110px;

                            padding-left: 13px;
                            border-left: solid 3px transparent;

                            &:hover {
                                color: $color_palette_red;
                                border-color: $color_palette_red;
                            }

                            &:active {
                                background: rgba(0, 0, 0, 0.1);
                                color: $color_black;
                                border-color: $color_black;
                            }

                            > span {
                                display: block;
                                white-space: initial;

                                &:first-of-type {
                                }

                                &:last-of-type {
                                    margin-top: 6px;
                                    font-size: 8pt;
                                }
                            }

                            cursor: pointer;

                            > input {
                                display: inline-block;
                                float: left;
                                margin: 0;
                                margin-right: 8px;
                            }
                            > select {
                                margin: 5px 0;
                                width: 100%;
                                padding: 2px;
                            }
                        }

                        border-bottom: solid 1px $color_palette_smoke;

                        &:last-of-type {
                            border-bottom: none;
                        }
                    }
                }
            }
        }

        &.edit-form {
            padding-left: 10%;
            padding-right: 10%;

            p:not(.no-flex) {
                display: flex;
                flex-direction: column;
                input, textarea, select {
                    padding: 10px;
                    margin-top: 8px;
                    font-family: "Montserrat", sans-serif;
                    font-weight: 500;
                    font-size: 13px;
                }
                textarea.json-textarea {
                    font-family: $font_monospace;
                    height: 400px;
                }
                span {
                    font-size: 13px;
                    text-align: right;
                }
                .error-message {
                    color: red;
                    font-size: 14px;
                }
            }
            ul.errorlist {
                margin-bottom: 10px;
                font-weight: bold;
            }
        }

        &.delete-confirmation {
            text-align: center;
        }

        .viewset-views-table {
            white-space: normal;
            word-break: break-word;
            width: 100%;
        }

        &.note-container {
            margin-top: 30px;
            background-color: #ffffff;
            padding: 20px;

            > div {

                > textarea {
                    min-height: 500px;
                }

                > p {
                    white-space: pre-wrap;
                }

                &[data-note-target="preview"] {
                    display: none;
                    border-top: solid 1px rgba(0, 0, 0, 0.2);
                    padding-top: 20px;
                    min-height: 500px;
                }

            }

            > ul {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                list-style-type: none;
                margin: 0;
                padding: 0 0 20px 0;
                height: 60px;

                > li {
                    display: flex;
                    flex-direction: column;
                    width: 15%;
                    height: 100%;
                    text-align: center;
                    justify-content: center;
                    white-space: pre-wrap;

                    > a {
                        text-decoration: none;
                        color: $color_black;
                        cursor: pointer;

                        &.active {
                            color: $color_palette_blue;
                            font-weight: bold;
                        }
                    }
                }

            }

        }
    }

    dd {
        margin-top: 0.2em;
        margin-bottom: 1em;
    }
}
