
body.processors, body.extractors, body.processors > main, body.extractors > main {
    .layout {
        width: 960px;
        padding: 40px 0 0;
        position: relative;
        margin: 20px auto;
        margin-bottom: 100px;

        > .product-label {
            position: absolute;
            top: 0;
            left: 0;

            select {
                font-size: 14pt;
                padding: 7px;
            }
        }
    }
}

.partial.processors-list {
    margin-bottom: 100px;

    .partial.run {
        position: unset;
    }
}

.processors-list,
body.processors .layout > #list,
body.extractors .layout > #list, 
body.processors > main .layout > #list,
body.extractors > main .layout > #list {

    button.uuid-gen {
        background: transparent;
        border: none;
        color: $color_palette_blue;
        cursor: pointer;
        padding: 5px 0;

        &:hover {
            color: $color_palette_red;
        }

        &:active {
            color: $color_black;
        }
    }

    > div {
        > .add-key {
            padding: 20px;
            border: solid 1px rgba(0, 0, 0, 0.1);
            border-radius: 10px;
            margin-top: 20px;

            > h3 {
                text-align: center;
                margin-top: 0;
            }
            form {
                display: flex;
                flex-direction: column;

                > div {
                    margin: auto;
                }

                input {
                    padding: 10px;
                    margin-bottom: 5px;
                }
            }
        }

        > .run {
            position: fixed;
            top: 80px;
            right: 20px;

            > form {
                > button {
                    margin: 0;
                }
            }
        }

        > ul {
            margin: 0;
            padding: 0;
            list-style: none;

            > li {
                padding: 0;
                margin: 20px 0;
                background: $color_white;
                @include prefix(border-radius, 10px);

                &.empty {
                    padding: 20px;
                    text-align: center;
                    color: $color_gray;
                    font-size: 10pt;
                }

                &:last-of-type {
                    border-bottom: none;
                }

                > .partial {

                    .mapping-actions {
                        display: flex;
                        flex-direction: row;
                        align-items: stretch;

                        > * {
                            padding: 20px;
                            vertical-align: top;
                            font-size: 10pt;
                        }

                        .key {
                            width: 20%;

                            > span {
                                font-family: monospace;
                                white-space: initial;

                                &.global {
                                    color: $color_palette_blue;
                                    font-weight: bold;
                                }
                            }
                        }

                        .name {
                            width: 27%;

                            > form {
                                > p {
                                    margin: 0;

                                    > label {
                                        display: block;
                                        color: $color_black;
                                        font-size: 9pt;
                                        margin-bottom: 5px;
                                        text-transform: uppercase;
                                    }

                                    select {
                                        width: 100%;
                                        padding: 6px;
                                    }

                                }
                            }

                        }

                        .options {
                            width: 30%;

                            > form {
                                > p {
                                    margin: 0;
                                    margin-bottom: 20px;

                                    &:last-of-type {
                                        margin-bottom: 0;
                                    }

                                    > label {
                                        display: block;
                                        color: $color_black;
                                        font-size: 9pt;
                                        margin-bottom: 5px;
                                        text-transform: uppercase;
                                    }

                                    > select, > input {
                                        width: 100%;
                                        padding: 7px;
                                    }

                                    > input[type="checkbox"] {
                                        width: initial;
                                    }

                                    > .helptext {
                                        margin-top: 6px;
                                        display: block;
                                        font-size: 8pt;
                                        color: $color_gray;
                                        width: 100%;
                                        white-space: initial;
                                    }
                                }
                            }
                        }

                        .actions {
                            width: 23%;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;

                            > * {
                                display: block;
                                margin-bottom: 5px;
                            }

                            button {
                                margin: 0;
                            }
                        }
                    }

                    .mapping-info {
                        border-top: solid 1px $color_smoke;
                        padding: 10px;

                        > * {
                            display: inline-block;
                            vertical-align: middle;
                            margin-right: 10px;
                        }

                        > form {
                            > button {
                                margin: 0;
                                background: transparent;
                                color: $color_palette_blue;
                                cursor: pointer;
                                border: none;

                                &:hover {
                                    color: $color_palette_red;
                                }

                                &:active {
                                    color: $color_black;
                                }

                                font-size: 9pt;
                                font-weight: normal;
                            }
                        }

                        > span {
                            font-size: 9pt;
                            font-weight: normal;
                        }

                    }
                }
            }
        }
    }
}
