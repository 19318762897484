form.ui.form.lexicon-editor {

    label[for="root_forms"],
    label[for="root"] {
        display: none;
    }
    label {
        text-transform: none;
        font-size: inherit;
        font-weight: inherit;
    }

    legend {
        display: none;
    }

    fieldset {
        padding: 0;
        border: none;
    }
    .field.field-object {
        margin-top: 0;
        margin-bottom: 0;
    }
    .field:not(.field-object) {
        display: grid;
        grid-template-columns: 200px auto;
        align-items: baseline;

        &.hidden {
            display: none;
        }
    }
}

body.translation-lexicons, body.translation-lexicons > main {
    .lexicon-items {
        form.ui.form.lexicon-editor {
            fieldset#root {
                > :nth-child(1) {
                    select {
                        padding: 10px;
                        width: 150px;
                    }
            }
        }
        .field:not(.field-object) {
                display: inline-block;
                label {
                    padding-left: 0;
                }
            }
        }
    }
}
