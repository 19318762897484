.alert-box {
    background: $color_white;
    @include prefix(border-radius, 5px);
    @include prefix(box-shadow, 0 3px 6px 0 #dbe0e3);
    margin: 10px auto;
    width: 100%;
    max-width: 600px;

    .inner {
        padding: 8px 20px;
        display: flex;
        align-items: center;
        gap: 30px;
        button {
            background: none;
            border: none;
            cursor: pointer;
            font-size: 24px;
            &:hover {
                color: $color_gray;
            }
        }
    }

    &.info {
        background: $color_white;
    }

    &.success {
        background: lighten($color_success, 30%);
    }

    &.warning {
        background: lighten($color_warning, 30%);
    }

    &.error {
        background: lighten($color_error, 30%);
    }
}
