
body.vocabulary-list, body.vocabulary-list > main {

    background: $color_white;

    .container {
        max-width: none;
        width: 96%;

        > h1 {
            font-size: 16pt;
            margin: 30px 0;
        }

        > div {
            display: flex;
            flex-direction: row;

            > .left {
                flex: 1;

                > .top {
                    display: flex;
                    flex-direction: row;

                    > .search {
                        flex: 1;

                        > input[type="text"] {
                            width: 100%;
                            padding: 8px 20px;
                            font-size: 10pt;
                        }
                    }

                    > .actions {
                        flex: 1.5;
                        text-align: right;

                        > form {
                            display: inline-block;

                            > div {
                                width: 100%;
                                display: inline-block;

                                > select {
                                    padding: 6px 12px;
                                    font-size: 10pt;
                                }
                            }
                        }
                    }
                }
            }

            > .right {
                flex: 0 0 200px;
                margin-left: 20px;

                > .filters {
                    background: $color_palette_smoke;
                    padding-bottom: 10px;

                    > * {
                        padding: 10px 20px;
                    }

                    > h2 {
                        margin: 0;
                        background: $color_palette_blue;
                        color: $color_white;
                        text-transform: uppercase;
                        letter-spacing: 2px;
                        font-size: 10pt;
                        font-weight: 500;

                        @include prefix(border-radius, 4px 4px 0 0);
                    }

                    > div {
                        > h3 {
                            padding: 0;
                            margin: 10px 0;
                            font-size: 11pt;
                            font-weight: 600;
                        }

                        > form > input[type="text"] {
                            display: none;
                        }

                        > select {
                            display: block;
                            height: 38px;
                            width: 100%;
                            min-width: 0;
                            padding: 0.22619048em 2.1em 0.22619048em 0.35714286em;
                            cursor: pointer;
                            > option {
                                text-transform: capitalize;
                            }
                        }

                        > ul {
                            margin: 0 -20px;
                            padding: 0;
                            list-style: none;

                            > li {
                                display: block;

                                > a {
                                    display: block;
                                    font-size: 10pt;
                                    padding: 8px 30px;
                                    border-left: solid 4px transparent;
                                    text-decoration: none;
                                    color: $color_gray;

                                    &:hover {
                                        color: $color_black;
                                    }

                                    &.active {
                                        border-color: $color_palette_red;
                                        background: $color_white;
                                        color: $color_palette_red;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .translation-owners {
        width: 100%;
        flex: none;
        height: 12px;
        background: rgba(0, 0, 0, 0.1);
        display: inline-block;
        padding: 2px;
        margin: 0 !important;
        @include prefix(border-radius, 3px);
        white-space: nowrap;
        font-size: 0;
        vertical-align: middle;

        > .owner {
            margin: 0 !important;
            padding: 0 !important;

            height: 100%;
            display: inline-block;
            vertical-align: top;

            &.system {
                background: $color_white;
            }

            &.customer {
                background: $color_palette_blue;
            }
        }
    }
}
