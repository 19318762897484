
.collapsible {
    > .inner {
        background: $color_white;
        padding: 10px 20px;
        margin: 20px;
        @include prefix(border-radius, 6px);

        > .field {
            > label {
                font-size: 9pt;
                color: $color_gray;
                text-align: left;
                text-transform: none;
            }
        }
    }

    &.collapsed {
        > button {
            > i {
                &.up {
                    display: none;
                }
            }
        }

        > .inner {
            display: none;
        }
    }

    &.expanded {
        > button {
            > i {
                &.down {
                    display: none;
                }
            }
        }

        > .inner {
            display: block;
        }
    }
}
