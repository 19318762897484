body.embed.inriver, body.inriver_cvl_import, body.embed.inriver > main, body.inriver_cvl_import > main {
    > .container {
        width: 100%;

        .partial.loading {
            * {
                display: none;
            }
        }

        > h1 {
            font-size: 24px;
            font-weight: 600;
            text-align: center;
            margin: 30px 0;
        }

        .details > p {
            text-align: center;
            font-size: 12px;
            font-weight: 500;
        }

        .pbutton {
            text-align: center;
            margin: 30px auto;
        }

        .form {
            width: 600px;
            padding: 10px 30px;
            margin: 20px auto;
            background: $color_white;
            @include prefix(border-radius, 12px);
            @include prefix(box-shadow, 0 10px 24px 0 #dbe0e3);

            .pbutton {
                margin: 20px auto;
            }

            li {
                list-style-type: none;
                padding: 5px 0;
                label {
                    cursor: pointer;
                    display: block;
                    width: 100%;
                }
            }
        }

        .product-details {
            min-width: 600px;
            width: 100%;
            margin: 0 auto;

            > .texts {
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 20px;

                > .card {
                    width : 300px;
                    padding: 10px;
                    margin: 20px 0;
                    flex: 1 1 auto;

                    > .language {
                        font-size: 14px;
                        font-weight: 600;
                    }

                    > .text {
                        padding: 5px;
                        height: 100%;
                        background: $color_smoke;

                        > h1 {
                            font-size: 20px;
                            font-weight: 600;
                        }
                    }
                }
            }
        }

        .other {
            text-align: center;
            margin-top: 50px;
            margin-bottom: 60px;

            > h3 {
                font-size: 14px;
                font-weight: 600;
                text-transform: uppercase;
            }

            > ul {
                list-style: none;
                margin: 0;
                padding: 0;

                > li {
                    > a {
                        display: block;
                        font-size: 14px;
                        font-weight: 600;
                        color: $color_palette_red;
                        text-decoration: none;
                    }
                }
            }

            > h3,
            > ul > li > a {
                margin: 20px 0;
            }
        }

    }

    .TagTreeEditor {

        .generated-text {
            display: none;
        }

        .rst__tree {
            min-height: 100px;
        }

        .rst__placeholder::before {
            width: 670px;
            height: 40px;
        }

        .rstcustom__row {
            margin-left: 10px;
            padding-left: 0px !important;
        }

        .rstcustom__collapseButton, .rstcustom__expandButton {
            padding-left: 0px !important;
        }

        .rstcustom__toolbarButton > div {
            right: 90px !important;
        }

        .rstcustom__nodeContent {
            width: 100%;
        }
    }
}
