.modal-overlay {
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.3s;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1000;
}

.modal[role=dialog] {
    pointer-events: none;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s;
    position: fixed;
    top: 8%;
    left: 0;
    right: 0;
    margin: 0 auto;
    padding: 0px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
    z-index: 1000;
}

.modal-container {
    display: flex;
    justify-content: center;
    padding: 0 20px;
}

.modal-content {
    padding: 5px;
    border-bottom: 1px solid gray;
    margin: 15px;
}

.modal-header {
    > h3{
        margin: 10px 0;
    }
}

.modal-body {
    margin: 10px;

    > .fit-content {
        width: fit-content;
    }
}

.modal-footer {
    overflow: hidden;
    float: right;
    margin-top: 10px;
}

.modal-footer > * {
    margin: 15px 10px;
}

.modal-body #error {
    color: red;
    font-weight: bold;
}