
body.template-list, body.template-list > main {

    background: $color_white;

    .container {
        max-width: none;
        width: 96%;

        > h1 {
            font-size: 16pt;
            margin: 30px 0;
        }
    }

}
