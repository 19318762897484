
$color_primary: rgb(34, 61, 133);
$color_secondary: #0E1936;

$color_success: #8BC24A;    // green
$color_error: #F44236;      // red
$color_errorlight: #F44236; // light red
$color_warning: #FF9800;    // orange

$color_darksmoke: #bfbfbf;  // not so light gray
$color_smoke: #efefef;      // light gray
$color_lightsmoke: #f6f6f6; // lighter gray

$color_white: #ffffff;
$color_black: #272727;
$color_gray: #666666;

$color_palette_blue: #233d84;
$color_palette_red: #d74c50;
$color_palette_smoke: #f4f5f9;
$color_palette_gray: #9d9ea2;
$color_palette_darkblue: #21284c;
