
body.product-bulk-action:not(body.manage), .pipeline-partial-bulk-action, body.product-bulk-action:not(body.manage) > main {
    .container {
        margin: 30px auto;
        width: 900px;

        &.full-width {
            padding-inline: 10px;
            width: unset;
            margin: 30px 0;
            max-width: unset;
        }
        &.no-margin {
            margin: 0;
        }

        > .selection {
            font-size: 12pt;
            padding: 10px 20px;

            > span {
                > * {
                    display: inline-block;
                    vertical-align: middle;
                }

                &:first-of-type {
                    font-weight: 600;
                }

                &:last-of-type {
                    float: right;
                }
            }
        }

        > .section {
            background: $color_white;
            @include prefix(border-radius, 10px);

            > form {
                input[name="tag"],
                textarea[name="tags"] {
                    display: none;
                }

                input[name="label"] {
                    padding: 10px 14px;
                    font-weight: 500;
                }

                select {
                    padding: 10px 14px;
                    font-weight: 500;
                }

                > .actions {
                    text-align: right;
                    padding: 20px;
                    border-top: solid 1px $color_palette_smoke;
                    overflow: hidden;

                    > .warning {
                        width: 100%;
                        text-align: left;
                        float: left;
                        margin: 10px;
                    }

                    > #status_change_warning {
                        display: None;
                    }
                }

                > .subactions {
                    text-align: left;
                    padding: 20px;
                }

                > .field {
                    padding: 14px 20px 20px;
                    margin: 0;

                    svg {
                        margin: -3px;
                    }

                    > *:not(:hidden) {
                        display: block;
                    }
                    .extra-spacing {
                        margin-bottom: 8px;
                    }
                    > .label {
                        display: inline-block;
                        margin: 6px 10px;
                        margin-top: 0;

                        > label {
                            font-size: 9pt;
                            color: $color_gray;
                            text-transform: uppercase;
                        }
                    }

                    > select,
                    > input,
                    > textarea {
                        padding: 8px 10px;
                        font-size: 12pt;
                    }

                    > input[data-t-component="vocabulary-lookup"] {
                        visibility: hidden;
                    }

                    border-bottom: solid 1px $color_palette_smoke;

                    &.last {
                        border-bottom: 0;
                    }

                    &.checkbox {
                        padding: 10px 20px;

                        > label {
                            margin: 6px 0;
                            padding: 0;
                        }
                    }

                    &.single-checkbox {
                        padding: 10px 20px;

                        > label {
                            margin: 0;
                        }
                    }


                    > .choices {
                        padding: 20px 0;

                        > ul {
                            padding: 0;
                            list-style: none;
                            padding: 0 20px;
                            margin: -20px 0;

                            > li {
                                display: inline-block;
                                vertical-align: top;
                                margin: 20px;

                                > label {
                                    position: relative;

                                    > i {
                                        display: none;
                                        position: absolute;
                                        right: 0;
                                        top: 50%;
                                        width: 24px;
                                        height: 24px;
                                        margin-right: -12px;
                                        margin-top: -12px;
                                        font-size: 14px;
                                        background: $color_palette_red;
                                        color: $color_white;
                                        padding: 5px;
                                        @include prefix(border-radius, 100%);
                                    }

                                    cursor: pointer;
                                    display: block;
                                    width: 220px;
                                    padding: 21px 40px;

                                    > span {
                                        font-size: 14px;
                                        font-weight: 600;
                                        display: block;
                                        white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                    }

                                    @include prefix(box-shadow,  0 10px 24px 0 #dbe0e3);
                                    @include prefix(border-radius, 10px);

                                    border: solid 2px transparent;
                                }

                                > input {
                                    display: none;
                                }

                                > input:checked + label {
                                    border-color: $color_palette_red;

                                    > i {
                                        display: block;
                                    }
                                }
                            }
                        }
                    }

                    &.generate-selection {
                        border-bottom: none;
                        > .generate-selection-columns {
                            display: flex;
                            > .generate-selection-column {
                                width: 100%;

                                > label {
                                    font-size: 18px;

                                    > small {
                                        font-size: 14px;
                                    }
                                }
                            }
                        }
                    }

                    &.generate-summary {
                        .bottom > div {
                            margin-bottom: 5px;

                            > span {
                                font-weight: bold;
                                margin-right: 5px;
                            }
                        }
                    }

                    &.generate-options {
                        > div {
                            width: 100%;
                            margin: 10px auto;
                            display: flex;
                            align-items: center;
                            > * {
                                display: inline-block;
                            }

                            > input {
                                height: 20px;
                                width: 20px;
                                margin: 15px;
                            }
                        }
                    }
                }
            }
        }

        .block-choices {
            padding: 20px 0;

            > .label {
                text-align: center;
                font-size: 18px;
                font-weight: 600;
                margin: 40px 0;
            }

            > .toggle {
                text-align: center;
                margin: -20px 0 40px;

                > * {
                    display: inline-block;
                    vertical-align: middle;
                }

                > button, > span {
                    color: $color_palette_red;
                    background: transparent;
                    border: none;
                    font-size: 14px;
                }

                > button {
                    cursor: pointer;
                }
            }

            > ul {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                list-style: none;
                margin: -20px 0;

                > li {
                    display: inline-block;
                    vertical-align: top;
                    margin: 20px;

                    > label {
                        position: relative;

                        > i {
                            display: none;
                            position: absolute;
                            right: 0;
                            top: 50%;
                            width: 24px;
                            height: 24px;
                            margin-right: -12px;
                            margin-top: -12px;
                            font-size: 14px;
                            background: $color_palette_red;
                            color: $color_white;
                            padding: 5px;
                            @include prefix(border-radius, 100%);
                        }

                        cursor: pointer;
                        display: block;
                        width: 220px;
                        padding: 21px 40px;

                        > .flag {
                            float: left;
                            font-weight: normal;
                            font-size: 18px;
                            margin: -1px 10px 0 -18px;
                        }

                        > span {
                            font-size: 14px;
                            font-weight: 600;
                            display: block;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                        > p {
                            text-wrap: initial;
                        }

                        @include prefix(box-shadow,  0 10px 24px 0 #dbe0e3);
                        @include prefix(border-radius, 10px);

                        border: solid 2px transparent;
                    }

                    > input {
                        display: none;
                    }

                    > input:checked + label {
                        border-color: $color_palette_red;

                        > i {
                            display: block;
                        }
                    }
                }
            }
        }
    }
    .container.w1100 {
        width: 1100px;
        max-width: 1100px;
    }
}
