
body.download, body.download > main {
    margin: 20px 0;

    > .container {
        width: $wide_mini;
    }

    form {
        .actions {
            margin-bottom: 40px;

            > * {
                margin-right: 6px;
            }
        }

        .field > ul {
            margin: 10px 0;
            padding: 0;
            list-style: none;
            font-size: 9pt;

            > li {
                > label {
                    display: block;
                    padding: 2px 15px;

                    > input {
                        vertical-align: middle;
                        cursor: pointer;
                    }

                    cursor: pointer;
                    color: $color_secondary;

                    &:hover {
                        color: $color_primary;
                    }

                    &:active {
                        color: $color_black;
                    }
                }
            }
        }

        .columns {
            font-size: 0;

            > * {
                display: inline-block;
                vertical-align: top;
                font-size: 10pt;
            }
        }
    }
}
