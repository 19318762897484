
.partial.generate-task {
    > .wizard-dialog > .dialog {

        > .inner {
            background: $color_smoke;

            > .system {
                padding: 10px 20px;
                border-bottom: solid 1px rgba(0, 0, 0, 0.06);
                font-size: 9pt;
                color: $color_gray;

                &, * {
                    font-family: monospace;
                }

                display: none;
            }

            > .status {
                padding: 20px;

                > ul {
                    padding: 0;
                    margin: 0;
                    list-style: none;

                    > li {
                        margin: 10px 0;
                        height: 40px;

                        > * {
                            display: inline-block;
                            vertical-align: top;
                        }

                        font-size: 9pt;

                        > .name {
                            width: 140px;
                            text-align: right;
                            padding: 2px 0;

                            > * {
                                display: block;
                            }

                            > .channel {
                                color: $color_black;
                            }

                            > .language {
                                color: $color_gray;
                            }
                        }

                        > .info {
                            > * {
                                display: block;
                            }

                            > .top {
                                > * {
                                    display: inline-block;
                                    vertical-align: middle;
                                }

                                > .progress {
                                    @include prefix(border-radius, 2px);

                                    overflow: hidden;
                                    background: $color_white;
                                    width: 300px;
                                    height: 4px;
                                    font-size: 0;
                                    margin: 0 6px;

                                    > * {
                                        display: inline-block;
                                        height: 100%;

                                        &.ok {
                                            background: $color_success;
                                        }

                                        &.err {
                                            background: $color_error;
                                        }
                                    }
                                }

                                > .pending {
                                    color: $color_gray;
                                }
                            }

                            > .bottom {
                                padding: 0 10px;

                                > * {
                                    margin-right: 4px;

                                    &.ok {
                                        color: $color_success;
                                    }

                                    &.err {
                                        color: $color_error;
                                    }
                                }
                            }
                        }

                    }
                }
            }
        }
    }
}
