body.instructions, body.instructions > main {
  img {
    max-height: 100%;
    max-width: 100%;
  }
  blockquote {
    margin: 0;
  }
  blockquote p {
    background: white;
    border-left: 5px solid cornflowerblue;
    padding: 10px 0 10px 10px;
  }
  code {
    color: darkgoldenrod;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  th, td {
    border: 1px solid black;
    padding: 3px;
  }
  .back-button {
    padding-top: 15px;
  }
  .languages ul {
    padding: 30px 0 30px 0;
    background: $color_white;
    @include prefix(border-radius, 10px);
    @include prefix(box-shadow, 0 10px 24px 0 #dbe0e3);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 5%;
  }
  .languages li {
    list-style: none;
    flex: 0 0 28.3333%;
  }
  .languages li span.flag {
    font-size: 50px;
    vertical-align: middle;
  }
  .languages li a {
    display: block;
    text-decoration: none;
    color: $color_black;
  }
}
