.predefined-title-component {
    position: relative;
    min-height: 41.5px;
    .wrapper {

        > input {
            display: block;
            padding: 11px 10px;
            font-size: 11pt;
            width: 100%;
        }

        .suggestions {
            display: none;
        }

        &.open {
            background: $color_white;
            border: 1px solid rgba(0, 0, 0, 0.4);
            @include prefix(border-radius, 4px);
            position:  absolute;
            right: 0;
            left: 0;
            z-index: 10;
            

            > input {
                background: none;
                border: none;
                @include prefix(box-shadow, none);
                @include prefix(border-radius, 0);
                border-bottom: 1px solid $color_smoke;
            }
            > .suggestions {
                display: block;
                padding: 10px 0;
                margin: 0;
                list-style-type: none;
                font-size: 11pt;
                max-height: 330px;
                overflow-y: scroll;
                

                > li {
                    padding: 5px 10px;
                    &.selected {
                        background: $color_smoke;
                    }
                }
            }
        }
    }
}