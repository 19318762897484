
.wizard-action-list {
    &.container {
        margin: 0;
        width: auto;
        max-width: unset;
    }
    h1 {
        text-align: center;
    }
    .list-actions {
        margin-bottom: 100px;

        form > button {
            margin: 0 auto;
        }
    }
    div.list-wrapper {
        display: flex;
        flex-direction: column;
    }
    ul.items {
        padding: 0;
        margin: 0 auto;
        margin-bottom: 60px;
        margin-top: 20px;
        list-style: none;
    }

    .row {
        border-bottom: solid 1px rgba(0, 0, 0, 0.1);

        &.row-heading {
            padding: 10px 30px 10px 0;

            > div {
                > div {
                    > .column {
                        font-weight: 600;
                        text-transform: uppercase;
                        font-size: 9pt;
                    }
                }
            }
        }

        &.row-item {
            &:hover {
                background: rgba(255, 255, 255, 0.6);
            }

            &:empty {
                display: none;
            }
           
        }

        > div {
            > div {
                display: grid;
                grid-template-columns: 390px repeat(3, 250px) 1fr;
                grid-template-rows: 1fr;
                grid-column-gap: 10px;
                align-items: center;
                padding: 0 10px;

                > .column {

                    > .empty {
                        font-size: 9pt;
                        color: $color_palette_red;
                    }

                    > .set {
                        font-size: 9pt;
                        color: $color_black;
                    }

                    > a {
                        display: block;
                        font-size: 9pt;
                        color: $color_gray;

                        &:hover {
                            color: $color_black;
                        }
                    }

                    &.column-query {
                        > .query {
                            background: $color_white;
                            font-family: monospace;
                            font-size: 9pt;
                            @include prefix(border-radius, 4px);
                            margin-right: 20px;
                        }
                    }

                    &.column-event, &.column-action {

                        > form {
                            padding: 0;

                            > select {
                                padding: 9px;
                                font-size: 9pt;
                            }
                        }
                    }
                }
            }
        }
    }
}
