
body.extract, body.extract > main {
    padding-top: 160px;
    padding-left: 300px;
}

.extract {
    ul.products {
        list-style: none;
        margin: 0;
        padding: 0;

        > li {
            font-size: 0;

            &.head {
                background: $color_secondary;
                color: $color_white;

                > * {
                    text-align: left;
                    text-transform: uppercase;
                }
            }

            &.item {
                border-bottom: solid 1px rgba(0, 0, 0, 0.06);
            }

            > * {
                display: inline-block;
                vertical-align: top;
                font-size: 10pt;
                padding: 10px;

                &:first-of-type { padding-left: 20px; }
                &:last-of-type { padding-right: 20px; }
            }

            > .external_id { width: 15%; }
            > .preview_text { width: 20%; }
            > .imported_metadata { width: 45%; }
            > .product_labels { width: 20%; }

            > .product_labels {
                > a {
                    color: $color_white;
                    background: $color_primary;
                    @include prefix(border-radius, 2px);
                    font-size: 9pt;
                    padding: 4px 6px;
                    margin: 2px;
                    display: inline-block;
                }
            }

            > .imported_metadata {
                > table {
                    td {
                        position: relative;
                        vertical-align: top;

                        > .equal {
                            position: absolute;
                            left: -12px;
                            top: 6px;
                        }

                        > a {
                            padding: 5px;
                            background: $color_white;
                            @include prefix(border-radius, 2px);
                            font-family: monospace;
                            font-size: 13px;
                            line-height: 15px;
                            overflow: hidden;
                            word-wrap: break-word;
                            display: block;
                            text-decoration: none;

                            &.key {
                                margin-right: 15px;
                                color: $color_primary;
                            }

                            &.value {
                                color: $color_secondary;
                            }
                        }
                    }
                }
            }
        }
    }

    > .filters {
        background: $color_white;
        position: fixed;
        top: 60px;
        left: 0;
        right: 0;
        height: 100px;
        z-index: 1;

        > form {
            @extend .container;

            padding: 20px;

            > .field {
                display: inline-block;
                width: 170px;
                margin: 0;
                margin-right: 10px;
                vertical-align: bottom;
                text-align: left;

                > input,
                > select {
                    width: 100%;
                    display: block;
                    padding: 4px 8px;
                }

                > label {
                    display: block;
                    font-size: 9pt;
                    margin-bottom: 6px;
                    text-transform: uppercase;
                    color: $color_secondary;
                }

                > input,
                > select,
                > button {
                    height: 36px;
                }
            }

            > .config {
                position: fixed;
                left: 0;
                top: 160px;
                bottom: 0;
                overflow-y: scroll;
                width: 300px;
                background: rgba(255, 255, 255, 0.5);

                > .actions {
                    padding: 15px 20px;
                    background: rgba(0, 0, 0, 0.04);

                    > * {
                        margin-right: 5px;
                    }
                }

                > .field {
                    padding-bottom: 20px;
                    margin: 0;

                    > label {
                        font-size: 10pt;
                        display: block;
                        padding: 10px 20px;
                        text-transform: uppercase;
                        color: $color_secondary;
                        text-align: center;
                        background: rgba(0, 0, 0, 0.04);
                    }

                    > ul {
                        margin: 10px 0;
                        padding: 0;
                        list-style: none;
                        font-size: 9pt;

                        > li {
                            &:first-of-type {
                                display: none;
                            }

                            > label {
                                display: block;
                                padding: 2px 15px;

                                > input {
                                    vertical-align: middle;
                                    cursor: pointer;
                                }

                                cursor: pointer;
                                color: $color_secondary;

                                &:hover {
                                    color: $color_primary;
                                }

                                &:active {
                                    color: $color_black;
                                }
                            }
                        }
                    }
                }

            }
        }
    }

    > .list {
        padding-top: 36px;

        .button.next {
            display: inline-block;
            margin: 10px;
            text-decoration: none;
        }

        > ul.products {
            position: fixed;
            top: 160px;
            left: 300px;
            right: 0;
            z-index: 1;
        }

        .task {
            border-bottom: solid 1px rgba(0, 0, 0, 0.06);

            > .partial {
                > .system {
                    padding: 10px 20px;
                    border-bottom: solid 1px rgba(0, 0, 0, 0.06);
                    font-size: 9pt;
                    color: $color_gray;

                    &, * {
                        font-family: monospace;
                    }
                }

                > .status {
                    padding: 20px;
                }

                > form {
                    padding: 30px 10px;
                    text-align: center;

                    > * {
                        display: inline-block;
                    }

                    > .field {
                        > label {
                            font-size: 9pt;
                            text-align: left;
                            padding: 2px;

                            > i {
                                font-size: 15px;
                            }
                        }

                        > input {
                            padding: 9px;
                        }
                    }

                    > span {
                        font-size: 10pt;
                        margin: 10px;
                        color: $color_gray;
                        display: block;

                        > span {
                            color: $color_black;
                        }
                    }

                    > button {
                        margin: 0;
                    }
                }
            }
        }
    }
}
