@import 'base/colors';
@import 'base/mixins';
@import 'base/fonts';
@import 'base/anim';

@import 'components';
@import 'assets/scss/react-sortable-tree-theme'; 

* {
    font-family: $font_primary;
}

html {
    &.noscroll {
        overflow: hidden;
    }
}

html, body {
    margin: 0;
}

body {
    background: $color_palette_smoke;
    font-weight: 500;
    > iframe {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 10;
        width: 100%;
        height: 100%;
        border: none;
    }
}

script {
    display: none !important;
}

.css-1492t68, // from VocabularyLookup component
::placeholder {
    color: rgba(191, 191, 191, 0.87) !important;
    opacity: 1;
    font-weight: 500;
}

h1, h2, h3 {
    font-family: $font_heading;
    font-weight: normal;
}

.subheading {
    color: $color_gray;
    font-style: italic;
    margin-top: -1em;
}

p.error {
    color: $color_error;
}

p.info, i.info {
    font-size: small;
    color: $color_darksmoke;
}

* {
    &, &::after, &::before {
        @include prefix(box-sizing, border-box);
    }
}

$wide_reduced: 1000px;
$wide_feed: 900px;
$wide_mobile: 780px;
$wide_mini: 420px;

.container {
    width: 92%;
    max-width: 1040px;
    margin: 0 auto;
}

form {
    ul.errorlist {
        list-style: none;
        margin: 0;
        padding: 0 2px;
        color: $color_error;
        float: right;
        font-size: 9pt;

        &.nonfield {
            float: none;
            margin: 10px 0;
        }
    }

    > button[type="submit"]:not(.txu-btn) {
        margin: 20px 0;
        display: block;
    }

    .field {
        margin: 14px 0;

        > *:not(:hidden) {
            display: block;
        }

        > label {
            font-size: 9pt;
            text-transform: uppercase;
            margin: 0 0 8px;
            padding: 0 10px;

            > * {
                display: inline-block;
                vertical-align: middle;
            }

            > select {
                text-transform: none;
            }
        }

        > select,
        > input {
            width: 100%;
            padding: 10px 6px;
            font-size: 11pt;

            &:disabled {
                background: rgba(0, 0, 0, 0.1);
            }
        }

        > select {
            @include prefix(appearance, none);
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgAgMAAACf9p+rAAAACVBMVEUAAAAyMjIzMzMmhMtFAAAAAnRSTlMAgJsrThgAAABcSURBVHgBYhgxYBSMglGQtQoKVqJJSMEklqBJsAHam0MjAEAggGHHZozIyKgYOgDmaytinGcsYxdBBAkBCQEJAQkBQQRBBEEUQQRBBEEEQRRBBEEEQQRBTNP0vwu7s0JpRPs+OAAAAABJRU5ErkJggg==);
            background-repeat:  no-repeat;
            background-position: 99% 50%;
            background-size: 25px 25px;
        }

        > input {
            padding: 11px 10px;
        }

        > .readonly {
            padding: 4px 10px 0;
            color: $color_gray;

            > * {
                display: inline-block;
                vertical-align: middle;
            }

            > input[type="text"] {
                border: none;
                outline: none;
                color: $color_black;
                padding: 4px 0;
                width: 120px;

                @include prefix(border-radius, 0);
            }
        }

        // Vocabulary lookup component
        [id=react-async-select] {
            > div {
                padding: 2px !important;
            }
        }

        &.single-line {
            display: flex;
            flex-wrap: wrap;
            > label {
                width: 100%;
            }
            > select, > input {
                flex: 1 0 200px;
            }
            > button {
                margin-left: 5px;
            }
        }
    }
}

form.lexicon-form {
        > .template > .errorlist {
        float: unset,
    }
}

textarea,
select,
input:not([class^='tw-']) {
    outline: none !important;
    font-size: 1em;
    border: solid 1px rgba(0, 0, 0, 0.2);
    background: $color_white;

    @include prefix(box-shadow, none);
    @include prefix(border-radius, 4px);

    &:focus {
        border-color: rgba(0, 0, 0, 0.4);
    }
    &.hidden{
        display: none !important;
    }
}

textarea:not(.skip-default-styling) {
    height: 100px;
    min-height: 100px;
    max-width: 100%;
    min-width: 100%;
}

.pbutton {
    font-size: 14px;
    display: inline-block;
    width: 175px;
    border: solid 1px transparent;
    font-weight: 500;
    @include prefix(border-radius, 4px);
    text-decoration: none;
    cursor: pointer;
    text-align: center;
    @include prefix(transition, all 100ms ease);
    padding: 10px;

    &.pbutton-primary {
        color: $color_white;
        background: $color_palette_red;
        border-color: $color_palette_red;

        &:active {
            opacity: 0.6;
        }
    }

    &.pbutton-inverse {
        color: $color_palette_red;
        background: transparent;
        border-color: $color_palette_red;

        &:active {
            background: rgba(0, 0, 0, 0.2);
        }
    }

    &.pbutton-inverse-alt {
        color: $color_gray;
        background: transparent;
        border-color: $color_gray;

        &:active {
            background: rgba(0, 0, 0, 0.2);
        }

        &:hover {
            color: $color_black;
        }
    }

    &.pbutton-small {
        padding: 2px;
        margin: 0;
    }
    &.pbutton-smaller {
        padding: 5px 10px;
        margin: 0;
        width: unset;
    }

    &.pbutton-wide {
        width: 240px;
    }

    &.pbutton-margin {
        margin-left: 5px;
    }

    &.loading {
        position: relative;
        padding-left: 2px;
        padding-right: 18px;

        &::after {
            @include prefix(animation, rotation .6s infinite linear);
            @include prefix(border-radius, 100%);

            content: "";
            display: block;
            height: 16px;
            width: 16px;

            position: absolute;
            top: 50%;
            margin-top: -8px;
            right: 0;
            margin-right: 10px;
        }

        &.pbutton-primary::after {
            border-left: 2px solid rgba(255, 255, 255, .4);
            border-right: 2px solid rgba(255, 255, 255, .4);
            border-bottom: 2px solid rgba(255, 255, 255, .4);
            border-top: 2px solid rgba(255, 255, 255, 1);
        }

        &.pbutton-inverse::after {
            border-left: 2px solid rgba(215, 76, 80, .4);
            border-right: 2px solid rgba(215, 76, 80, .4);
            border-bottom: 2px solid rgba(215, 76, 80, .4);
            border-top: 2px solid rgba(215, 76, 80, 1);
        }

        &.pbutton-inverse-alt::after {
            border-left: 2px solid rgba(100, 100, 100, .4);
            border-right: 2px solid rgba(100, 100, 100, .4);
            border-bottom: 2px solid rgba(100, 100, 100, .4);
            border-top: 2px solid rgba(0, 0, 0, 1);
        }
    }

    > i {
        font-size: 15px;
    }

    > * {
        vertical-align: middle;
        display: inline-block;
    }

    &[disabled] {
        color: $color_white;
        background: $color_darksmoke;
        cursor: default;
        border-color: $color_darksmoke;
    }

}

.buttonOfMapping {
    width:80px;
}

.pd-0 {
    padding: 0;
}

.button {
    border: none;
    padding: 10px 24px;
    cursor: pointer;
    font-size: 10pt;

    @include prefix(border-radius, 2px);

    > * {
        display: inline-block;
    }

    &.block {
        text-align: center;
        display: block;
    }

    &:hover {
        opacity: 0.8;
    }

    &:active {
        opacity: 0.9;
    }

    &:disabled {
        cursor: default;
        opacity: 0.3;
    }

    &.inverse {
        color: rgba(0, 0, 0, 0.8);
        border: solid 1px rgba(0, 0, 0, 0.6);
        background: transparent;

        padding: 9px 24px;

        &:hover {
            background: rgba(0, 0, 0, 0.1);
        }

        &:active {
            background: rgba(0, 0, 0, 0.2);
        }
    }

    &.danger {
        color: $color_white;
        background: rgba(173, 8, 8, 1);
    }

    &.primary {
        color: $color_white;
        background: $color_primary;
    }

    &.secondary {
        color: $color_white;
        background: $color_secondary;
    }
}

    .link-style {
        text-decoration:  none;
        color: $color_palette_blue;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 600;
        border: none;
        background: transparent;
        margin: 0;
        padding: 10px 0;
        cursor: pointer;
        &:hover {
            color: $color_palette_red;
        }
    }

.js-img-tooltip {
    position: absolute;
    height: 400px;
    z-index: 100;
}

.sortable-ghost {
    background: $color_smoke;
    > * {
        opacity:  0;
    }
}

[data-sortable-handle] {
    cursor: grab;
}

.sortable-chosen {
    cursor: grabbing;

    [data-sortable-handle] {
        cursor: grabbing;
    }
}

.noborder {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
}

.partial {
    @include prefix(transition, opacity 200ms ease);
}

@keyframes spinner {
  to {transform: rotate(360deg);}
}
.partial.show-spinner:not(.done) {
    height: 20px;
    position: relative;
}
.partial.show-spinner:not(.done):before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid transparent;
    border-top-color: $color_primary;
    border-bottom-color: $color_primary;
    animation: spinner .8s ease infinite;
}

.vocabulary-change-enabled {
    span[data-vocab-id], span[data-template-id], span[data-text-block-id] {
        cursor: pointer !important;

        &:hover {
            border-bottom: solid 2px;
        }

        &:active {
            background: rgba(0, 0, 0, 0.1);
        }
    }
}

.dsp-flx {
    display: flex;
}

.html-tag-tree {
    margin: -10px -30px;
    margin-bottom: 0;
    margin-right: 0;

    .level {
        margin-left: 30px;

        > .tag {
            font-size: 10pt;
            position: relative;
            z-index: 1;
            margin-top: 10px;

            > a {
                text-decoration:  none;
                color: $color_black;

                &:hover > span {
                    text-decoration: underline;
                }
            }

            > a, & {
                > span {
                    display: inline-block;
                    @include prefix(border-radius, 3px);
                    background: $color_white;
                    padding: 6px 10px;
                    @include prefix(box-shadow, 0 3px 5px 0 #dbe0e3);
                }
            }
        }

        > .children {
            overflow: hidden;
            padding-bottom: 4px;

            > div {
                position: relative;

                &::before {
                    content: " ";
                    display: block;
                    position: absolute;
                    border: solid 0 rgb(180, 180, 180);
                    border-left-width: 1px;
                    border-bottom-width: 1px;
                    width: 20px;
                    height: 200px;
                    left: -15px;
                    top: -186px;
                }
            }
        }

        &:last-of-type {
            &::before {
                border-bottom-left-radius: 4px;
            }
        }
    }
}

span.pair, a.pair {
    display: inline-block;
    text-decoration: none;
    background: $color_white;
    @include prefix(border-radius, 4px);
    @include prefix(box-shadow, 0 2px 9px 0 #dbe0e3);
    overflow: hidden;

    > * {
        display: inline-block;
        font-size: 12px !important;
        padding: 5px 10px !important;
    }

    > .exclude {
        text-decoration: line-through;
    }

    > .key {
        background: $color_palette_red;
        color: $color_white;
    }

    > .value {
        color: #1a1919;
    }

    > .no-padding {
        padding: 0 !important;
    }

    &.mapped {
        > .key {
            background: $color_palette_blue;
        }
    }

    &:active {
        opacity: 0.7;
    }

    border: solid 1px transparent;

    &.main-kind {
        border: solid 1px black;
        background-color: lightgray;
    }
}

#atlwdg-trigger {
    z-index: 9999;
}

.copy-clipboard-fallback{
    word-wrap: break-word;
}

.space-between {
    display: flex;
    justify-content: space-between;
}

.text-center {
    padding: 12px;
    text-align: center;
}

.greyed-out {
    opacity: 0.5;
}



.ui.label {
    &.small-border {
        box-shadow: 0px 0px 0px 1px rgb(34 36 38 / 15%) inset;
        border: none;
    }
    &.no-border {
        border: none;
        box-shadow: none !important;
    }
    &.no-line-height {
        line-height: unset;
    }
    &.fitted-template-label {
        padding: 0.25rem;
    }
    &.font-size-small {
        font-size: 0.85rem !important
    }
    &.color-primary {
        background-color: $color_primary !important;
        color: white !important;
      }
    &.clickable {
        cursor: pointer;
    }
}

.ui.label,
.label {
    font-weight: 500 !important;
}

.descriptive-helper-text {
    margin-top: 0;
    font-size: small
}

// text that is missing in translation will be wrapped in a span
// this makes sure that we can still open the vocabulary details view
span.text-missing-translation {
    pointer-events: none;
}


.ui.menu.tiny.custom-button-dropdown, .ui.button.custom-dropdown-like-button {
    box-shadow: 0px 0px 0px 1px rgb(34 36 38 / 15%) inset;
    border: none;
    color: rgba(0, 0, 0, 0.87) !important;
    > ui.dropdown.item {
        font-size: 0.85rem !important;
        padding: 0.78em 1.5em 0.78em !important;
    }
    b {
        font-weight: 600 !important
    }
}

@import 'base/messages';

@import 'assets/scss/sections/navigation';
@import 'assets/scss/sections/account';
@import 'assets/scss/sections/auth';
@import 'assets/scss/sections/generate';
@import 'assets/scss/sections/upload';
@import 'assets/scss/sections/map';
@import 'assets/scss/sections/extract';
@import 'assets/scss/sections/customers';
@import 'assets/scss/sections/settings';
@import 'assets/scss/sections/download';
@import 'assets/scss/sections/wizard';
@import 'assets/scss/sections/wizard_mappings';
@import 'assets/scss/sections/wizard_conditions';
@import 'assets/scss/sections/adword_ad';
@import 'assets/scss/sections/search_extract';
@import 'assets/scss/sections/loading_indicator';
@import 'assets/scss/sections/inriver';
@import 'assets/scss/sections/instructions';
@import 'assets/scss/sections/product_detail';
@import 'assets/scss/sections/product_list';
@import 'assets/scss/sections/product_bulk_action';
@import 'assets/scss/sections/template_list';
@import 'assets/scss/sections/processors';
@import 'assets/scss/sections/manage';
@import 'assets/scss/sections/collapsible';
@import 'assets/scss/sections/help_tooltip';
@import 'assets/scss/sections/subpage';
@import 'assets/scss/sections/vocabulary_list';
@import 'assets/scss/sections/vocabulary_detail';
@import 'assets/scss/sections/lexicon_editor';
@import 'assets/scss/sections/template_builder';
@import 'assets/scss/sections/embed';
@import 'assets/scss/sections/translation';
@import 'assets/scss/sections/variants_field';
@import 'assets/scss/sections/template_variants_field';
@import 'assets/scss/sections/edit-text-modal';
@import 'assets/scss/sections/copy-sku-ean-modal';
@import 'assets/scss/sections/load-template-category-modal';
@import 'assets/scss/sections/publish-overwrite-modal';

@import 'assets/scss/components/fluent';
@import 'assets/scss/components/modal';
@import 'assets/scss/components/predefined_title';
@import 'assets/scss/components/text';
@import 'assets/scss/components/tag_tree';


