
body.search-extract, body.search-extract > main {
    &.search-extract-upload {
        .container {
            text-align: center;

            > .help {
                width: 500px;
                margin: 0 auto 60px;
                font-size: 0;

                > span {
                    display: inline-block;
                    width: 50%;
                    font-size: 14px;
                    vertical-align: top;
                    text-align: left;

                    &::before {
                        content: " ";
                        width: 10px;
                        height: 10px;
                        border: solid 2px $color_palette_red;
                        display: inline-block;
                        @include prefix(border-radius, 100%);
                        margin: 4px 10px 20px;
                        float: left;
                    }

                    > span {
                        color: $color_palette_blue;
                    }
                }
            }

            > form {
                > .field {
                    width: 400px;
                    margin: 30px auto;
                }

                > .drop {
                    width: 500px;
                    height: 322px;
                    @include prefix(border-radius, 10px);
                    border: dashed 2px $color_palette_blue;
                    position: relative;
                    margin: 80px auto 30px;
                    display: block;

                    background: transparent;
                    .title > .empty { display: block; }
                    .title > .selected { display: none; }

                    &.selected {
                        background: rgba(0, 0, 0, 0.1);
                        .title > .empty { display: none; }
                        .title > .selected { display: block; }
                    }

                    input[type="file"] {
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        width: 100%;
                        opacity: 0;
                        cursor: pointer;
                    }

                    > .image {
                        width: 110px;
                        height: 110px;
                        background-repeat: no-repeat;
                        background-position: 50% 50%;
                        background-size: contain;
                        background-image: url('../svg/upload-file-1x.svg');
                        margin: 50px auto 0;
                    }

                    > .title {
                        font-size: 18px;
                        font-weight: 600;
                        margin: 20px 0 30px;
                    }

                    > .text {
                        width: 220px;
                        margin: 0 auto;

                        &, * {
                            font-size: 14px;
                            font-weight: 600;
                        }

                        > span {
                            color: $color_palette_red;
                        }
                    }
                }
            }
        }
    }

    &.search-extract-upload-task {
        .sk-cube-grid {
            margin: 20px auto;
        }

        .dialog {
            height: 160px;
        }
    }

    &.search-extract-results {
        > .container {
            width: 600px;
            padding: 30px 0 60px;
        }

        ul.results {
            margin: 0;
            padding: 0;
            list-style: none;

            > li {
                padding: 20px;
                background: $color_white;
                margin: 20px 0;
                @include prefix(box-shadow,  0 10px 24px 0 #dbe0e3);
                @include prefix(border-radius, 10px);

                > .site_link {
                    margin-bottom: 10px;

                    > a {
                        color: $color_palette_blue;
                        text-decoration: none;
                    }
                }

                > .description {
                    font-size: 10pt;
                }

                > .extract_button {
                    float: right;
                    margin: 0 0 20px 20px;

                    button {
                        margin: 0;
                    }
                }
            }
        }
    }
}
