.wizard-mappings.wizard {
    > .alert-box-container {
        position: fixed;
        left: 20px;
        z-index: 100;
    }
}
.wizard-mapping-list {
    width: unset;
    max-width: unset;
    > form.mapping-filters {
        max-width: 1500px;
        display: flex;
        gap: 10px;
        margin: auto;
        margin-bottom: 50px;
        background: $color_white;
        padding: 10px 14px;
        flex-wrap: wrap;
        @include prefix(border-radius, 4px);

        > .field {
            flex: 1;
            margin: auto;
            > label {
                display: flex;
                flex-direction: column;
                padding: 0;
                > input, select {
                    max-width: 300px;
                    font-size: 10pt;
                    padding: 11px 14px;
                    font-weight: 500;
                }
            }
        }
    }
    .filter-btn {
        flex: 0;
        margin: auto;
        > button {
            margin-top: 7px;
            width: 80px;
        }
    }
    .mappings-download-btn {
        text-align: right;
        margin-bottom: -40px;
    }

    ul.items {
        padding: 0;
        margin: 0;
        margin-bottom: 60px;
        margin-top: 20px;
        list-style: none;
    }

    .row {
        padding: 20px 30px;
        border-bottom: solid 1px rgba(0, 0, 0, 0.1);

        &.row-heading {
            padding: 10px 30px;

            > div {
                > div {
                    > .column {
                        font-weight: 600;
                        text-transform: uppercase;
                        font-size: 9pt;
                    }
                }
            }
        }

        &.row-item {
            &:hover {
                background: rgba(255, 255, 255, 0.6);
            }
        }

        > div:not(.rowContents) { // :not() is to not apply these styles to tag trees inside "Edit" modal
            &.hidden {
                display: none;
            }
            > div {
                display: grid;
                grid-template-columns: 290px repeat(3, 250px) 1fr;
                grid-template-rows: 1fr;
                grid-column-gap: 10px;
                align-items: center;
                padding: 0 10px;

                > .column {

                    &.column-pair {
                        
                        .product-count {
                            display: block;
                            font-size: 8pt;
                            font-weight: 600;
                            padding: 2px 10px;
                        }

                        span.pair,
                        a.pair {
                            display: inline-block;
                            text-decoration: none;
                            background: $color_white;
                            @include prefix(border-radius, 4px);
                            @include prefix(box-shadow, 0 2px 9px 0 #dbe0e3);
                            overflow: hidden;

                            * {
                                font-size: 12px;
                                padding: 5px 10px;
                                display: inline-block;
                            }

                            > a {
                                text-decoration: none;

                                &:hover {
                                    text-decoration: underline;
                                }
                            }

                            > .key {
                                background: $color_palette_red;
                                color: $color_white;
                            }

                            > .value {
                                color: #1a1919;
                            }

                            &.mapped {
                                > .key {
                                    background: $color_palette_blue;
                                }
                            }

                            &:active {
                                opacity: 0.7;
                            }

                            border: solid 1px transparent;

                            &.main-kind {
                                border: solid 1px black;
                                background-color: lightgray;
                            }
                        }
                    }

                    &.column-preview {
                        > a {
                            color: black;
                            text-decoration: none;
                            &:hover {
                                text-decoration: underline;
                            }
                        }

                    }

                    &.column-actions {
                        display: flex;
                        gap: 10px;
                        justify-content: flex-end;
                    }
                }
            }
        }
    }

    > .message {
        margin-top: -20px;
        margin-bottom: 10px;
        font-size: 9pt;
        margin-left: 20px;
    }
}

.wizard-mapping-editor {
    #productTagSelect, #ProductSubpartTagSelect {
        input.search {
            padding-left: 16px;
            width: 200px;
            height: 41px;
          }
    }
    .edit {
        margin-top: -20px;
        margin-bottom: 20px;

        > ul {
            list-style: none;
            padding: 0 20px;
            margin: 0;
            border-bottom: solid 1px rgba(0, 0, 0, 0.1);

            > li {
                display: inline-block;
                margin: 0 4px;

                > a {
                    display: block;
                    padding: 10px;
                    border-bottom: solid 3px transparent;
                    font-size: 9pt;
                    color: $color_gray;
                    text-decoration: none;
                    text-transform: uppercase;

                    &.active {
                        border-bottom-color: $color_palette_red;
                        color: $color_palette_red;
                    }
                }

                &.separator {
                    height: 10px;
                    border-right: solid $color_gray 1px;
                }
            }
        }

        &.edit-templates {
            > ul {
                border-bottom: none;
            }
        }

        > .section {
            background: $color_white;
            @include prefix(border-radius, 4px);
            min-height: 200px;
            padding: 20px;
        }

        .recommend-box {
            margin: 5px;
            >form {
                display: inline;
                padding: 0;
                margin: 0;
            }
        }

        .recommend {
            display: inline;
            padding: 4px 5px;
            text-decoration: none;
            color: black;
            margin: 2px;
            border: 1px solid black;
            border-radius: 6px;
            white-space: nowrap;
            transition: 0.3s;
            font-size: 10px;
            box-shadow: 0 3px 5px 0 #dbe0e3;
        }

        .recommend:hover {
            background: #e7e7e7;

            box-shadow: none;
        }
        .seo-recommendations {
            margin-top: 10px;
        }

        .seo-recommend {
            background: #cdeaf9;
        }

        .seo-recommend:hover {
            background: #a4ceea;
        }

        [seo-competition="Low"] {
            background: #01FF70;
        }
        [seo-competition="Medium"] {
            background: #FFDC00;
        }
        [seo-competition="High"] {
            background: #FF851B ;
        }

        &.edit-templates {
            > .section {
                > .animate {
                    display: flex;
                    margin: -20px;
                    @include prefix(border-radius, 4px);
                    background: rgba(0, 0, 0, 0.1);
                    min-height: 400px;

                    > .left {
                        flex: 0 0 150px;
                        padding-top: 10px;

                        h3 {
                            padding-left: 10px;
                            text-transform: uppercase;
                            font-size: 12px;
                            color: $color_gray;
                        }

                        > ul {
                            margin: 5px 0;
                            list-style: none;
                            padding: 0;

                            > li {
                                margin: 0;
                                padding: 0;
                                display: flex;
                                justify-content: space-between;
                                white-space: nowrap;

                                &.active {
                                    background: $color_white;
                                }

                                > * {
                                    padding: 10px 16px;
                                    display: inline-block;
                                }

                                > a {
                                    padding: 10px 16px;
                                    text-decoration: none;
                                    font-size: 10pt;
                                    color: $color_black;
                                    border-left: solid 3px transparent;

                                    &.add {
                                        padding: 10px 16px;
                                        padding-left: 40px;
                                        display: block;

                                        > span {
                                            margin-top: 10px;
                                            text-transform: uppercase;
                                            color: $color_gray;
                                        }
                                    }

                                    > span {
                                        margin-right: 14px;

                                        &.line-through{
                                            text-decoration: line-through;
                                        }

                                        &.template-deleted{
                                            color: $color_gray;
                                        }
                                    }

                                }

                                &.active > a {
                                    border-color: $color_palette_red;
                                    color: $color_palette_red;
                                    > span {
                                        color: $color_palette_red;
                                    }
                                }

                                > form {
                                    opacity: 0;

                                    > button {
                                        padding: 1px;
                                        margin: 0;
                                        cursor: pointer;
                                        background: transparent;
                                        border: 0;

                                        > i {
                                            font-size: 15px;
                                        }
                                    }
                                }

                                &:hover {
                                    > form {
                                        opacity: 1;
                                    }
                                }
                            }
                        }
                    }

                    > .right {
                        flex: 1;
                        padding: 10px 0;
                        padding-left: 30px;
                        margin: 10px;
                        margin-left: 0;
                        background: $color_white;
                        @include prefix(border-radius, 4px);

                        > h3 {
                            margin: 60px 0;
                            text-align: center;
                        }

                        > form {
                            > .actions {
                                padding: 20px;

                                > * {
                                    margin-right: 14px;
                                }
                            }

                            > .field {
                                margin: 0;
                                padding: 14px 20px;

                                svg {
                                    margin: -3px;
                                }

                                > * {
                                    display: block;
                                }

                                > .label {
                                    display: inline-block;
                                    margin: 6px 10px;
                                    margin-top: 0;

                                    > label {
                                        font-size: 9pt;
                                        color: $color_gray;
                                        text-transform: uppercase;
                                    }
                                }

                                > select,
                                > input,
                                > textarea {
                                    padding: 8px 10px;
                                    font-size: 12pt;
                                }

                                border-bottom: solid 1px $color_palette_smoke;

                                &:last-of-type {
                                    border-bottom: 0;
                                }
                            }
                        }
                    }
                }
            }
        }

        .animate {
            opacity: 0;
            position: relative;
            @include prefix(animation, fadein 300ms ease forwards);
        }
    }
}
