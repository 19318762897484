body.account, body.account > main {
    .metrics-form {
        #id_report_date_day {
            display: none;
        }
        #id_report_date_year {
            padding-right: 2em;
        }
    }
    
    .hide {
        display: none;
    }
}
