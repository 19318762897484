

.login {
    width: $wide_mini;
    margin: 50px auto;

    > .footer {
        padding: 40px;
        text-align: center;
        font-size: 12pt;
        > i {
            font-size: small;
        }
        > a {
            display: block;
            margin-top: 10px;
            color: $color_palette_blue;
            text-decoration: none;
            font-size: large;
            &:hover {
                color: $color_palette_red;
            }

            &:active {
                color: $color_black;
            }
        }
    }

    > .dialog {
        padding: 18px 30px;
        background: $color_white;
        @include prefix(box-shadow, 0 10px 24px 0 #dbe0e3);
        @include prefix(border-radius, 6px);

        dl {
            dt {
                font-weight: 600;
            }
            dd {
                margin-top: 0.5em;
                margin-bottom: 1em;
            }
        }

        hr {
            margin: 30px 0;
            border: 1px solid $color_palette_smoke;
        }

        form {
            .actions {
                margin: 30px 0 10px;

                > .auth {
                    float: right;

                    > a {
                        display: inline-block;
                        width: 40px;
                        height: 40px;

                        background-position: 50% 50%;
                        background-size: 100%;
                        background-repeat: no-repeat;

                        margin-left: 10px;

                        opacity: 0.7;

                        &:hover {
                            opacity: 0.9;
                        }
                    }
                }
            }
        }
    }
}

.signup {
    width: 800px;
    margin: 50px auto;

    > .footer {
        padding: 40px;
        text-align: center;
        font-size: 12pt;

        > a {
            color: $color_palette_blue;
            text-decoration: none;

            &:hover {
                color: $color_palette_red;
            }

            &:active {
                color: $color_black;
            }
        }
    }

    > .dialog {
        padding: 0;
        background: $color_white;
        @include prefix(box-shadow, 0 10px 24px 0 #dbe0e3);
        @include prefix(border-radius, 6px);
        overflow: hidden;

        > form {

            > .top {
                background: $color_palette_blue;

                ul.errorlist {
                    font-weight: bold;
                    color: $color_white;
                }

            }

            > .bottom {
                display: flex;
                flex-direction: row;
                padding: 0;

                > * {
                    padding: 18px 30px;
                    align-self: center;
                }

                > .left {
                    flex: 1;

                    .actions {
                        text-align: center;
                        margin: 30px 0 10px;
                    }
                }

                > .or {
                    flex: 0;
                    padding: 0;
                    align-self: stretch;
                    padding: 20px 14px;

                    position: relative;

                    > .line {
                        border-left: solid 1px rgba(0, 0, 0, 0.2);
                        height: 100%;
                    }

                    > .text {
                        position: absolute;
                        top: 50%;
                        width: 40px;
                        text-align: center;
                        margin-left: -20px;
                        margin-top: -18px;
                        background: $color_white;

                        font-size: 10pt;
                        text-transform: uppercase;
                        color: $color_gray;
                        padding: 10px 0;
                    }
                }

                > .right {
                    flex: 1;

                    > button {
                        width: 100%;
                        display: block;
                        margin: 20px 0;

                        > .google {
                            display: inline-block;
                            width: 26px;
                            height: 26px;
                            margin: -6px 4px;
                            margin-left: 0;
                            background-position: 50% 50%;
                            background-size: 100%;
                            background-repeat: no-repeat;
                            opacity: 0.7;
                        }

                        &:hover {
                            background: $color_palette_smoke;

                            > .google {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
    }
}

.no-customer, .not-verified {
    width: 900px;
    margin: auto;
    text-align: center;

    > .heading {
        margin: 40px 0 24px;
        text-align: center;
    }

    > .dialog {
        padding: 20px;
        background: $color_white;
        @include prefix(border-radius, 10px);
        @include prefix(box-shadow, 0 10px 24px 0 #dbe0e3);
        margin-bottom: 60px;

        opacity: 0;
        @include prefix(animation, slidedown 400ms ease forwards);
        animation-delay: 200ms;
    }
}
