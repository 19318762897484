.fluent-input-component {
    > input {
        width: 100%;
        padding: 10px 6px;
        font-size: 11pt;

    }
    .suggestions {
        display: none;
    }

    &.open {
        background: $color_white;
        border: 1px solid rgba(0, 0, 0, 0.4);
        @include prefix(border-radius, 4px);

        > input {
            background: none;
            border: none;
            @include prefix(box-shadow, none);
            @include prefix(border-radius, 0);
            border-bottom: 1px solid $color_smoke;
        }
        > .suggestions {
            display: block;
            background: none;
            padding: 10px 0;
            margin: 0;
            list-style-type: none;
            font-size: 11pt;

            > li {
                padding: 5px 6px;
                &.selected {
                    background: $color_smoke;
                }
            }
        }
    }
}