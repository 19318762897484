
body.map, body.map > main {

    > .filters {
        background: $color_white;
        z-index: 1;

        > form {
            @extend .container;

            padding: 20px;

            > .field {
                display: inline-block;
                width: 170px;
                margin: 0;
                margin-right: 10px;
                vertical-align: bottom;
                text-align: left;

                > input,
                > select {
                    width: 100%;
                    display: block;
                    padding: 4px 8px;
                }

                > label {
                    display: block;
                    font-size: 9pt;
                    margin-bottom: 6px;
                    text-transform: uppercase;
                    color: $color_secondary;
                }

                > input,
                > select,
                > button {
                    height: 36px;
                }
            }
        }

        > .links {
            @extend .container;

            padding: 0 20px;

            > a {
                margin-top: 10px;
                color: $color_primary;
            }
        }

    }

    > .head,
    .item {
        > div {
            display: inline-block;
            vertical-align: top;
        }

        > .product_type {
            width: 20%;
        }

        > .product_value {
            width: 20%;
        }

        > .resulting_tag {
            width: 50%;

            .TagTreeEditor {
                .rst__tree {
                    margin-bottom: -50px;
                    min-height: 100px;
                }

                .rst__placeholder::before {
                    width: 670px;
                    height: 40px;
                }

                .rstcustom__row {
                    margin-left: 10px;
                    padding-left: 0px !important;
                }

                .rstcustom__collapseButton, .rstcustom__expandButton {
                    padding-left: 0px !important;
                }

                .rstcustom__toolbarButton > div {
                    right: 90px !important;
                }
            }

        }

        > .actions {
            width: 10%;
        }

        > div {
            font-size: 10pt;
            padding: 10px;

            &:first-of-type { padding-left: 20px; }
            &:last-of-type { padding-right: 20px; }
        }
    }

    > .head {
        background: $color_secondary;
        color: $color_white;
        font-size: 0;

        > div {
            text-align: left;
            text-transform: uppercase;
        }

        z-index: 1;
    }

    > .items {

        .button.next {
            display: inline-block;
            margin: 10px;
            text-decoration: none;
        }

        .item {
            border-bottom: solid 1px rgba(0, 0, 0, 0.06);
            font-size: 0;

            > div {
                font-size: 10pt;
                position: relative;

                > .equal {
                    position: absolute;
                    left: -3px;
                    top: 14px;
                }

                > .value {
                    padding: 5px;
                    background: $color_white;
                    @include prefix(border-radius, 2px);
                    font-family: monospace;
                    font-size: 13px;
                    line-height: 15px;
                    overflow: hidden;
                    word-wrap: break-word;
                    display: block;
                    text-decoration: none;
                }
            }

            > .product_type {
                > .value {
                    font-weight: bold;
                    color: $color_primary;
                }
            }

            > .product_value {
                > .value {
                    font-weight: bold;
                    color: $color_secondary;
                }
            }

            > .actions {
                > .button {
                    display: inline-block;
                    margin: 5px 10px;
                    text-decoration: none;
                    width: 100px;
                    text-align: center;
                    padding: 10px 0;
                }
            }

            > .resulting_tag {
                > div {
                    white-space: pre;
                }

                > form {

                    > textarea {
                        font-size: 9pt;
                        font-family: monospace;
                    }

                    > .actions {
                        > * {
                            display: inline-block;
                            vertical-align: top;
                            text-decoration: none;
                            margin: 10px 0;
                        }
                    }
                }
            }
        }
    }
}
