body.translation, body.translation > main {
    .alert-box-container {
        position: fixed;
        top: 120px;
        width: 600px;
        z-index: 2;
        left: 50%;
        margin-left: -300px;

        > .alert-box {
            opacity: 0;
            @include prefix(animation, slidedown 200ms ease forwards);
        }
    }

    .navigation > .top {
        background: $color_palette_red;
    }

    > div > .container {
        width: 90%;
        max-width: none;
        margin: 0 auto;
    }

    > div > .heading {
        background: $color_white;
        position: sticky;
        z-index: 11;
        top: 50px;
        opacity: 0;
        @include prefix(animation, slidedown 200ms ease forwards);
        animation-delay: 200ms;

        > .container {
            > * {
                display: inline-block;
                vertical-align: bottom;
            }

            > .mode {
                > ul {
                    list-style: none;
                    margin: 0;
                    padding: 0;

                    > li {
                        display: inline-block;
                        margin: 0 4px;

                        > a {
                            display: block;
                            padding: 16px 10px;
                            border-bottom: solid 3px transparent;
                            font-size: 10pt;
                            color: $color_gray;
                            text-decoration: none;

                            &:hover {
                                color: $color_black;
                            }

                            &.active {
                                border-bottom-color: $color_palette_red;
                                color: $color_palette_red;
                            }
                        }

                        &.separator {
                            height: 10px;
                            border-right: solid $color_gray 1px;
                        }
                    }
                }
            }

            > a {
                text-decoration: none;
                color: $color_black;
                margin: 4px;
                padding: 10px;

                > * {
                    display: block;
                    text-align: center;
                }

                > i {
                }

                &:hover {
                    color: $color_palette_red;
                }

                &:active {
                    color: $color_palette_blue;
                }
            }

            > .title {
                border-left: solid 1px $color_palette_smoke;
                margin-left: 2px;
                padding-left: 16px;

                > * {
                }

                > span {
                    font-size: 14pt;
                    padding: 15px 6px;
                    display: inline-block;
                }

                > .language {
                    margin: 10px;
                    margin-left: 4px;

                    display: inline-block;
                    border: solid 1px $color_palette_smoke;
                    padding: 2px 10px;
                    @include prefix(border-radius, 4px);

                    > * {
                        display: inline-block;
                        vertical-align: middle;
                    }

                    > .flag {
                        font-size: 18px;
                        margin-right: 2px;
                    }

                    > .name {
                        font-size: 9pt;
                    }
                }

                > .word-type {
                    position: relative;
                    display: inline-block;

                    > .caption {
                        cursor: pointer;
                        padding: 14px 6px;

                        > * {
                            display: inline-block;
                            vertical-align: middle;
                        }

                        > i {
                            font-size: 24px;
                        }

                        > span {
                            font-size: 14pt;
                        }
                    }

                    > .dropdown {
                        position: absolute;
                        width: 200px;
                        padding: 6px 0;
                        max-height: 500px;
                        overflow-y: scroll;

                        > ul {
                            margin: 0;
                            padding: 0;
                            list-style: none;
                            overflow: hidden;

                            background: $color_white;
                            @include prefix(border-radius, 3px);
                            @include prefix(box-shadow, 0 4px 10px rgba(0, 0, 0, 0.1));

                            > li {
                                > a {
                                    display: block;
                                    padding: 10px;
                                    text-decoration: none;
                                    color: $color_gray;

                                    > * {
                                        display: inline-block;
                                        vertical-align: middle;
                                    }

                                    > i {
                                        font-size: 24px;
                                    }

                                    > span {
                                        font-size: 10pt;
                                    }

                                    &:hover {
                                        color: $color_palette_red;
                                        background: $color_palette_smoke;
                                    }

                                    &:active {
                                        color: $color_black;
                                    }

                                    &.selected {
                                        pointer-events: none;
                                        opacity: 0.5;
                                    }
                                }
                            }
                        }
                    }

                    > .dropdown {
                        display: none;
                        position: absolute;
                        top: 40px;
                        left: 0;
                    }

                    &:hover {
                        > .caption {
                            color: $color_palette_red;
                        }

                        > .dropdown {
                            display: block;
                            overflow-y: scroll;

                            opacity: 0;
                            @include prefix(animation, slidedown 200ms ease forwards);
                        }
                    }
                }
            }
        }
    }

    .paginator {
        margin-top: 20px;
        text-align: center;

        > a {
            display: inline-block;
            padding: 8px;
            text-decoration: none;
            color: $color_black;
            margin: 0 10px;

            > * {
                display: inline-block;
                vertical-align: middle;
            }

            > i {
            }

            > span {
                font-size: 9pt;
                text-transform: uppercase;
            }

            &:hover {
                color: $color_palette_red;
            }

            &:active {
                color: $color_black;
            }

            &.disabled {
                opacity: 0.5;
                pointer-events: none;
            }
        }
    }

    .container .filters {
        margin: 20px 10px 20px;
        text-align: right;
        font-size: 14px;

        select, input {
            padding: 10px;
            // width: 300px;
        }
        form {
            display: inline-block;
            margin-left: 5px;
        }
        label {
            margin-right: 10px;
            color: $color_gray;
            display: inline-flex;
            align-items: center;
            vertical-align: middle;
        }

        opacity: 0;
        @include prefix(animation, slidedown 200ms ease forwards);
        animation-delay: 400ms;
    }

    form.search {
        text-align: center;
        margin: 20px 0;

        > * {
            display: inline-block;
            vertical-align: middle;
            margin: 0 5px;
        }

        > input[type="text"] {
            padding: 10px;
        }

        opacity: 0;
        @include prefix(animation, slidedown 200ms ease forwards);
        animation-delay: 300ms;
    }
}

body.translation-requests, body.translation-requests > main { 
    .load-more {
        margin: 40px 0 100px;
        text-align: center;
    }

    .vocabulary-request-items {
        margin-bottom: 50px;
        margin-top: -10px;

        > ul {
            margin: 0;
            padding: 0;
            list-style: none;

            > li {
                .vocabulary-request-item {
                    background: $color_white;
                    @include prefix(border-radius, 4px);
                    margin: 40px 40px;
                    padding: 0;

                    @keyframes item-extend {
                        from { margin: 40px 40px; }
                        to { margin: 80px 0px; }
                    }

                    &.extended {
                        @include prefix(box-shadow, 0 8px 10px 0 #dbe0e3);
                        margin: 80px 0px;

                        &.anim {
                            @include prefix(animation, item-extend 200ms ease forwards);
                        }
                    }

                    &.hidden {
                        display: none;
                    }
                }
            }
        }
    }

    .partial > .vocabulary-request-item > form > .extend {
        > a {
            > .loading { display: none; }
            > .action { display: block; }
        }
    }

    .partial.loading > .vocabulary-request-item > form > .extend {
        > a {
            > .loading { display: block; }
            > .action { display: none; }

            > .loading {
                @include prefix(animation, rotation .6s infinite ease);

                font-size: 20px;
                padding: 2px;
            }
        }
    }

    .vocabulary-request-item {
        position: relative;

        > .mask {
            background: rgba(255, 255, 255, 0.9);
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }

        > .message {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 500px;
            height: 70px;
            margin-left: -250px;
            margin-top: -35px;
            text-align: center;

            > .title {
                font-size: 13pt;
                margin-bottom: 6px;
                color: $color_palette_red;

                > * {
                    display: inline-block;
                    vertical-align: middle;
                }
            }

            > .text {
                font-size: 10pt;
                color: $color_gray;
            }

            opacity: 0;
            @include prefix(animation, slidedown 200ms ease forwards);
        }

        @keyframes extend {
            from { height: 0; }
            to { height: 300px; }
        }

        > form {
            > .extend {
                position: absolute;
                left: -40px;

                > a {
                    display: block;
                    padding: 4px;
                    text-decoration: none;
                    outline: none;
                    @include prefix(border-radius, 100%);

                    > i {
                        display: block;
                        font-size: 24px;
                    }

                    color: $color_black;

                    &:hover {
                        color: $color_palette_red;
                        background: rgba(0, 0, 0, 0.04);
                    }

                    &:active {
                        background: rgba(0, 0, 0, 0.1);
                    }
                }
            }

            > .hidden {
                display: none;
            }

            > .extended {
                height: 300px;

                &.anim {
                    @include prefix(animation, extend 200ms ease forwards);
                }

                > .column-similar-words {
                    overflow: auto;
                    
                    .big-custom-tooltip {
                        color: white;
                        display: none;
                        position: absolute;
                        width: 500px;
                        height: 500px;
                        left: 390px;
                        top: 200px;
                        z-index: 20;
                        pointer-events: none;
                        > .content {
                            padding: 20px;
                            border-radius: 10px;
                            background: rgba(0, 0, 0, 0.8);
                            margin-block: auto;

                            *:first-child {
                                margin: 0;
                            }

                            ul {
                                column-count: 2;
                                list-style: none;
                                padding: 0;
                            }
                        }
                    }
                    .parent-relative-tooltip:hover + .big-custom-tooltip {
                            display: flex;
                    }
                }

                &.changed-products {
                    overflow-y: scroll;

                    > ul.products {
                        list-style: none;
                        margin: 0;
                        padding: 0;
                        width: 100%;

                        > li {
                            display: flex;
                            border-bottom: solid 1px $color_palette_smoke;

                            &.empty {
                            }

                            > * {
                                flex: 1;
                                padding: 20px;

                                &, * {
                                    font-size: 9pt;
                                    margin: 0;
                                    font-weight: 500;
                                }

                                > h1, > h1 * {
                                    font-weight: 600;
                                }
                            }

                            > .initial-text {
                                opacity: 0.6;

                                span.highlight {
                                    font-weight: 600;
                                    background: $color_palette_red;
                                    color: $color_white;
                                }
                            }

                            > .new-text {
                                span.highlight {
                                    font-weight: 600;
                                    background: $color_success;
                                    color: $color_white;
                                }
                            }

                            > .icon {
                                flex: 0;
                                padding: 30px 0;

                                > i {
                                    font-size: 24px;
                                }
                            }

                            &.empty {
                                > div {
                                    padding: 40px;
                                    text-align: center;
                                    font-size: 10pt;
                                    color: $color_gray;
                                }
                            }
                        }
                    }
                }
            }

            > .extended,
            > .top {
                display: flex;
                flex-direction: row;
                border-bottom: solid 1px $color_palette_smoke;

                > .column {
                    flex: 1;

                    border-left: solid 1px $color_palette_smoke;
                    &:first-of-type { border-left: none; }

                    > .title {
                        font-size: 9pt;
                        color: $color_gray;
                        padding: 16px;
                        padding-bottom: 8px;
                    }

                    > .value {
                        padding: 16px;
                        padding-top: 0;
                        font-size: 9pt;
                        word-break: break-all;

                        > ul {
                            padding: 0;
                            margin: 0;
                            list-style: none;
                        }

                        > select {
                            width: 100%;
                            font-size: 9pt;
                            font-weight: 500;
                            padding: 9px;
                        }

                        > input[type="text"] {
                            padding: 10px;
                            width: 100%;
                            font-weight: 500;
                        }

                        &.value-alt {
                            margin-top: 20px;

                            > span {
                                display: inline-block;
                                width: 46%;
                                vertical-align: top;

                                > span {
                                    display: block;

                                    &:first-of-type {
                                        font-size: 18pt;
                                        font-weight: 600;
                                    }

                                    &:last-of-type {
                                    }
                                }
                            }
                        }
                    }

                    &.column-products {
                        overflow: auto;

                        > .value {
                            > ul {
                                margin: 0 -16px;

                                > li {
                                    &.warning {
                                        padding: 0 16px 20px;
                                        color: $color_palette_red;
                                        font-size: 8pt;
                                    }

                                    > a {
                                        padding: 8px 16px;
                                        display: block;
                                        outline: none;
                                        text-decoration: none;

                                        > * {
                                            display: block;
                                            white-space: nowrap;
                                            text-overflow: ellipsis;
                                            overflow: hidden;
                                            max-width: 225px;
                                        }

                                        > .product {
                                            color: $color_black;
                                        }

                                        > .customer {
                                            color: $color_gray;
                                        }

                                        &:hover {
                                            background: rgba(0, 0, 0, 0.03);
                                        }

                                        &:active {
                                            background: rgba(0, 0, 0, 0.1);
                                        }
                                    }
                                }
                            }
                        }
                    }

                    &.column-similar-words {
                        > .title {
                            > .legend {
                                vertical-align: middle;
                                float: right;

                                > * {
                                    display: inline-block;
                                    vertical-align: middle;
                                }

                                > span:first-of-type {
                                    color: $color_warning;
                                    font-size: 8pt;
                                }

                                > span:last-of-type {
                                    background: $color_warning;
                                    width: 8px;
                                    height: 8px;
                                    margin-left: 4px;
                                    @include prefix(border-radius, 100%);
                                }
                            }
                        }

                        > .value {
                            > .vocabulary-lookup-wrapper {
                                .pending {
                                    color: $color_warning;
                                }

                                .selected-value {
                                    position: inherit;
                                }
                            }

                            > ul {
                                margin: 0 -16px;

                                > li {
                                    > label {
                                        display: block;
                                        padding: 8px;
                                        padding-left: 40px;
                                        outline: none;
                                        text-decoration: none;
                                        cursor: pointer;

                                        > * {
                                            display: block;
                                        }

                                        > .name {
                                            color: $color_black;
                                        }

                                        > .tag {
                                            color: $color_gray;
                                        }

                                        > .warning {
                                            margin: 6px;
                                            margin-bottom: 0;
                                            font-size: 8pt;
                                            padding: 6px 8px;
                                            @include prefix(border-radius, 3px);
                                            background: $color_lightsmoke;

                                            > span {
                                                display: block;
                                            }
                                        }

                                        &:hover {
                                            background: rgba(0, 0, 0, 0.03);

                                            > .warning {
                                                background: $color_white;
                                            }
                                        }

                                        &:active {
                                            background: rgba(0, 0, 0, 0.1);
                                        }

                                        &.pending {
                                            > .name,
                                            > .tag {
                                                color: $color_warning;
                                            }
                                        }
                                    }

                                    > input[type="radio"] {
                                        float: left;
                                        margin: 12px 14px 0 12px;
                                    }
                                }
                            }
                        }
                    }

                    &.column-description,
                    &.column-language-names {
                        > .textarea {
                            overflow-y: scroll;
                            height: 200px;
                            resize: none; 
                            font-size: 10px;
                        }

                        > .value {
                            > ul {
                                > li {
                                    margin: 4px 0;

                                    > * {
                                        display: inline-block;
                                        vertical-align: middle;
                                    }

                                    > .language {
                                        font-size: 18px;
                                    }

                                    > .name {
                                        margin: 0 4px;
                                        width: calc(100% - 30px);
                                    }
                                }
                            }
                        }
                    }
                }
            }

            > .actions {
                padding: 16px;

                > * {
                    margin-right: 10px;
                    display: inline-block;
                    vertical-align: middle;
                }

                input[type="text"] {
                    width: 300px;
                    padding: 11px;
                    font-size: 10pt;
                }

                > .right {
                    float: right;
                    margin: 0;

                    > * {
                        margin-left: 10px;
                        display: inline-block;
                        vertical-align: middle;
                    }
                }
            }
        }
    }
}

body.translation-hypernyms, body.translation-hypernyms > main {
    .category-kind-items {
        margin-bottom: 100px;
        margin-top: -10px;

        > ul {
            margin: 0;
            padding: 0;
            list-style: none;

            > li {
                background: $color_white;
                @include prefix(border-radius, 4px);
                margin: 40px 0;
                padding: 0;
            }
        }
    }

    .category-kind-item {
        
        > form {
            display: flex;
            flex-direction: row;
            border-bottom: solid 1px $color_palette_smoke;

            select {
                width: 100%;
                padding: 8px;
                font-size: 10pt;
                font-weight: 500;
            }

            > .column {
                flex: 1;

                border-left: solid 1px $color_palette_smoke;
                &:first-of-type { border-left: none; }

                > .title {
                    font-size: 9pt;
                    color: $color_gray;
                    padding: 16px;
                    padding-bottom: 8px;
                }

                > .value {
                    padding: 16px;
                    padding-top: 0;
                    font-size: 9pt;

                    > ul {
                        padding: 0;
                        margin: 0;
                        list-style: none;
                    }
                }

                &.column-no-hypernym {
                    background-color:  #f2c9cb;
                }

                &.column-actions {
                    > .actions {
                        padding: 36px 16px;

                        > button {
                            width: 100%;
                        }
                    }
                }

                &.column-language-names {
                    > .textarea {
                        overflow-y: scroll;
                        height: 200px;
                        resize: none; 
                        font-size: 10px;
                    }

                    > .value {
                        > ul {
                            > li {
                                margin: 4px 0;

                                > * {
                                    display: inline-block;
                                    vertical-align: middle;
                                }

                                > .language {
                                    font-size: 18px;
                                }

                                > .name {
                                    margin: 0 4px;
                                    width: calc(100% - 30px);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

body.translation-lexicons, body.translation-lexicons > main {
    .lexicon-items {
        margin-bottom: 100px;
        margin-top: -10px;

        > ul {
            margin: 0;
            padding: 0;
            list-style: none;

            > li {
                .lexicon-item {
                    background: $color_white;
                    @include prefix(border-radius, 4px);
                    margin: 40px 0;
                    padding: 0;

                    @keyframes item-extend {
                        from { margin: 40px 40px; }
                        to { margin: 80px 0px; }
                    }
                    &.extended {
                        @include prefix(box-shadow, 0 8px 10px 0 #dbe0e3);
                        margin: 80px 0px;

                        &.anim {
                            @include prefix(animation, item-extend 200ms ease forwards);
                        }
                    }
                    &.hidden {
                        display: none;
                    }
                }
            }
        }
    }

    .lexicon-item {

        position: relative;

        // .VariantsField button {
        //     display: none;
        // }

        > .mask {
            background: rgba(255, 255, 255, 0.9);
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }

        > .message {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 500px;
            height: 70px;
            margin-left: -250px;
            margin-top: -35px;
            text-align: center;

            > .title {
                margin-bottom: 6px;
                color: $color_palette_red;

                > * {
                    display: inline-block;
                    vertical-align: middle;
                }
            }

            > .text {
                color: $color_gray;
            }

            opacity: 0;
            @include prefix(animation, slidedown 200ms ease forwards);
        }
        
        @keyframes extend {
            from { height: 0; }
            to { height: 300px; }
        }

        > .extend {
            
            font-size: 10px;
            position: absolute;
            left: -40px;

            > a {
                display: block;
                padding: 4px;
                text-decoration: none;
                outline: none;
                @include prefix(border-radius, 100%);

                > i {
                    display: block;
                    font-size: 24px;
                }

                color: $color_black;

                &:hover {
                    color: $color_palette_red;
                    background: rgba(0, 0, 0, 0.04);
                }

                &:active {
                    background: rgba(0, 0, 0, 0.1);
                }
            }
        }

        > .top {
            display: flex;
            flex-direction: row;
            border-bottom: solid 1px $color_palette_smoke;

            > .column {
                flex: 1;
                overflow-wrap: anywhere;
                border-left: solid 1px $color_palette_smoke;
                &:first-of-type { border-left: none; }

                > .title {
                    color: $color_gray;
                    padding: 16px;
                    padding-bottom: 8px;
                }

                > .value {
                    padding: 16px;
                    padding-top: 0;

                    &.reject-reason-comment {
                        background-color:  #f2c9cb;
                    }

                    > ul {
                        padding: 0;
                        margin: 0;
                        list-style: none;

                        &.variants {
                            padding-left: 15px;
                            list-style: circle;
                        }
                    }

                    > ol {
                        padding-left: 20px;
                    }
                }

                > .textarea {
                    > ul {
                        margin-left: 32px;
                        list-style: disc;
                    }
                }

                &.column-preview-language {
                    flex: 0;
                }

                &.column-translate {
                    > .title {
                        margin-bottom: 4px;
                    }

                    > .translate-value {
                        color: #e48b8f;
                        font-weight: bold;
                        vertical-align: middle;
                        margin: 4px 0;
                        padding: 16px;
                        padding-top: 0;
                        padding-bottom: 0;
                    }
                }

                &.column-sentences{
                    > .textarea {
                        overflow-y: scroll;
                        height: 200px;
                        resize: none; 
                    }
                }
                    
                &.column-language-names {
                    > .textarea {
                        overflow-y: scroll;
                        height: 200px;
                        resize: none;
                    }
                }
            }
        }
        
 

        > .bottom {
            > form {
                form.ui.form.lexicon-editor {
                    position: static !important; // is relative by default but that will overlap the the overlay .mask class
                }
                &.dir-rtl {
                    text-align: right;
                }

                fieldset {
                    display: inline-block;
                    vertical-align: bottom;
                    border: none;
                    padding: 0;

                    > div {
                        display: inline-block;
                        vertical-align: bottom;
                    }
                }

                label {
                    color: $color_gray;
                    display: block;
                    margin-bottom: 4px;
                }


                #root {
                    width: 100%;
                    margin: 10px 0;

                    > div:first-of-type {
                        padding: 6px 17px;
                    }

                    > div:last-of-type {
                        display: block;
                    }
                }

                label[for="root_forms"] { display: none; }
                legend[id="root_forms__title"] { display: none; }

                input, select {
                    width: 100%;
                    padding: 6px 8px;
                    font-weight: 500;
                }

                #root_forms {
                    width: 100%;

                    > div {
                        width: 20%;
                        padding: 6px 14px;
                    }
                }

                .row {
                    display: none;
                }

                > .template {
                    padding: 16px;
                }

                > .field_configs {
                    padding:  16px;
                    display: flex;
                    justify-content:  space-between;
                    > div {
                        width: 32%;
                    }
                }

                > .template-preview {
                    border-top: solid 1px $color_palette_smoke;
                    padding: 16px;

                    > .title {
                        color: $color_gray;
                        margin-bottom: 4px;
                    }

                    .value {
                        &.error {
                            color: $color_palette_red;
                        }
                    }
                }

                > .preview {
                    > div {
                        margin: 16px;
                        padding: 16px;
                        background: $color_palette_smoke;
                        @include prefix(border-radius, 4px);

                        > .label {
                            display: inline-block;
                            text-transform: uppercase;
                            vertical-align: middle;
                            margin-bottom: 8px;
                        }

                        > .text {

                            h1 {
                                margin: 0;
                                font-size: 14pt;
                            }

                            .highlight {
                                font-weight: bold;
                            }
                            
                            .preview-highlight {
                                font-weight: bold;
                                color: $color_palette_red;
                            }
                        }
                    }

                    @keyframes loading-bar {
                        0% { left: 0; width: 0; }
                        25% { left: 0; width: 100%; }
                        50% { left: 0; width: 100%; }
                        75% { left: 100%; width: 100%; }
                    }

                    position: relative;
                    padding: 2px 0;

                    &.loading {
                        overflow: hidden;

                        &::before {
                            bottom: 0;
                            content: " ";
                            @include prefix(animation, loading-bar 1.5s infinite linear);
                            position: absolute;
                            background: $color_palette_red;
                            height: 3px;
                        }
                    }
                }

                > .actions {
                    border-top: solid 1px $color_palette_smoke;
                    padding: 16px;

                    > * {
                        margin-right: 10px;
                        display: inline-block;
                        vertical-align: middle;
                    }

                    input[type="text"] {
                        width: 300px;
                        padding: 11px;
                    }

                    > .right {
                        float: right;
                        margin: 0;

                        > * {
                            margin-left: 10px;
                            display: inline-block;
                            vertical-align: middle;
                        }
                    }
                }
            }
        }
    }
}

body.translation-home, body.translation-home > main {
    > .container {
        width: 900px;

        > .menu {
            > div {
                height: 80px;
                font-size: 15pt;
                padding: 40px 0;

                opacity: 0;
                @include prefix(animation, slidedown 200ms ease forwards);
                animation-delay: 1000ms;
            }

            > ul {
                float: right;
                list-style: none;
                margin: 30px 0;
                padding: 0;
                position: relative;
                z-index: 2;

                > li {
                    display: inline-block;

                    opacity: 0;
                    @include prefix(animation, slidedown 200ms ease forwards);

                    &:nth-child(1) { animation-delay: 1050ms; }
                    &:nth-child(2) { animation-delay: 1100ms; }
                    &:nth-child(3) { animation-delay: 1150ms; }

                    > a {
                        display: block;
                        background: $color_white;
                        padding: 12px 20px;
                        margin-left: 30px;
                        @include prefix(border-radius, 4px);
                        text-decoration: none;
                        @include prefix(box-shadow, 0 4px 6px 0 #dbe0e3);

                        > * {
                            display: inline-block;
                            vertical-align: middle;
                        }

                        > span {
                            font-size: 11pt;
                        }

                        color: $color_black;
                        @include prefix(transition, transform 100ms ease);

                        &:hover {
                            color: $color_palette_red;
                            transform: scale(1.1);
                        }

                        &:active {
                            color: $color_black;
                            background: rgba(0, 0, 0, 0.2);
                            transform: scale(1.05);
                        }
                    }
                }
            }
        }

        > .languages {
            margin-bottom: 100px;

            > select {
                margin-top: 0;
            }

            > ul {
                list-style: none;
                padding: 0;
                margin: 0;

                > li {
                    margin: 30px 0;
                    padding: 10px 20px;

                    background: $color_white;
                    @include prefix(border-radius, 12px);
                    @include prefix(box-shadow, 0 8px 10px 0 #dbe0e3);

                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    opacity: 0;
                    @include prefix(animation, slidedown 200ms ease forwards);

                    &:nth-child(1) { animation-delay: 100ms; }
                    &:nth-child(2) { animation-delay: 150ms; }
                    &:nth-child(3) { animation-delay: 250ms; }
                    &:nth-child(4) { animation-delay: 300ms; }
                    &:nth-child(5) { animation-delay: 350ms; }
                    &:nth-child(6) { animation-delay: 400ms; }

                    > .language {
                        flex: 1;
                        padding: 20px 0;

                        > * {
                            display: inline-block;
                            vertical-align: middle;
                        }

                        > .flag {
                            font-size: 50px;
                            margin: 0 14px;
                        }

                        > .name {
                            font-size: 16pt;
                        }
                    }

                    > .board > .partial {
                        flex: 2;
                        text-align: right;

                        > div {
                            margin: 14px 0;

                            > * {
                                display: inline-block;
                                vertical-align: middle;
                            }

                            > .word-type {
                                text-align: right;
                                padding-right: 20px;
                                font-size: 10pt;

                                > * {
                                    display: inline-block;
                                    vertical-align: middle;
                                    margin: 0 4px;
                                }

                                > i {
                                    font-size: 24px;
                                }
                            }

                            > a {
                                text-align: center;
                                text-decoration: none;
                                width: 80px;
                                margin: 0 10px;

                                > span {
                                    display: block;

                                    &:first-of-type {
                                        font-size: 12pt;
                                        margin-bottom: 2px;
                                    }

                                    &:last-of-type {
                                        font-size: 9pt;
                                    }
                                }

                                &.pending {
                                    color: $color_palette_red;
                                }

                                &.done {
                                    color: rgba(0, 0, 0, 0.2);
                                }
                            }

                            > .status {
                                display: none;
                                width: 60px;
                                text-align: center;
                            }

                            &.done > .status.status-done {
                                display: inline-block;
                                color: $color_success;
                                opacity: 0.7;
                            }

                            &.pending > .status.status-pending {
                                display: inline-block;
                                color: $color_palette_red;
                            }

                            &:hover {
                                > .word-type {
                                    color: $color_palette_red;
                                }

                                > a {
                                    &.done {
                                        color: rgba(0, 0, 0, 0.4);
                                    }

                                    &:hover {
                                        color: $color_black;
                                    }

                                    &:active {
                                        color: $color_palette_blue;
                                    }
                                }
                            }
                        }
                    }

                    > .board > .partial-language:before {
                        top: 40%;
                        left: 75%;
                        margin-top: 0;
                        margin-left: 0;
                    }
                }
            }
        }
    }
}

.template-lexicon-items > ul > li {
    position: relative;
}

.template-lexicon-items > ul > li,
.lexicon-item, .vocabulary-request-item {
    > span.prio {
        display: block;
        position: absolute;
        top: -28px;
        right: 0;
        padding: 4px 10px;

        > * {
            display: inline-block;
            vertical-align: middle;
        }

        > i {
            font-size: 15px;
        }

        > span {
            font-size: 9pt;
        }

        &.low { color: $color_gray; }
        &.medium { color: $color_warning; }
        &.high { color: $color_error; }
    }
}
