
.TemplateVariantsField {

    > .actions {
        margin-left: 20px;
    }

    > .row {
        display: flex;

        > .mode-toggle {
            margin-top: 60px;

            > a {
                display:  block;
                text-transform:  uppercase;
                font-size: 9pt;
                padding: 10px;
                border-right: solid 3px transparent;

                &.active {
                    color: $color_palette_red;
                    border-right: solid 3px $color_palette_red;
                }

            }
        }

        .shortcuts {
            display: flex;
            flex-wrap: wrap;
            button {
                margin: 3px;
                width: auto;
            }
        }

        > .edit {
            flex: 1 0 50%;
            margin: 10px 0;

            > * {
                display: inline-block;
                vertical-align: middle;
            }
            > .shortcuts {
                display: flex;
                flex-wrap: wrap;
            }

            > textarea {
                padding: 10px 10px;
            }
        }
        > .jinja-generator {
            flex: 1 0 30%;
            margin: 10px 20px;
        }
    }

    button.add, button.copy, button.remove, button.insert {
        border: none;
        background: transparent;
        cursor: pointer;
        padding: 3px 6px;

        > * {
            display: inline-block;
            vertical-align: middle;
        }

        > span {
            font-size: 9pt;
            font-weight: 500;
            margin-left: 4px;
        }

        color: $color_palette_blue;

        &:hover {
            color: $color_palette_red;
        }

        &:active {
            color: $color_black;
        }
    }
}
