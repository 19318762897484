
body.product-list, body.product-list > main  {

    background: $color_white;

    .container {
        max-width: none;
        width: 96%;
        > div.upper {
            display: flex;
            align-items: center;
            > * {
                flex-basis: 50%;
            }
            > h1 {
                text-align: left;
                font-size: 16pt;
                margin: 30px 0;

                > * {
                    display: inline-block;
                    vertical-align: middle;
                }
            }
            > div {
                text-align: right;
                > button {
                    margin: 20px 0 0 5px;
                }
            }
        }

        > div.lower {
            display: flex;
            flex-direction: row;
            > .left {
                flex: 1;
                > .bottom {
                    margin-top: 30px;
                    padding-bottom: 50px;
                }
            }
        }
    }

    &.product-list-query {

        .custom-query {
            width: 800px;
            margin: 0 auto;
        }

        .customer-details {
            display: flex;
            justify-content: space-between;

            .languages, .channels, .imported-metadata {
                flex-basis: 45%;

                .table {
                    .detail-row {
                        display: grid;
                        grid-template-columns: 5fr 2fr;
                        grid-auto-rows: auto;

                        h5, p {
                            margin: 0.25em;
                        }
                    }
                }
            }
        }

        .input-container {
            margin-top: 2em;
            font-size: large;

            .tree {
                font-family: monospace;
            }

            textarea {
                padding: 1em;
                font-family: monospace;
            }
        }

        .sample-conditions {
            .item {
                .content {
                    padding: 0;
                    margin-bottom: 1em;
                }
                code {
                    display: block;
                    margin-top: .2em;
                }
            }
        }
    }
}

label {
    user-select: none;
}
