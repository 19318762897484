@import 'base/colors';
@import 'base/mixins';

// SEMANTIC_UI PRODUCT TAGS EDITOR
.treeViewBox {
  .sortable_tree_h {
    min-height: 10vh !important;
    position: relative;
    clear: both;

    .flex-row {
      display: flex;
      .custom_label {
        flex: 1;
      }
      button {
        align-self: flex-end;
        margin-left: 4px;
      }
    }

    .product_type_container {
      display: flex;
      align-items: start;
    }
  }
  .custom_label {
	font-weight: 700
  }
  .extract_btn{
    position: absolute;
    right: -5px;
    top: 242px;
  }
  textarea {
    resize: none !important;
  }
  .m_top_10{
    margin-top: 10px !important;
  }
  .treeview_dropdown{
    width: 300px !important;
  }
  .app_style_btn{
    color: #ffffff !important;
    background: #d74c50 !important;
    border-color: #d74c50 !important;
  }
  .trasparent_btn{
    color: #d74c50 !important;
    background: transparent !important;
    border-color: #d74c50 !important;
    outline: auto !important;
  }
  .Language_setting_btn{
    margin-right: 10px !important;
    margin-bottom: 10px !important;
  }

  .material_ui_btn{
    display: inline-block;
    font-size: 16px;
    float: right;
    margin: 16px;
    cursor: pointer;
    color: #fff !important;
  }
  .app_desc{
    display: inline-block;
  }
  .rstcustom__nodeContent{
    width: 100%;
    position: relative;
  }

  .custom_class{
    padding-left: 0 !important;

    .rstcustom__rowContents{
      flex: 1;
      background: #fff;
      padding: 3px 10px;
      box-shadow: none;
    }
    .rstcustom__rowContents:hover {
      box-shadow: none;
    }
  }
  .rstcustom__collapseButton,.rstcustom__rowLabel,.rstcustom__expandButton{
    display: none;
  }

  .rstcustom__rowWrapper{
    opacity: 1
  }

  .ui.multiple.search.dropdown.multiple-treeview_dropdown{
    max-width: 825px !important;
    width: 100% !important;
    min-width: 300px;
  }
  .product_type{
    width: 60% !important;
    display: inline-block !important;
  }
  .product_tags{
    width: 70% !important;
    display: inline-block !important;
  }
    
  .ui.multiple.search.dropdown.alt-style-labels,
  .ui.fluid.multiple.search.selection.dropdown.alt-style-labels {
    a.ui.label {
      background: transparent;
      color: rgba(0, 0, 0, 0.87);
      font-weight: 500;
    }
  }
  .ui.multiple.search.dropdown.multiple-treeview_dropdown,
  .ui.fluid.multiple.search.selection.dropdown.product_tags {
    a.ui.label {
      background: transparent;
    }
  }

  .width_50_per{
    width: 50% !important;
  }

  .product_text_item{
      padding-bottom: 20px;
      padding-right: 20px;
  }
  .select_all_lang{
      font-weight: 600;
  }
  .width_85_per{
      width: 85%;
  }

  // advanced template child tree
  .advanced-child-tree {
    // Makes the dropdown menu show
    .ReactVirtualized__Grid.ReactVirtualized__List.rst__virtualScrollOverride {
      overflow: visible !important;
    }
    .rstcustom__node {
      overflow: inherit;
    }
    // Remove the default background when hovering a node
    .rstcustom__highlight {
      background: none;
    }
    // This padding is the default padding. We do not want it to change when hovering the element
    .rstcustom__rowWrapper:hover {
      padding: 2px 2px 2px 0;
    }  
}

  .tree_view_table {
      overflow-x: visible;
      overflow-y: visible;
      padding-bottom: 35px;
      z-index: 9;
      position: relative;
      &.hide-placeholder {
        padding-bottom: 0;
        *.rst__placeholder {
           display:none;
         }
      }
  }
  .width_hundred{
      width: 100%;
  }

  .hamburger_menu{
      background: #223d85 !important;
      height: 60px !important;
  }
  .menu_icon_color{
    color: #fff !important;

    i.bars.icon {
      font-size: 20px !important;
    }
  }
  .ui.dimmer.inverted{
      position: fixed;
  }
  .width_100{
      width: 100% !important;
  }
  .container_body{
      margin: 10px;
  }

  button,input,optgroup,select,textarea{font-family:sans-serif;font-size:100%;line-height:1.15;margin:0}
}

.field .hide-placeholder .treeViewBox {
   margin: 0 !important;
   .custom-widget-dropdown-width {
     min-width: unset;
     width: 110px;
   }
   .sortable-handle-child {
     color: $color_darksmoke;
     padding-top: 5px;
     cursor: move;
   }
 }
.field .light-warning {
    font-size: 10pt;
    opacity: 0.3;
    padding: 20px 0;
  }

// Vocabulary Lookup New
.vocabulary-lookup {
  position: relative;
  box-sizing: border-box;

  .input-container {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: hsl(0,0%,100%);
    border-color: hsl(0,0%,80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    cursor: default;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    min-height: 38px;
    outline: 0 !important;
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    box-sizing: border-box;

    &.input-focused {
      border-color:#2684FF;
      box-shadow:0 0 0 1px #2684FF;
    }

    &:hover {
      border-color: hsl(0,0%,60%);
    }

    .input-wrapper {
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      padding: 2px 8px;
      -webkit-overflow-scrolling: touch;
      position: relative;
      box-sizing: border-box;

      .selected-value {
        position: absolute;
      }

      .single-input {
        position: absolute;
        width: 100%;
        border: none;
        background-color: transparent;

        &:focus {
          outline: none;
        }
      }

      .multi-input {
        flex: 1;
        border: none;

        &:focus {
          outline: none;
        }
      }

      .tag {
        background-color: hsl(0,0%,90%);
        border-radius: 2px;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        margin: 2px;
        min-width: 0;
        box-sizing: border-box;

        .tag-label {
          border-radius: 2px;
          color: hsl(0,0%,20%);
          font-size: 85%;
          overflow: hidden;
          padding: 3px;
          padding-left: 6px;
          text-overflow: ellipsis;
          box-sizing: border-box;
        }

        .tag-remove {
          -webkit-align-items: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          border-radius: 2px;
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          padding-left: 4px;
          padding-right: 4px;
          box-sizing: border-box;

          &:hover {
            background-color: #FFBDAD;
            color: #DE350B;
          }
        }
      }

      .sizer-style {
        position: absolute;
        top: 0;
        left: 0;
        visibility: hidden;
        height: 0;
        overflow: scroll;
        white-space: pre;
      }

      .multi-sizer-style {
        font-size: 85%;
      }
    }

    .icon-svg {
      display: inline-block;
      fill: currentColor;
      line-height: 1;
      stroke: currentColor;
      stroke-width: 0;
    }

    .indicator-container {
      z-index: 10;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-align-self: stretch;
      -ms-flex-item-align: stretch;
      align-self: stretch;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      box-sizing: border-box;

      .icon-wrapper {
        color: hsl(0,0%,80%);
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        padding: 8px;
        -webkit-transition: color 150ms;
        transition: color 150ms;
        box-sizing: border-box;

        &:hover {
          color: hsl(0,0%,60%);
        }
      }

      .loading-indicator {
        color: hsl(0,0%,40%);
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        padding: 8px;
        -webkit-transition: color 150ms;
        transition: color 150ms;
        -webkit-align-self: center;
        -ms-flex-item-align: center;
        align-self: center;
        font-size: 4px;
        line-height: 1;
        margin-right: 4px;
        text-align: center;
        vertical-align: middle;
        box-sizing: border-box;

        @keyframes loading-animation{0%,80%,100%{opacity:0;}40%{opacity:1;}}
      }
    }
  }

  .suggestions-container {
    top: 100%;
    background-color: hsl(0,0%,100%);
    border-radius: 4px;
    box-shadow: 0 0 0 1px hsla(0,0%,0%,0.1), 0 4px 11px hsla(0,0%,0%,0.1);
    margin-bottom: 8px;
    margin-top: 8px;
    position: absolute;
    width: 100%;
    z-index: 30;
    box-sizing: border-box;

    div {
      max-height: 300px;
      overflow-x: hidden;
      overflow-y: auto;
      padding-bottom: 4px;
      padding-top: 4px;
      position: relative;
      -webkit-overflow-scrolling: touch;
      box-sizing: border-box;

      .menu-item {
        background-color: transparent;
        color: inherit;
        cursor: default;
        display: block;
        font-size: inherit;
        padding: 8px 12px;
        width: 100%;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
        box-sizing: border-box;

        &.suggestion-active {
          background-color: #DEEBFF;
          color: inherit;
          cursor: default;
          display: block;
          font-size: inherit;
          padding: 8px 12px;
          width: 100%;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          -webkit-tap-highlight-color: rgba(0,0,0,0);
          box-sizing: border-box;
        }

        .option-request-word {
          cursor: pointer;
          display: inline-block;
          width: 100%;
          min-height: 1em;
          font-size: 9pt;
          outline: none;
          border: none;
          vertical-align: baseline;
          background: #E0E1E2 none;
          color: rgba(0, 0, 0, 0.6);
          font-family: 'Montserrat', 'Helvetica Neue', Arial, Helvetica, sans-serif;
          margin: 0em 0.25em 0em 0em;
          padding: 0.78571429em 1.5em 0.78571429em;
          text-transform: uppercase;
          text-shadow: none;
          font-weight: bold;
          line-height: 1em;
          font-style: normal;
          text-align: center;
          text-decoration: none;
          border-radius: 0.28571429rem;
        }
      }

      .placeholder {
        color: hsl(0,0%,60%);
        padding: 8px 12px;
        text-align: center;
        box-sizing: border-box;
      }
    }
  }
}

// FLUENT INPUT
.fluent-input {
  font-weight: 500;
  font-size: 20px;
  text-align: left;
  position: relative;

  .input {
    display: flex;
    flex-flow: row;
    padding: 10px;
    background: white;
    border: 1px solid gray;
    min-width: 500px;

    [contenteditable]:focus {
      outline: 0 solid transparent;
    }

    span {
      display: inline-block;
      position: relative;
      padding: 3px;
      min-width: 2px;
      white-space: nowrap;

      &.error {
        border: 1px solid darkred;
        background: lightcoral;
      }

      &.complete {
        border: 1px solid gray;
      }

      &.lastSpan {
        flex: 1;
      }
    }
  }

  ul {
    position: absolute;
    z-index: 1000;
    background: white;
    border: 1px solid gray;
    list-style-type: none;
    margin: -12px 0 0;
    padding: 0;
    cursor: pointer;
    max-height: 200px;
    max-width: 300px;
    overflow-y: scroll;

    li {
      padding: 3px;

      &.suggestion-active {
        background-color: lightgray;
      }
      &:hover {
        background-color: lightgray;
      }
    }
  }

  .spinner {
    z-index: 100;
    position: absolute;
    background-color: #00000052;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;

    .lds-ellipsis {
      display: inline-block;
      position: relative;
      width: 64px;
      height: 64px;
      top: calc(100% / 2 - 64px / 2);

      div {
        position: absolute;
        top: 27px;
        width: 11px;
        height: 11px;
        border-radius: 50%;
        background: #fff;
        animation-timing-function: cubic-bezier(0, 1, 1, 0);

        &:nth-child(1) {
          left: 6px;
          animation: lds-ellipsis1 0.6s infinite;
        }

        &:nth-child(2) {
          left: 6px;
          animation: lds-ellipsis2 0.6s infinite;
        }

        &:nth-child(3) {
          left: 26px;
          animation: lds-ellipsis2 0.6s infinite;
        }

        &:nth-child(4) {
          left: 45px;
          animation: lds-ellipsis3 0.6s infinite;
        }
      }
    }

    @keyframes lds-ellipsis1 {
      0% {
        transform: scale(0);
      }
      100% {
        transform: scale(1);
      }
    }
    @keyframes lds-ellipsis3 {
      0% {
        transform: scale(1);
      }
      100% {
        transform: scale(0);
      }
    }
    @keyframes lds-ellipsis2 {
      0% {
        transform: translate(0, 0);
      }
      100% {
        transform: translate(19px, 0);
      }
    }
  }
}

// ANNOTATION EDITOR
.annotation {
  flex: 1;
  color: #444;
  display: flex;
  position: relative;
  flex-wrap: wrap;
  max-width: 675px;
  background: #fff;
  min-height: 100px;
  font-family: "Lato", "Trebuchet MS", Roboto, Helvetica, Arial, sans-serif;
  align-content: space-between;
  border-radius: 0;
  flex-direction: row;
  text-align: start;
  font-size: 20px;

  .entity-content {
    width: 310px;
    position: absolute;
    z-index: 1001;
    border: 1px solid #D4D4D5;
    max-width: 300px;
    background: #FFFFFF;
    padding: 10px 18px;
    color: rgba(0, 0, 0, 0.87);
    border-radius: 0.28571429rem;
    box-shadow: 0px 2px 4px 0px rgba(34, 36, 38, 0.12), 0px 2px 10px 0px rgba(34, 36, 38, 0.15);

    .entity-type-selection {
      display: inline-block;
      min-width: 120px;
      padding: 6px 8px;
      margin: 3px 5px;
      border-radius: 0.28571429rem;
      background: #f4f5f9;
      align-self: center;
      color: #233d84;
      font-size: 13px;
      font-weight: bold;
      text-transform: uppercase;
      text-align: center;
      line-height: 13px;
      cursor: pointer;

      &.disabled {
        color: #c5c5c5;
      }
    }
  }

  .taggy-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 10px 10px;

    .taggy-content:not(:first-child) {
      border-top: 1px solid lightgray;
    }

    .taggy-content {
      line-height: 2;
      background-color: white;

      .tier-line {
        width: 20px;
        height: 500px;
        position: absolute;
        margin-left: -10px;
        margin-top: -465px;
        border-bottom: 2px solid black;
        border-left: 2px solid black;
      }

      .taggy-wrapper {
        margin-top: 10px;
        padding: 5px 10px;

        span {
          display: inline-block;

          mark {
            color: #583fcf;
            cursor: pointer;
            position: relative;
            margin: 0 3px;
            display: inline;
            padding: 5px 8px;
            background: #ffe184;
            line-height: 1;
            -webkit-box-decoration-break: clone;
            font-size: 16px;
            font-weight: bold;
            font-family: "Roboto Condensed", "Arial Narrow", sans-serif;
            margin-left: 8px;
            text-transform: uppercase;
            vertical-align: middle;

            &.requestable {
              border-color: #cf2943;
              border-width: 3px;
            }

            &:hover > .cross-button {
              opacity: 1
            }

            .cross-button {
              top: -9px;
              left: -10px;
              color: #fff;
              width: 18px;
              height: 18px;
              display: block;
              position: absolute;
              background: #b7b7b7;
              transition: opacity 0.1s ease;
              border-radius: 50%;
              opacity: 0;
            }
            .cross-button:before, .cross-button:after {
              position: absolute;
              top: 3px;
              left: 8px;
              content: ' ';
              height: 12px;
              width: 2px;
              background-color: #333;
            }
            .cross-button:before {
              transform: rotate(45deg);
            }
            .cross-button:after {
              transform: rotate(-45deg);
            }
          }

          [data-entity] {
            padding: 0.25em 0.35em;
            margin: 0px 0.25em;
            line-height: 1;
            display: inline-block;
            border-radius: 0.25em;
            border: 1px solid #bbb;
          }

          [data-entity="att"] {
            background: #bbb;
            border: 1px solid #fff;
          }

          [data-entity="type"] {
            background: #a6e22d;
          }

          [data-entity="prop"] {
            background: #ef60b4;
          }

          [data-entity="mat"] {
            background: #fd9720;
          }

          [data-entity="pos"] {
            background: #fd9720;
          }

          [data-entity="col"] {
            background: #a99dfb;
          }

          [data-entity="phrase"] {
            background: #2fbbab;
          }

          [data-entity="sen"] {
            background: #2fbbab;
          }

          [data-entity]::after {
            box-sizing: border-box;
            content: attr(data-entity);
            font-size: 0.6em;
            line-height: 1;
            padding: 0.35em;
            border-radius: 0.35em;
            text-transform: uppercase;
            display: inline-block;
            vertical-align: middle;
            margin: 0px 0px 0.1rem 0.5rem;
            background-color: white;
            font-weight: bold;
            color: lightgray;
          }
        }
      }
    }
  }
}


.text_wrap {
  white-space: normal;
  width: 100% !important;
}
.ui.modal > .content > .description {
    width: 100%;
}
.mb_20{
    margin-bottom: 20px;
}

// LEXICON EDITOR

      .object-property-expand{
        display: none;
      }
      .btn-info {
        display: none;
        &.btn-add {
          display: inline-block;
          border: 1px solid #aaa;
          border-radius: 3px;
          background-color: darkgreen;
        }
      }
      .array-item {
        label {
          display: none;
        }
      }
      .array-item-list{
        display: grid !important;
        grid-gap: 1rem;
        .array-item{
          grid-template-columns: 1fr auto;
          display: grid;
          grid-gap: 1rem;
          .array-item-move-up, .array-item-move-down {
            display: none !important;
          }
          .array-item-remove {
            border: 1px solid #aaa;
            border-radius: 3px;
            background-color: indianred;
          }
          .array-item-toolbox {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
      .glyphicon {
        &.glyphicon-plus {
          &:before {
            font-size: 1.25em;
            color: white;
            content: "+";
            font-style: normal !important;
          }
        }
        &.glyphicon-remove {
          &:before {
            font-size: .75em;
            margin-bottom: .25em;
            color: white;
            content: "X";
            font-style: normal !important;
          }
        }

      }

// VOCABULARY LOOKUP

.VocabularyLookup {
    .css-d8oujb {
        display: none;
    }
}

.tree_view_popover{
  width: 310px;
  left: calc(100% + 10px) !important;
  top: 45px !important;
}
.tree_view_popover.ui.top.popup:before{
  top: -5px !important;
  transform: rotate(226deg) !important;
  display: none;
}

.tree_view_popover .column.grid .aligned.column{
  width: 100%;
  display: flex;
  padding: 10px 15px
}
.tree_view_popover .column.grid .aligned.column button{
  align-self: center;
  background: transparent;
  border: 1px solid #555;
  font-size: 10pt;
  padding: 6px 8px;
  min-width: 120px;
  margin-right: 15px;
  height: 32px;
  font-weight: normal;
  color: $color_palette_blue;
  background: $color_palette_smoke;
  border: none;
  font-weight: bold;
}
.tree_view_popover .column.grid .aligned.column p{
  text-align: left;
  margin: 0;
  font-size: 10pt;
  line-height: 18px;
  white-space: normal;
}

.template-selector {
  .mask {
      position: fixed;
      z-index: 100;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: rgba(33, 40, 76, 0.5);
      @include prefix(border-radius, 10px);
  }

  .modal {
      width: 100%;
      z-index: 100;

      position: absolute;
      top: 150px;
      left: 0px;
      right: 0px;

      > .inner {
          > h1 {
              margin: -20px;
              margin-bottom: 20px;
              padding: 12px 20px;
              font-size: 12pt;
              font-weight: 600;
              background: $color_smoke;
              @include prefix(border-radius, 10px 10px 0 0);
          }

          @include prefix(border-radius, 10px);

          width: 700px;
          // height: 400px;
          margin: 0 auto;
          padding: 20px;
          background: $color_white;

          > .template-autocomplete {

              > input {
                  padding: 10px;
                  display: block;
                  border: 1px solid $color_smoke;
                  width: 100%;
              }

              > ul {
                  height: 300px;
                  overflow-y: scroll;

                  padding: 0;
                  list-style: none;

                  > li {
                      cursor: pointer;
                      padding: 3px;

                      &.selected {
                          background-color: $color_smoke;
                      }
                      .label:not(.alt-style-labels) {
                            font-size:  9pt;
                            // text-transform:  uppercase;
                            padding: 0 2px;
                            margin: 0 2px;
                            color: $color_gray;
                            border: 1px solid rgba(0, 0, 0, 0.2);
                            @include prefix(border-radius, 2px);
                        }
                      .template-display-name {
                        margin: 2px 0;
                      }
                  }
              }
          }
      }
  }
}

.treeViewButtonContainer {
  margin: 5px;
}

.helper-text-query, .helper-text-template {
  margin-top: 10px;
  margin-bottom: 100px;
  &.helper-text-template {
    display: none;
  }
    > h2 {
        font-size: 12pt;
        font-weight: 500;
        text-transform: uppercase;
    }
    > ul {
        > li {
            margin-bottom: 10px;
            font-size: 9pt;
            > :first-child {
                display: block;
                font-weight: 600;
                margin-bottom: 2px;
            }
            code {
                font-family: monospace;
            }
        }
    }
}
