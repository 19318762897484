body.wizard, body.wizard > main {

    .select-product-type {
        max-width: 700px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        > * {
            display: flex;
            flex-direction: column;
            text-align: center;
            margin: 30px;
            width: 250px;
            padding: 30px;

            text-decoration: none;
            color: $color_black;
            font-weight: 500;

            background: $color_white;
            @include prefix(border-radius, 10px);
            @include prefix(box-shadow, 0 10px 24px 0 #dbe0e3);

            border: 2px solid transparent;

            &:hover {
                border: 2px solid $color_palette_red;
            }

            > .image {
                > img {
                    width: 130px;
                }

                margin-bottom: 30px;
            }
        }
    }

    form.filters {
        font-size: 10pt;

        label {

            > * {
                display: inline-block;
                vertical-align: middle;
            }

            > input[type="text"] {
                padding: 5px 10px;
            }

            > button {
                background: transparent;
                border: none;
                cursor: pointer;

                > i {
                    font-size: 20px;
                }

                &:hover {
                    color: $color_palette_red;
                }

                &:active {
                    color: $color_black;
                }
            }
        }

        float: right;
        margin-left: -300px;
    }

    form.ordering {
        font-size: 10pt;

        span,
        select {
            padding: 4px 6px;
            display: inline-block;
        }

        span {
            text-transform: uppercase;
            color: $color_gray;
            padding-right: 2px;
        }

        float: left;
        margin-right: -270px;
    }

    .paginator {
        text-align: center;

        > * {
            display: inline-block;
            vertical-align: middle;

            > a {
                display: block;
                text-decoration: none;
                border: solid 1px $color_palette_red;
                @include prefix(border-radius, 100%);
                outline: none;

                > i {
                    display: block;
                    color: $color_palette_red;
                    font-size: 14px;
                    padding: 3px;
                }

                &.disabled {
                    border-color: #9d9ea2; // gray
                    pointer-events: none;

                    > i {
                        color: #9d9ea2; // gray
                    }
                }

                &:active {
                    border-color: $color_black;

                    > i {
                        color: $color_black;
                    }
                }
            }
        }

        > .position {
            width: 80px;
            text-align: center;
            font-size: 18px;
            font-weight: 600;
        }
    }

    .wizard-dialog {
        > .mask {
            z-index: 2;
            position: fixed;
            top: 60px;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(33, 40, 76, 0.5);
        }

        > .dialog {
            position: absolute;
            top: 120px;
            left: 0;
            right: 0;
            padding: 50px 0;

            width: 1166px;
            left: 50%;
            margin-left: -583px;
            z-index: 2;

            > .inner {
                padding: 20px;
                background: $color_white;
                @include prefix(border-radius, 10px);

                > h1 {
                    margin: -20px;
                    margin-bottom: 20px;
                    padding: 12px 20px;
                    font-size: 12pt;
                    font-weight: 600;
                    background: $color_palette_smoke;

                    @include prefix(border-radius, 10px 10px 0 0);
                }

                > h2 {
                    margin: -20px;
                    margin-bottom: 20px;
                    padding: 12px 20px;
                    font-size: 14pt;
                    font-weight: 600;
                    color: $color_palette_red;
                }

                form > .actions > * {
                    margin-right: 10px;
                }

                form {
                    select {
                        padding: 8px 12px;
                    }
                }

                form.extra {
                    float: right;

                    > button {
                        margin: 0;
                        margin-top: -40px;
                    }
                }
            }

            &.dialog-generate-config {
                > .inner {
                    padding-bottom: 60px;
                }

                > .inner > .form > form {
                    > h1 {
                        font-size: 24px;
                        font-weight: 600;
                        text-align: center;
                        margin: 60px 0 20px;
                    }

                    > .text {
                        width: 350px;
                        margin: 20px auto;
                        text-align: center;
                        font-size: 12px;
                    }

                    > .actions {
                        text-align: center;
                        margin: 20px auto 20px;
                        display: flex;
                        justify-content: center;

                        > a {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }


                        > * {
                            display: inline-block;
                            margin: 0 10px;
                        }
                    }

                    > .choices {
                        padding: 20px 0;

                        > .label {
                            text-align: center;
                            font-size: 18px;
                            font-weight: 600;
                            margin: 40px 0;
                        }

                        > .toggle {
                            text-align: center;
                            margin: -20px 0 40px;

                            > * {
                                display: inline-block;
                                vertical-align: middle;
                            }

                            > button,
                            > span {
                                color: $color_palette_red;
                                background: transparent;
                                border: none;
                                font-size: 14px;
                            }

                            > button {
                                cursor: pointer;
                            }
                        }

                        > ul {
                            padding: 0;
                            list-style: none;
                            padding: 0 20px;
                            margin: -20px 0;

                            > li {
                                display: inline-block;
                                vertical-align: top;
                                margin: 20px;

                                > label {
                                    position: relative;

                                    > i {
                                        display: none;
                                        position: absolute;
                                        right: 0;
                                        top: 50%;
                                        width: 24px;
                                        height: 24px;
                                        margin-right: -12px;
                                        margin-top: -12px;
                                        font-size: 14px;
                                        background: $color_palette_red;
                                        color: $color_white;
                                        padding: 5px;
                                        @include prefix(border-radius, 100%);
                                    }

                                    cursor: pointer;
                                    display: block;
                                    width: 220px;
                                    padding: 21px 40px;

                                    > .flag {
                                        float: left;
                                        font-weight: normal;
                                        font-size: 18px;
                                        margin: -1px 10px 0 -18px;
                                    }

                                    > span {
                                        font-size: 14px;
                                        font-weight: 600;
                                        display: block;
                                        white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                    }

                                    @include prefix(box-shadow, 0 10px 24px 0 #dbe0e3);
                                    @include prefix(border-radius, 10px);

                                    border: solid 2px transparent;
                                }

                                > input {
                                    display: none;
                                }

                                > input:checked+label {
                                    border-color: $color_palette_red;

                                    > i {
                                        display: block;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.wizard-upload {
        background: unset;
        .container > .title {
            font-size: 24px;
            font-weight: 600;
            margin-top: 40px;
        }

        .generate-ai-settings, .run-pipeline-settings {
            &.hidden {
                visibility: hidden;
                position: fixed;
            }
            &.show {
                visibility: show;
            }
        }

        form.shops {

            > button,
            > a {
                width: 260px;
                height: 140px;
                display: inline-block;
                border: none;
                @include prefix(border-radius, 6px);
                vertical-align: top;
                margin: 20px 10px;
                padding: 30px 0;
                text-decoration: none;
                color: $color_black;
                cursor: pointer;

                > * {
                    display: block;
                }

                > i {
                    font-size: 40px;
                    color: rgba(0, 0, 0, 0.6);
                }

                > span {
                    margin: 10px 0;
                    font-size: 11pt;
                    font-weight: 600;
                }

                background: transparent;

                &:hover {
                    background: rgba(255, 255, 255, 0.6);

                    > * {
                        color: $color_palette_red;
                    }
                }

                &:active {
                    background: rgba(200, 200, 200, 0.2);
                }
            }
        }

        .container {
            text-align: center;
            padding-bottom: 100px;
            .type-of-upload-list {
                ul {
                    margin: 0;
                    padding: 0;
                    padding-top: 10px;
                    li {
                        list-style-type: none;
                        padding: 10px 0;
                    }
                }
            }
            .advanced {
                width: 100%;
                > .collapsible {
                    button {
                        &.remove-border {
                            border: none !important;
                            box-shadow: none !important;
                        }
                    }
                    width: 100%;
                    > .inner {
                        background: none;
                        margin: 0 10px 0 0;
                        padding: 0;
                    }
                }

                .field {
                    margin-bottom: 30px;

                    &.left {
                        text-align: left;
                    }

                    &:last-of-type {
                        margin-bottom: 10px;
                    }
                }
            }

            .help {
                width: 500px;
                font-size: small;
                display: grid;
                grid-template-columns: 1fr 1fr;
                padding: 20px;
                grid-gap: 10px;
                &.show-on-hover {
                    &.hidden {
                        display: none;
                    }
                    &.show {
                        display: grid;
                    }
                }

                > span {
                    display: inline-block;
                    font-size: 14px;
                    vertical-align: top;
                    text-align: left;

                    &::before {
                        content: " ";
                        width: 10px;
                        height: 10px;
                        border: solid 2px $color_palette_red;
                        display: inline-block;
                        @include prefix(border-radius, 100%);
                        margin: 4px 10px 20px;
                        float: left;
                    }

                }
            }

            > form {

                .header {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    margin: 1em;
                    font-size: 13px;

                    .importer {
                        margin-left: 1em;
                    }
                }

                > .actions {
                    margin: 30px auto 40px;
                }

                > .drop {
                    width: 400px;
                    height: 150px;
                    @include prefix(border-radius, 10px);
                    border: dashed 2px $color_palette_blue;
                    position: relative;
                    margin: 2em auto 30px;
                    display: block;

                    background: transparent;

                    .title > .empty {
                        display: block;
                    }

                    .title > .selected {
                        display: none;
                    }

                    .text > .empty {
                        display: block;
                    }

                    .text > .selected {
                        display: none;
                    }

                    &.selected {
                        background: rgba(0, 0, 0, 0.1);

                        .title > .empty {
                            display: none;
                        }

                        .title > .selected {
                            display: block;
                        }

                        .text > .empty {
                            display: none;
                        }

                        .text > .selected {
                            display: block;
                        }
                    }

                    input[type="file"] {
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        width: 100%;
                        opacity: 0;
                        cursor: pointer;
                    }

                    > .image {
                        background-repeat: no-repeat;
                        background-position: 50% 50%;
                        background-size: contain;
                        background-image: url('../svg/upload-file-1x.svg');
                        margin: 50px auto 0;
                    }

                    > .title {
                        font-size: 18px;
                        font-weight: 600;
                        margin: 5px
                    }

                    > .text {
                        width: 220px;
                        margin: 0 auto;

                        &,
                        * {
                            font-size: 14px;
                            font-weight: 600;
                        }

                        > span {
                            color: $color_palette_red;
                        }
                    }
                }
            }
        }
    }

    &.wizard-select {
        > .message {
            text-align: center;

            > .title {
                font-size: 24px;
                font-weight: 600;
            }

            > .text {
                font-weight: 500;
                padding: 20px 0;

                &,
                * {
                    font-size: 12px;
                }

                > span {
                    font-weight: 700;
                }
            }

            > .actions {}
        }
    }

    &.wizard-mappings {
        .partial {
            @include prefix(transition, opacity 300ms ease);

            &.loading {
                opacity: 0.5;
            }
        }

        > .list {
            margin: 0 auto;

            .wizard-action-list {
                max-width: none;
                width: auto;
            }

            > ul.tabs {
                list-style: none;
                margin: 0;
                padding: 0 20px;
                border-bottom: solid 1px rgba(0, 0, 0, 0.1);
                margin-bottom: 32px;

                > li {
                    display: inline-block;
                    margin: 0 4px;

                    > a {
                        display: block;
                        padding: 10px;
                        border-bottom: solid 3px transparent;
                        font-size: 9pt;
                        color: $color_gray;
                        text-decoration: none;
                        text-transform: uppercase;

                        &:hover {
                            color: $color_black;
                        }

                        &.active {
                            border-bottom-color: $color_palette_red;
                            color: $color_palette_red;
                        }
                    }

                    &.separator {
                        height: 10px;
                        border-right: solid $color_gray 1px;
                    }
                }
            }

            > .message {
                padding: 80px 0 40px;
                text-align: center;

                > .title {
                    font-size: 24px;
                    font-weight: 600;
                }

                > .text {
                    font-weight: 500;
                    padding: 20px 0;

                    &,
                    * {
                        font-size: 12px;
                    }

                    > span {
                        font-weight: 700;
                    }
                }

                > .actions {}
            }

            ul.products {
                list-style: none;
                margin: 0;
                padding: 0;

                > li {
                    margin: 40px 0;

                    > .item {
                        @include prefix(box-shadow, 0 10px 24px 0 #dbe0e3);
                        @include prefix(border-radius, 10px);
                        background: $color_white;
                        font-size: 0;
                        padding: 40px 30px;

                        > .top {
                            display: block;
                            margin: -40px -30px;
                            margin-bottom: 40px;
                            border-bottom: solid 2px $color_palette_smoke;

                            > h3 {
                                margin: 0;
                                padding: 40px;
                                text-align: center;
                                font-size: 18px;
                                font-weight: 600;
                            }
                        }

                        .title {
                            font-size: 14px;
                            padding: 0 10px;
                            text-transform: uppercase;
                            font-weight: 600;
                        }

                        .help {
                            font-size: 12px;
                            float: right;
                            padding: 0 10px;
                            color: #9d9ea2;
                            font-weight: 300;
                        }

                        .value {
                            font-size: 14px;
                            padding: 10px;

                            > .text-preview {
                                h1 {
                                    font-size: 18px;
                                    font-weight: 600;
                                }

                                span {
                                    cursor: default;
                                }

                                span.highlight {
                                    border-bottom: solid 2px;
                                }

                                &.error {
                                    background: lighten($color_errorlight, 30%);
                                }
                            }

                            > .inner {
                                background: $color_palette_smoke;
                                padding: 10px;

                                &.image {
                                    padding: 10px;
                                    width: 100px;
                                    height: 100px;

                                    img {
                                        cursor: pointer;
                                        display: block;
                                        margin: auto;
                                        max-height: 100%;
                                        max-width: 100%;
                                    }

                                    > .placeholder {
                                        background-image: url('../img/tshirt.svg');
                                        background-repeat: no-repeat;
                                        background-position: 50% 50%;
                                        background-size: 70%;
                                        height: 100%;
                                        opacity: 0.6;
                                    }
                                }

                                &.mappings {
                                    padding: 15px;

                                    &.mapped-mappings.fade [data-vocab-id] {
                                        opacity: 0.6;
                                    }

                                    &.disabled {
                                        > * {
                                            opacity: 0.3;
                                            pointer-events: none;
                                        }

                                        > .disabled-message {
                                            opacity: 1;
                                            margin-bottom: 10px;
                                            font-size: 9pt;
                                            color: $color_palette_red;
                                        }
                                    }

                                    > ul {
                                        list-style: none;
                                        margin: 0;
                                        padding: 0;

                                        > li {
                                            display: inline-block;
                                            margin: 5px;

                                            .highlight {
                                                opacity: 1 !important;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        > * {
                            font-size: initial;
                            display: inline-block;
                            vertical-align: top;
                        }

                        > .left {
                            width: 300px;

                            > .value {
                                > .inner {
                                    min-height: 250px;
                                }
                            }
                        }

                        > .right {
                            width: calc(100% - 300px);

                            > .top {
                                font-size: 0;

                                > * {
                                    font-size: initial;
                                    display: inline-block;
                                    vertical-align: top;
                                }

                                > .left {
                                    width: 120px;
                                }

                                > .right {
                                    width: calc(100% - 120px);

                                    > .value {
                                        > .inner {
                                            min-height: 100px;
                                        }
                                    }
                                }
                            }

                            > .bottom {
                                margin-top: 10px;

                                > .value {
                                    > .inner {
                                        min-height: 100px;
                                    }
                                }
                            }
                        }

                        > .actions {
                            text-align: right;
                            display: block;
                            padding: 10px;

                            > a {
                                display: inline-block;
                                margin-left: 14px;

                                > i {
                                    font-size: 18px;
                                    display: inline-block;
                                    vertical-align: middle;
                                    margin: -5px 0 -2px 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.wizard-generate > main {
        > .list {
            width: 1166px;
            margin: 0 auto;

            .messages {
                align-items: center;
                display: flex;
                justify-content: space-between;
                margin-bottom: 42px;
            }

            .message-container {
                display: flex;
                justify-content: center;

                > .message {
                    padding: 80px 0 40px;
                    text-align: center;
                    margin: 0 20px;

                    > .title {
                        font-size: 24px;
                        font-weight: 600;
                    }

                    > .text {
                        font-weight: 500;
                        padding: 20px 0;

                        &,
                        * {
                            font-size: 12px;
                        }

                        > span {
                            font-weight: 700;
                        }
                    }

                    > .actions {
                        text-align: center;

                        > .partial > form,
                        > form {
                            display: inline-block;

                            button {
                                margin: 0;
                            }
                        }

                        > form#publish-form {
                            select {
                                padding: 9px;
                            }
                        }
                    }
                }
            }

            ul.products {
                list-style: none;
                margin: 0;
                padding: 0;

                > li {
                    margin: 40px 0;

                    > .item {
                        @include prefix(box-shadow, 0 10px 24px 0 #dbe0e3);
                        @include prefix(border-radius, 10px);
                        background: $color_white;
                        padding: 0;

                        > .top {
                            position: relative;

                            > h3 {
                                margin: 0;
                                padding: 40px;
                                text-align: center;
                                font-size: 18px;
                                font-weight: 600;
                            }

                            > .actions {
                                position: absolute;
                                top: 0;
                                right: 0;
                                padding: 30px 20px;
                            }
                        }

                    }
                }
            }
        }
    }
}

.partial.upload-task {
    > .dialog {
        width: 500px;
        background: $color_smoke;
        @include prefix(border-radius, 3px);
        padding: 20px;
        font-size: 11pt;

        &.big {
            top: auto;
            left: 0;
            width: calc(100vw);
            height: calc(100vh);
            margin: 0;
            border-radius: 0;
            padding: 20pt;
        }

        > .status {
            text-align: center;
            text-transform: uppercase;
            color: $color_gray;
            margin-bottom: 10px;
        }

        .buttons {
            float: right;
            margin-bottom: 1em;
        }

        .table-wrapper {
            width: 100%;
            height: calc(100vh - 230pt);
            overflow: overlay;
        }

        table {
            .error {
                margin-top: 5px;
                font-size: smaller;
            }
            .nowrap {
                white-space: nowrap;
            }
            .wrap {
                white-space: normal;
                overflow-wrap: anywhere;
            }
        }

        > form.cancel {
            margin-top: 40px;

            > button[type="submit"] {
                float: right;
            }
        }
    }

    > .confirm-dialog {
        position: fixed;
        left: 50%;
        width: 500px;
        height: 200px;
        margin-left: -250px;
        background: $color_smoke;
        @include prefix(border-radius, 3px);
        padding: 20px;
        text-align: center;
        display: flex;
        flex-direction: column;

        > .actions {
            margin-top: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 15px;
        }
    }
}

.customer-select {
    > .product-labels {
        background: $color_white;
        height: 230px;
        overflow: auto;
        margin-top: 10px;

        > ul {
            list-style: none;
            margin: 0;
            padding: 4px;
            padding-bottom: 30px;

            > li {
                > form {
                    cursor: pointer;
                }

                > form,
                > a {
                    display: block;
                    padding: 10px;
                    text-decoration: none;
                    color: $color_gray;
                    border-bottom: solid 1px $color_smoke;

                    &:hover {
                        color: $color_black;
                        background: $color_lightsmoke;
                    }

                    > span {
                        display: block;
                        margin: 2px 0;

                        &:first-of-type {
                            font-size: 11pt;
                        }

                        &:last-of-type {
                            font-size: 9pt;
                        }
                    }
                }

                &.new {
                    display: none;

                    button {
                        display: block;
                        width: 100%;
                        margin-top: 4px;
                    }
                }
            }
        }
    }
}


.texts-grid {
    > .text {
        border-top: solid 2px $color_palette_smoke;
        font-size: 14px;

        > .top {
            padding: 40px 40px 0;

            > * {
                display: inline-block;
                vertical-align: middle;
            }

            > .flag {
                font-size: 20px;
            }

            > .name {
                margin: 0 14px;
                font-weight: 600;
            }
        }

        > .bottom {
            padding: 30px;
            margin-top: -25px;
            display: grid;
            grid-template-columns: repeat(3, 1fr);

        }
    }

    &.single-column {
        > .text {
            > .bottom {
                display: grid;
                grid-template-columns: repeat(1, 1fr);
            }
        }
    }
}

.text-item.single {
    margin: 0;

    .channel {
        padding: 0;

        > .name {
            display: none;
        }

        > form > .text,
        > .text {
            padding: 0;
            min-height: initial;
            background: transparent;

            > .badge {
                position: initial;
                margin-bottom: 10px;
            }
        }
    }

    .toolbar {
        margin-top: 12px;
        padding: 0;
        padding-top: 6px;
        border-top-color: rgba(0, 0, 0, 0.1);
    }
}

.text-item {
    .channel {
        padding: 10px;
        vertical-align: top;
        height: 100%;

        &.empty {
            > .text {
                font-size: 9pt;
                color: $color_gray;
                text-align: center;
                padding: 55px 0;
            }
        }

        > .name {
            font-weight: 600;
            color: $color_palette_darkblue;
            text-transform: uppercase;
            margin-bottom: 6px;
        }

        > form > .text,
        > .text {
            background: $color_palette_smoke;
            padding: 20px;
            min-height: 135px;
            text-align: initial;

            h1 {
                font-size: 18px;
                font-weight: 600;
            }

            position: relative;

            > .badges {
                margin-right: -10px;
                float: right;
                display: flex;
                flex-direction: row;
            }
        }

        > .comment {
            margin: 20px 0 10px;

            > i {
                float: left;
                padding: 0 8px;
                font-size: 18px;
            }
        }
    }

    .channel.info {
        > form > .text,
        > .text {
            color: grey;
        }
    }
    .channel.warning {
        > form > .text,
        > .text {
            background: #fcfcd8;
        }
    }
    .channel.error {

        > form > .text,
        > .text {
            background: lighten($color_errorlight, 30%);
        }
    }
}

.text-item {
    margin: 30px 0 0;

    .toolbar {
        visibility: hidden;
    }

    &:hover {
        .toolbar {
            visibility: visible;

            opacity: 0;
            @include prefix(animation, slidedown 200ms ease forwards);
        }
    }

    .channel > .text {
        padding: 12px 20px 12px 15px;

        &.edited {
            color: $color_palette_blue;
        }
    }

    .toolbar {
        border-top: solid 2px $color_palette_smoke;
        padding: 3px 8px;
        margin-top: 10px;

        > * {
            display: inline-block;
            vertical-align: middle;
        }

        > form > button,
        > button,
        > a {
            background: transparent;
            border: none;
            text-decoration: none;
            cursor: pointer;
            margin: 0;

            > * {
                display: inline-block;
                vertical-align: middle;
            }

            > i {
                font-size: 16px;
            }

            > span {
                font-size: 9pt !important;
                font-weight: 500;
            }

            padding: 5px 7px;

            > * {
                color: rgba(0, 0, 0, 0.7);
            }

            &:hover {
                > * {
                    color: $color_palette_red;
                }
            }
        }
    }

    h1 {
        margin: 0;
        font-size: 16pt;
    }

    p {
        margin: 0;
        font-size: 10pt;
    }

    form {
        .ql-toolbar.ql-snow {
            border: none;
            border-bottom: solid 2px $color_palette_smoke;
        }

        .ql-container.ql-snow {
            border: none;
        }

        textarea {
            margin-top: 10px;
            padding: 10px;
            height: 60px;
            min-height: 60px;
            max-height: 60px;
            font-size: 10pt;
            font-weight: 500;
        }
    }
}
