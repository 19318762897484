.template-builder {
    /* Tooltip container */
    .tooltip {
        position: relative;
        display: inline-block;
    }

    /* Tooltip text */
    .tooltip .tooltiptext {
        visibility: hidden;
        width: 800px;
        background-color: black;
        color: #fff;
        padding: 5px 0;
        border-radius: 6px;

        /* Position the tooltip text - see examples below! */
        position: absolute;
        z-index: 1;
    }

    /* Show the tooltip text when you mouse over the tooltip container */
    .tooltip:hover .tooltiptext {
        visibility: visible;
    }

    > .heading {
        padding: 15px 20px;
        border-bottom: solid 2px #f4f5f9;
        background: $color_white;
        @include prefix(box-shadow, 0 2px 9px 0 rgba(0, 0, 0, 0.1));

        position: sticky;
        top: 50px;
        left: 0;
        right: 0;
        z-index: 9;

        .layout-for-wrap {
            flex-wrap: wrap;
        }

        form {
            display: flex;

            .display_name {
                flex: 1;

                > input[type="text"] {
                    font-size: 16pt;
                    padding: 5px;
                    width: 100%;
                }

                > h2 {
                    padding: 5px;
                    width: 100%;
                    margin: 0;
                }
            }

            .actions {
                padding: 0;

                > * {
                    display: inline-block;
                    margin-left: 10px;
                    vertical-align: middle;
                }
            }
            .annotate {
                flex-basis: 100%;
            }
        }
    }

    > .container {
        padding-top: 20px;
        form.example-product {

            button[type="submit"] {
                background: $color_white;
            }

            a {
                text-decoration: none;
                color: $color_palette_blue;
                font-weight: 600;
                text-transform: uppercase;
                padding: 8px;

                > * {
                    display: inline-block;
                    vertical-align: middle;
                }

                > i {
                    font-size: 16px;
                }

                &:hover {
                    color: $color_palette_red;
                }

                &:active {
                    color: $color_black;
                }
            }
        }

    }

    .list-style {
        background: $color_white;
        @include prefix(border-radius, 4px);
        padding: 10px 25px;
        margin-top: 20px;

        .show-on-hover {
            opacity: 0
        }

        & .translation-wrapper {
            display: flex;
            align-items: center;
            gap: 15px;
            & form.machine-translation button {
                width: 250px;
            }
        }

        > .row {
            margin: 0 -25px;
            padding: 15px 25px;
            border-bottom:  solid 1px $color_palette_smoke;

            &:hover .show-on-hover {
                opacity: 1;
            }

            &:first-of-type {
                border-top: solid 1px $color_palette_smoke;
            }

            &.header {
                text-transform: uppercase;
                font-size: 12px;
                padding-bottom: 2px;
            }

        }

        > .bottom {
            margin-top: 20px;
            margin-bottom: 10px;

            .actions {
                margin: 10px 0;
            }
        }

        > .sentence-form {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
        }
    }

    .field-item {
        display: flex;
        justify-content:  space-between;

        > .column {
            flex: 1 0 20%;
        }
        > .actions {
            width: 40px;
        }

    }

    .field-form {
        &.new-field-form {
            display: none;
        }

        > .fields {
            display: flex;
            margin: -5px;

            > .field {
                flex: 1;
                margin: 0 5px;
            }

        }
    }

    .lexicon-item, .lexicon-form {
        > div {
            margin: 10px 0;
            & .machine-translation {
                margin-left: 10px;
            }
        }
        > .shortcuts {
            display: flex;
            flex-wrap: wrap;
            > * {
                button {
                    width: 100%;
                }
                margin: 3px;
            }
        }
        > .language {
            font-size: 10pt;
            span {
                font-weight: 600;
                text-transform:  uppercase;
                color: $color_black;
            }
            small {
                font-size: 9pt;
                color: $color_darksmoke;
            }
        }
        .save-for-all-button {
            position: relative;
        }
        > .pbutton, .save-for-all-button {
            display: inline-block;
            button.pbutton.pbutton-inverse {
                margin: 20px 0;
            }
        }

    }
}

.displayflex {
    display: flex;
}

.childmarginvertical {
    * + * {
        margin-left: 10px;
    }
}
